import React, { useEffect, useState } from "react";
import { isEmpty, cloneDeep, find, lowerCase } from "lodash";
import { useHistory } from "react-router";
import { ConfigContext } from "../../containers/SiteConfig";
import {
  Table,
  TableCell,
  TableRow,
  TableBody,
  Typography,
} from "@material-ui/core";
import { ArrowBackIos } from "@material-ui/icons";

import { sendMessage } from "../../iframeConnect";

import { api, useStyles } from "../../utils";
import Loader from "../../containers/Loader";
import { TeamsFilters } from "./TeamFilters";
import { SchedulesWidget } from "./ScheduleWidget";

const TeamsList = ({ label, handler, className = "", ...props }) => {
  const history = useHistory();
  const { sitesInfo, programId, widgetSiteId } =
    React.useContext(ConfigContext);
  const [teamList, setTeamList] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [filteredTeam, setFilteredTeam] = useState(null);
  const [searchValue, setSearchValue] = useState();

  const classes = useStyles();

  useEffect(() => {
    const loadTeams = async () => {
      try {
        const { siteId, apiKey } = find(
          sitesInfo,
          (site) => site.siteId === widgetSiteId
        );
        const { data: promisesApiData } = await api.getProgramDetail(
          apiKey,
          programId,
          siteId
        );
        if (
          promisesApiData.visibility === "Public" &&
          promisesApiData.type !== "CLASS" &&
          (promisesApiData.state === "LIVE" ||
            promisesApiData.state === "UPCOMING")
        ) {
          const { data: promisesApi } = await api.getProgramTeamsDetail(
            programId,
            siteId,
            apiKey
          );

          setTeamList(promisesApi);
          setIsLoading(false);
          sendMessage("contentUpdate", undefined, true);
        } else {
          setTeamList(null);
          setIsLoading(false);
        }
      } catch (err) {
        console.log(err);
      }
    };

    loadTeams();
  }, [programId, sitesInfo, widgetSiteId]);

  const showTeamTabs = (team, index) => () => {
    history.push(`/teams/${team.id}?tabIndex=${index}`, { team });
  };

  const handleSearch = () => {
    const searchText = lowerCase(searchValue);
    if (isEmpty(searchValue) && !isEmpty(teamList)) {
      setFilteredTeam(cloneDeep(teamList));
    } else {
      const teams = cloneDeep(teamList).filter((team) =>
        lowerCase(team.teamName).includes(searchText)
      );
      setFilteredTeam(teams);
    }
  };

  const handleClearSearch = () => {
    setFilteredTeam(cloneDeep(teamList));
  };

  const renderTeamsList = (team) => {
    return team.map((teamlist) => {
      return (
        <TableRow key={teamlist.teamId}>
          <TableCell>
            <Typography>
              <span
                style={{ cursor: "pointer" }}
                onClick={showTeamTabs(teamlist, 0)}
              >
                {teamlist.teamName}
              </span>
            </Typography>
          </TableCell>
          <TableCell
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
            colSpan="4"
          >
            <Typography
              style={{ cursor: "pointer" }}
              onClick={showTeamTabs(teamlist, 0)}
              className={classes.widgetButton}
            >
              Schedule
            </Typography>{" "}
            <Typography>&nbsp;|&nbsp;</Typography>
            <Typography
              style={{ cursor: "pointer" }}
              onClick={showTeamTabs(teamlist, 1)}
              className={classes.widgetButton}
            >
              Roster
            </Typography>
          </TableCell>
        </TableRow>
      );
    });
  };

  if (!isLoading && isEmpty(teamList)) {
    return <h3>No teams available for this program.</h3>;
  }

  return (
    <Loader showLoading={isLoading} key="schedule-loader">
      {!isEmpty(teamList) && (
        <>
          <TeamsFilters
            teamList={filteredTeam}
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            handleSearch={handleSearch}
            handleClearSearch={handleClearSearch}
          />
          <Table>
            <TableBody>
              {!isEmpty(filteredTeam)
                ? renderTeamsList(filteredTeam)
                : renderTeamsList(teamList)}
            </TableBody>
          </Table>
        </>
      )}
    </Loader>
  );
};

export default TeamsList;

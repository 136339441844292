import React from "react";
import { useLocation } from "react-router";
import { openSnackbar } from "./containers/Notifier";
import { sendMessage } from "./iframeConnect";

export const CURRENCY_COLUMNS = [
  "teamFee",
  "freeAgentFee",
  "teamIndividualFee",
  "teamPrice",
];

/*
 * This is part of a short term solution for showing a helpful message during the planned downtime for a database
 * migration in April 2023. This could be updated and reused later for a future planned downtime scenario.
 *
 * The following values would need to be updated and :
 *  - maintenanceEndTimeString: A specific time is stated in the message (i.e. "8 a.m. EDT").
 *                              This would need to be updated to match the `end` date/time.
 * In the MAINTENANCE_WINDOW object:
 *  - start: the start date/time of the planned downtime, in UTC
 *  - end: the end date/time of the planned downtime, in UTC
 *
 */
const maintenanceEndTimeString = "8 a.m. EDT";
export const MAINTENANCE_WINDOW = {
  start: "2023-04-11T04:00:00.000Z",
  end: "2023-04-11T12:00:00.000Z",
  title: "This will be back soon",
  message: `The LeagueApps platform is currently undergoing scheduled maintenance, so this isn't able to load. The maintenance is expected to conclude by ${maintenanceEndTimeString}, so please check back later. We appreciate your patience and understanding!`,
};

export const NETWORK_ERROR_MESSAGE = {
  message1: `There is a network error so this isn't able to load. If you're using a VPN, please turn it off and reload this page. If you're not using a VPN, please contact`,
  link: <a href="mailto:support@leagueapps.com">[LeagueApps Support]</a>,
  message2: "at support@leagueapps.com.",
};

export const WEEKDAYS = {
  MONDAY: "Monday",
  TUESDAY: "Tuesday",
  WEDNESDAY: "Wednesday",
  THURSDAY: "Thursday",
  FRIDAY: "Friday",
  SATURDAY: "Saturday",
  SUNDAY: "Sunday",
};

export const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export const alterUrl = (registerUrl, type, mode) => {
  if (!registerUrl) {
    return null;
  }

  if (mode === "YOUTH") {
    return registerUrl;
  } else {
    let url = registerUrl;
    if (registerUrl.includes("/init?")) {
      url = registerUrl.replace("/init", "");
    }

    if (type === "team") {
      return `${url}&type=Captain`;
    } else if (type === "individual") {
      return `${url}&type=FA`;
    } else {
      return `${url}&type=Team`;
    }
  }
};

export const daysSorterFromMonday = {
  Mon: 1,
  Tue: 2,
  Wed: 3,
  Thu: 4,
  Fri: 5,
  Sat: 6,
  Sun: 7,
};

export const daysSorterFromSunday = {
  Sun: 1,
  Mon: 2,
  Tue: 3,
  Wed: 4,
  Thu: 5,
  Fri: 6,
  Sat: 7,
};

export const programsButton = {
  signUp: "SIGN UP",
  openSoon: "OPEN SOON",
  soldOut: "SOLD OUT",
};

export const disabledButtonValues = ["OPENS_SOON", "SOLD_OUT", "CANCELLED"];
export const registrationStatuses = {
  OPENS_SOON: "Opens Soon",
  OPEN: "Open",
  SOLD_OUT: "Sold Out",
  LIMITED: "Limited Spots",
  WAITLIST: "Accepting Waitlist",
  ONLY_FREE_AGENTS: "Accepting Free Agents Only",
  ONLY_MALE_FREE_AGENTS: "Accepting Male Free Agents Only",
  ONLY_FEMALE_FREE_AGENTS: "Accepting Female Free Agents Only",
  ONLY_TEAM_CAPTAINS: "Accepting Team Captains Only",
  ONLY_TEAM_PLAYERS: "Accepting Team Players Only",
  ONLY_TEAM_PLAYERS_AND_FA: "Accepting Team and Free Agents Players Only",
  CANCELLED: "Cancelled",
  REGISTRATION_CLOSED: "Registration Closed",
};

export const genderValues = {
  CO_ED: "Co-Ed",
  MALE: "Male",
  FEMALE: "Female",
  ANY: "Any",
};

export const stateValuesKeys = {
  LIVE: "LIVE",
  OPEN_REGISTRATIONS: "OPEN_REGISTRATIONS",
  OPEN_REGISTRATIONS_AND_UPCOMING: "OPEN_REGISTRATIONS_AND_UPCOMING",
  UPCOMING: "UPCOMING",
};

export const stateDefaultValues = {
  [stateValuesKeys.LIVE]: "Live",
  [stateValuesKeys.OPEN_REGISTRATIONS]: "Open for Registrations",
  [stateValuesKeys.UPCOMING]: "Upcoming",
  [stateValuesKeys.OPEN_REGISTRATIONS_AND_UPCOMING]: "Open for Registrations and Upcoming",
};

export const stateValueToKey = {
  "Live": stateValuesKeys.LIVE,
  "Open for Registrations": stateValuesKeys.OPEN_REGISTRATIONS,
  "Upcoming": stateValuesKeys.UPCOMING,
  "Open for Registrations and Upcoming": stateValuesKeys.OPEN_REGISTRATIONS_AND_UPCOMING,
};

export const isOpenReg = (value) => value === stateValuesKeys.OPEN_REGISTRATIONS;

export const hasOpenReg = (valuesArr) => {
  return (valuesArr || []).some((val) => isOpenReg(val));
};

export const isOpenAndUpcoming = (value) => value === stateValuesKeys.OPEN_REGISTRATIONS_AND_UPCOMING;

export const hasOpenAndUpcoming = (valuesArr) => {
  return valuesArr.some((val) => isOpenAndUpcoming(val));
};

export const noDataMessage = "No programs scheduled. Please check back later.";
export const filtersMessage = "Add a zip code to enable Filters.";

export const AGE = [5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19];

export const getUrl = (urlString) => {
  if (!urlString) {
    return null;
  }

  if (urlString.includes("leagueapps.com")) {
    return `https:${urlString}`;
  } else {
    return `http:${urlString}`;
  }
};

export const getSignUpAction =
  (program, signUpAction, history, id, isDisabled) => () => {
    const {
      programUrlHtml,
      registerUrlHtml,
      programId,
      siteId,
      masterProgramId,
      masterRegisterUrlHtml,
      masterProgramUrlHtml,
      isSessionBased,
    } = program;

    sendMessage("scrollIframe", undefined, undefined, true); // hide means dont scroll

    if (masterProgramId > 0) {
      let _programId = programId;
      let _programUrlHtml = programUrlHtml;
      let _registerUrlHtml = registerUrlHtml;
      if (isSessionBased) {
        // is Session based subprog then show Master info i.e program id,program Url and registration url
        _programId = masterProgramId;
        _programUrlHtml = masterProgramUrlHtml;
        _registerUrlHtml = masterRegisterUrlHtml;
      }

      if (signUpAction === "viaDirectRegistration") {
        if (isDisabled) {
          return _programUrlHtml
            ? window.open(getUrl(_programUrlHtml), "_blank")
            : openSnackbar("Link not found.");
        }
        return _registerUrlHtml
          ? window.open(getUrl(_registerUrlHtml), "_blank")
          : openSnackbar("Link not found.");
      } else if (signUpAction === "viaLeagueApps") {
        return _programUrlHtml
          ? window.open(getUrl(_programUrlHtml), "_blank")
          : openSnackbar("Link not found.");
      } else if (signUpAction === "viaWidget") {
        return history.push(`/details/${_programId}/${siteId}?id=${id}`);
      }
    } else {
      if (signUpAction === "viaDirectRegistration") {
        if (isDisabled) {
          return programUrlHtml
            ? window.open(getUrl(programUrlHtml), "_blank")
            : openSnackbar("Link not found.");
        }
        return registerUrlHtml
          ? window.open(getUrl(registerUrlHtml), "_blank")
          : openSnackbar("Link not found.");
      } else if (signUpAction === "viaLeagueApps") {
        return programUrlHtml
          ? window.open(getUrl(programUrlHtml), "_blank")
          : openSnackbar("Link not found.");
      } else if (signUpAction === "viaWidget") {
        return history.push(`/details/${programId}/${siteId}?id=${id}`);
      }
    }
  };

export const COLUMN_WIDTHS = {
  programLogo150: 50,
  location: 50,
  scheduleDays: 50,
  scheduleTimes: 50,
  startTime: 50,
  gender: 50,
  leagueFormat: 50,
  experienceLevel: 50,
  teamFee: 50,
  freeAgentFee: 50,
  endRegistrationTime: 50,
  sport: 50,
  name: 50,
  season: 50,
  state: 50,
  registrationStatus: 50,
  type: 50,
  code: 50,
  sponsor: 50,
  publicRegistrationTime: 50,
  registrationType: 50,
  age: 50,
  subProgram: 50,
  siteName: 50,
  maxFreeAgentsCount: 50,
  maxPlayersCount: 50,
  maxTeamCount: 50,  
  remainingFreeAgents: 50,
  remainingPlayers: 50,
  remainingTeamCount: 50,  
};

export const days = [
  {
    label: "Su",
    key: "Sun",
    filterLabel: "Sunday",
  },
  {
    label: "M",
    key: "Mon",
    filterLabel: "Monday",
  },
  {
    label: "T",
    key: "Tue",
    filterLabel: "Tuesday",
  },
  {
    label: "W",
    key: "Wed",
    filterLabel: "Wednesday",
  },
  {
    label: "Th",
    key: "Thu",
    filterLabel: "Thursday",
  },
  {
    label: "F",
    key: "Fri",
    filterLabel: "Friday",
  },
  {
    label: "Sa",
    key: "Sat",
    filterLabel: "Saturday",
  },
];

export const defaultFilters = Object.freeze({
  state: {
    hasRelevancy: true,
    isMultiSelect: true,
    isOpen: false,
    options: [],
  },
  sport: {
    hasRelevancy: true,
    isMultiSelect: true,
    isOpen: false,
    options: [],
  },
  scheduleDays: {
    hasRelevancy: true,
    isMultiSelect: true,
    isOpen: false,
    options: [],
  },
  gender: {
    hasRelevancy: true,
    isMultiSelect: true,
    isOpen: false,
    options: [],
  },
  experienceLevel: {
    hasRelevancy: true,
    isMultiSelect: true,
    isOpen: false,
    options: [],
  },
  leagueFormat: {
    hasRelevancy: true,
    isMultiSelect: true,
    isOpen: false,
    options: [],
  },
  season: {
    hasRelevancy: true,
    isMultiSelect: true,
    isOpen: false,
    options: [],
  },
  sponsor: {
    hasRelevancy: true,
    isMultiSelect: true,
    isOpen: false,
    options: [],
  },
  location: {
    hasRelevancy: true,
    isMultiSelect: true,
    isOpen: false,
    options: [],
  },
  type: {
    hasRelevancy: true,
    isMultiSelect: false,
    isOpen: false,
    options: [],
  },
  code: {
    hasRelevancy: true,
    isMultiSelect: true,
    isOpen: false,
    options: [],
  },
  registrationStatus: {
    hasRelevancy: true,
    isMultiSelect: true,
    isOpen: false,
    options: [],
  },
  registrationType: {
    hasRelevancy: true,
    isMultiSelect: false,
    isOpen: false,
    options: [],
  },
  age: {
    hasRelevancy: true,
    isMultiSelect: false,
    isOpen: false,
    options: [],
  },
  siteName: {
    hasRelevancy: true,
    isMultiSelect: true,
    isOpen: false,
    options: [],
  },
  subProgram: {
    hasRelevancy: true,
    isMultiSelect: true,
    isOpen: false,
    options: [],
  },
});

export const defaultSelectedFilters = Object.freeze({
  state: [],
  sport: [],
  scheduleDays: [],
  gender: [],
  experienceLevel: [],
  leagueFormat: [],
  season: [],
  sponsor: [],
  location: [],
  type: [],
  code: [],
  registrationType: [],
  age: [],
  siteName: [],
  subProgram: [],
});

export const radiusOptions = [
  {
    id: 1,
    value: "Within 1 mile",
  },
  {
    id: 5,
    value: "Within 5 miles",
  },
  {
    id: 10,
    value: "Within 10 miles",
  },
  {
    id: 25,
    value: "Within 25 miles",
  },
  {
    id: 50,
    value: "Within 50 miles",
  },
  {
    id: 100,
    value: "Within 100 miles",
  },
];

export const defaultMapSearchState = {
  keywords: null,
  zipCode: null,
  radius: radiusOptions[0],
};

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback } from "react";
import { isEmpty, remove, pickBy, cloneDeep, map } from "lodash";
import { withDatabase } from "@nozbe/watermelondb/DatabaseProvider";
import withObservables from "@nozbe/with-observables";
import { Q } from "@nozbe/watermelondb";

import {
  Drawer,
  Divider,
  List,
  ListItem,
  Collapse,
  RadioGroup,
  MenuItem,
  FormControlLabel,
  Radio,
} from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { ConfigContext } from "../containers/SiteConfig";
import {
  useStyles,
  getFilterOptions,
  getMasterSubProgramValue,
  updateFilters,
} from "../utils";
import AppButton from "./AppButton";
import OptionsList from "./OptionsList";
import { getProgramsQuery } from "../db/queries/programs";
import { defaultFilters, sortFilterOptions } from "../constants";
import { sendMessage } from "../iframeConnect";

const showSelectedValues = (id, value, filterArr) => {
  let _selectedValues = filterArr || [];
  return {
    included: _selectedValues && _selectedValues.includes(value),
    updatedSelection: _selectedValues,
  };
};

const FilterDrawer = ({
  handleDrawerClose,
  open,
  order,
  setOrder,
  orderBy,
  setOrderBy,
  ...props
}) => {
  const classes = useStyles();
  const {
    programStateLabels,
    columnSettings = [],
    firstDayOfTheWeek,
  } = React.useContext(ConfigContext);
  const {
    filterConfig,
    programs,
    selectedFilters,
    setSelectedFilters,
    searchText,
    isFiltersModified,
    setIsFiltersModified,
    database,
    sitesInfo,
  } = props;

  const [filters, setFilters] = useState(cloneDeep(defaultFilters));
  const [collapse, setCollapse] = useState(false);
  const [sortOrder, setSortOrder] = useState(false);
  const [sortBy, setSortBy] = useState(false);

  useEffect(() => {
    sendMessage("contentUpdate", undefined, true);
    sendMessage("scrollIframe", undefined, undefined, undefined);
  }, [open, filters]);

  useEffect(() => {
    const loadFilters = async () => {
      let filterObj = await getFilterOptions(
        filterConfig,
        programs,
        programStateLabels,
        sitesInfo
      );

      const selected = pickBy(selectedFilters, (value, key) => !isEmpty(value));
      const siteIds = map(sitesInfo, ({ siteId }) => parseInt(siteId));
      const query = await getProgramsQuery(selected, siteIds, searchText);

      const filteredPrograms = await database.collections
        .get("programs")
        .query(...query)
        .fetch();

      let updatedFilterList = [];
      if (!isEmpty(filteredPrograms)) {
        const updatedFilters = getFilterOptions(
          filterConfig,
          filteredPrograms,
          programStateLabels,
          sitesInfo,
          firstDayOfTheWeek,
          selectedFilters
        );
        updatedFilterList = updateFilters(filterObj, updatedFilters);
      }

      if (isFiltersModified.isModified) {
        if (!isEmpty(isFiltersModified.id)) modifyFilters(isFiltersModified.id);

        setIsFiltersModified({ isModified: false, id: "" });
      }
      setFilters({ ...filterObj, ...updatedFilterList });
    };
    loadFilters();
  }, [programs]);

  const handleRequestSort = (property) => {
    if (orderBy === property) {
      const isDesc = order === "desc";
      setOrder(isDesc ? "asc" : "desc");
    } else {
      setOrder("asc");
      setOrderBy(property);
    }
  };

  const modifyFilters = async (id) => {
    const selected = pickBy(selectedFilters, (value, key) => !isEmpty(value));
    const siteIds = map(sitesInfo, ({ siteId }) => parseInt(siteId));
    const query = getProgramsQuery(selected, siteIds, searchText);
    const filteredPrograms = await database.collections
      .get("programs")
      .query(...query)
      .fetch();
    let updatedFilterList = [];
    if (!isEmpty(filteredPrograms)) {
      const updatedFilters = getFilterOptions(
        filterConfig,
        filteredPrograms,
        programStateLabels,
        sitesInfo
      );

      updateFilters(filters, updatedFilters);
      setFilters({ ...filters, ...updatedFilterList });
    }
  };

  useEffect(() => {
    modifyFilters();
  }, [selectedFilters]);

  const handleChange = (id, value, isMultiSelect) => {
    const showSelectionStatus = showSelectedValues(
      id,
      value,
      selectedFilters && selectedFilters[id]
    );
    if (showSelectionStatus.included) {
      remove(showSelectionStatus.updatedSelection, (val) => val === value);
    } else {
      if (isMultiSelect) {
        showSelectionStatus.updatedSelection.push(value);
      } else {
        showSelectionStatus.updatedSelection = [value];
      }
    }

    setSelectedFilters({
      ...selectedFilters,
      [id]: showSelectionStatus.updatedSelection,
    });
  };

  const handleFilterItemValue = (id) => () =>
    setFilters((prevFilters) => ({
      ...prevFilters,
      [id]: {
        ...prevFilters[id],
        isOpen: !(prevFilters[id] && prevFilters[id].isOpen),
      },
    }));

  return (
    <Drawer
      className={classes.drawer}
      variant="persistent"
      anchor="right"
      open={open}
      classes={{
        paper: classes.drawerPaper,
      }}
      style={{
        height: `${open ? "500px" : "100%"}`,
        overflow: "auto",
        maxHeight: "min-content",
      }}
    >
      <div className={classes.drawerHeader} key="filters-header">
        <span>Filters</span>
        <AppButton
          className={classes.drawerActionButton}
          color="primary"
          handler={handleDrawerClose}
          label="Done"
        />
      </div>
      <Divider />
      <div className={classes.drawerContent} key="filters-content">
        <List>
          {filterConfig.map(({ label, id, visibleOptions }) => {
            if (filters[id] && !isEmpty(filters[id].options)) {
              return (<div key={`filter-list-${id}`}>
                <ListItem
                  className={classes.programNameCell}
                  key={id}
                  onClick={handleFilterItemValue(id)}
                >
                  {label}
                  {!(filters[id] && filters[id].isOpen) ? (
                    <ExpandLess />
                  ) : (
                    <ExpandMore />
                  )}
                </ListItem>

                <Collapse
                  in={!(filters[id] && filters[id].isOpen)}
                  className={classes.collapsedFilterContent}
                >
                  <OptionsList
                    selectedValues={selectedFilters[id]}
                    filter={filters[id]}
                    label={label}
                    id={id}
                    handleChange={handleChange}
                    visibleOptions={visibleOptions}
                  />
                </Collapse>
              </div>)
            }
          })}
        </List>
        <ListItem className={classes.programNameCell}>
          Sort Order
          {!sortOrder ? (
            <ExpandLess onClick={() => setSortOrder(!sortOrder)} />
          ) : (
            <ExpandMore onClick={() => setSortOrder(!sortOrder)} />
          )}
        </ListItem>
        <RadioGroup value={order}>
          <Collapse in={!sortOrder} className={classes.collapsedFilterContent}>
            <MenuItem key="asc" onClick={() => setOrder("asc")}>
              <FormControlLabel
                style={{ pointerEvents: "none" }}
                value="asc"
                control={
                  <Radio
                    onClick={() => setOrder("asc")}
                    color="primary"
                    className={classes.filterCheckBox}
                    checked={order === "asc"}
                  />
                }
                label="Ascending"
              />
            </MenuItem>
            <MenuItem key="desc" onClick={() => setOrder("desc")}>
              <FormControlLabel
                style={{ pointerEvents: "none" }}
                value="desc"
                control={
                  <Radio
                    onClick={() => setOrder("desc")}
                    color="primary"
                    className={classes.filterCheckBox}
                    checked={order === "desc"}
                  />
                }
                label="Descending"
              />
            </MenuItem>
          </Collapse>
        </RadioGroup>
        <ListItem className={classes.programNameCell}>
          Sort By
          {!sortBy ? (
            <ExpandLess onClick={() => setSortBy(!sortBy)} />
          ) : (
            <ExpandMore onClick={() => setSortBy(!sortBy)} />
          )}
        </ListItem>
        <RadioGroup value={orderBy}>
          {columnSettings
            .filter((column) => column.value)
            .map(({ label: optionLabel, id, isDisabled }) => (
              <Collapse in={!sortBy} className={classes.collapsedFilterContent}>
                <MenuItem
                  disabled={isDisabled}
                  key={id}
                  onClick={() => handleRequestSort(id)}
                >
                  <FormControlLabel
                    style={{ pointerEvents: "none" }}
                    disabled={isDisabled}
                    value={id}
                    control={
                      <Radio
                        onClick={() => handleRequestSort(id)}
                        color="primary"
                        className={classes.filterCheckBox}
                        checked={orderBy === id}
                      />
                    }
                    label={optionLabel}
                  />
                </MenuItem>
              </Collapse>
            ))}
        </RadioGroup>
      </div>
    </Drawer>
  );
};

const enhance = withObservables(
  ["filters", "searchText", "sitesInfo"],
  ({
    database,
    filters,
    searchText,
    searchZipCode,
    searchRadius,
    showZipCodeFinder,
    sitesInfo,
  }) => {
    if (
      showZipCodeFinder === "yes" &&
      isEmpty(searchText) &&
      isEmpty(searchZipCode) &&
      isEmpty(searchRadius)
    ) {
      return {
        programs: [],
      };
    }

    const selectedFilters = pickBy(filters, (value, key) => !isEmpty(value));
    const siteIds = map(sitesInfo, ({ siteId }) => parseInt(siteId));
    const query = getProgramsQuery(
      selectedFilters,
      siteIds,
      searchText,
      searchZipCode,
      searchRadius
    );
    
    return {
      programs: database.collections
        .get("programs")
        .query(...query)
        .observe(),
    };
  }
);
export default withDatabase(enhance(FilterDrawer));

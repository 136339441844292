import React, { useMemo } from "react";
import {
  MenuItem,
  Checkbox,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@material-ui/core";
import { useStyles, formatData } from "../utils";
import { isEmpty } from "lodash";


const OptionsList = ({ filter, handleChange, selectedValues, id, label, visibleOptions }) => {
  const classes = useStyles();
  const optionsList = useMemo(() => {
    const newOptions = [...filter.options].filter(({ isDisabled }) => !isDisabled) // We need to hide the disabled filter
    if (visibleOptions && !isEmpty(visibleOptions)) {
      return newOptions.filter(({ key, value }) => visibleOptions.includes(key) || visibleOptions.includes(value));
    }
    return newOptions;
  }, [visibleOptions, filter.options]);

  const handleListItemClick =
    (value, isMultiSelect = false) =>
    () => {
      return handleChange(id, value, isMultiSelect);    
    };

  const getMultiSelectOptions = (options, label) => {
    const showSelectedValues = (id, value) => {
      return selectedValues.includes(value);
    };

    if(!options.length) {
      return (<MenuItem
        key="No options"
        className={classes.options}
      >
        <span> No available options </span>
      </MenuItem>)
    }

    return (options.map(({ label: optionLabel, value }) => (
        <MenuItem
          key={value}
          onClick={handleListItemClick(value, true)}
          className={classes.options}
        >
          <>
          <Checkbox
              color="primary"
              checked={showSelectedValues(id, value)}
              value={value}
            />
            {formatData(id, optionLabel)}
          </>
        </MenuItem>
      ))
    );
  };

  const getSingleSelectOptions = (options, label) => {
    
    if(!options.length) {
      return (<MenuItem
        key="No options"
        className={classes.options}
      >
        <span> No available options </span>
      </MenuItem>)
    }

    return (
      <RadioGroup
        aria-label={label}
        name={label}
        value={selectedValues && selectedValues[0]}
      >
        {options &&
          options.map(({ label: optionLabel, value }) => (
            <MenuItem
              className={classes.options}
              key={value}
              onClick={handleListItemClick(value)}
            >
              <FormControlLabel
                value={value}
                control={
                  <Radio
                    color="primary"
                    onClick={handleListItemClick(value)}
                    className={classes.filterCheckBox}
                    checked={selectedValues.includes(value)}
                  />
                }
                label={formatData(id, optionLabel)}
              />
            </MenuItem>
          ))}
      </RadioGroup>
    );
  };
 
  if (filter && filter.isMultiSelect) {
    return getMultiSelectOptions(optionsList, label);
  } else {
    return getSingleSelectOptions(optionsList, label);
  }
}

export default OptionsList;

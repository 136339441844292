import React, { useState } from "react";
import {
  Table,
  TableCell,
  TableRow,
  Popover,
  TableBody,
} from "@material-ui/core";

import moment from "moment-timezone";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";

import PopoverDetails from "./PopoverDetails";
import { useStyles } from "../../../utils";

import "react-big-calendar/lib/css/react-big-calendar.css";

const CalendarEvents = ({ date, events, timezone }) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [popoverData, setPopoverData] = React.useState();
  let anchorRef = React.createRef();
  const [anchorEl, setAnchorEl] = useState(null);
  const [prevDate, setPrevDate] = useState(date);
  const [viewType, setViewType] = useState("timeGridWeek");

  const onCloseTooltip = () => {
    setOpen(false);
    setAnchorEl(null);
  };

  const handleEventClick = (event) => {
    setOpen(true);
    setPopoverData(event);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const open1 = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const renderEventContent = (eventInfo) => {
    const { extendedProps: eventData } = eventInfo.event._def;
    let eventDetails;
    const isEvent = eventData.scheduleItemType === 'EVENT';
    const duration = eventData.endTimeUnix ? moment.duration(moment.utc(eventData.endTimeUnix).diff(moment.utc(eventData.startTimeUNIX))).asHours() : 1;
    const isAnHourLong = duration <= 1;
    
    switch (eventInfo.view.type) {
      case "timeGridWeek":
        setViewType("timeGridWeek");
        eventDetails = (
          <div
            aria-describedby={id}
            className={`${classes.eventCardWeek} ${isAnHourLong? 'hourLongEvent' : ''}`}
            onClick={handleClick}
          >
            <span>
              <b>
                {moment
                  .utc(eventData.startTimeUNIX)
                  .tz("America/New_York")
                  .format("hh:mm A")}
              </b>
            </span>
            <br />
            <span style={{ whiteSpace: "break-spaces" }}>
              {eventInfo.event.title}{
                !isEvent && !isAnHourLong ? 
                  <>
                    : {eventData.home}{" "}
                    {eventData.away ? `vs ${eventData.away}` : ""}
                  </>
                  : null
              }
            </span>
            <br />
          </div>
        );
        break;
      case "dayGridMonth":
        setViewType("dayGridMonth");
        eventDetails = (
          <>
            <div
              aria-describedby={id}
              onClick={handleClick}
              className={classes.eventCardDay}
            >
              <p
                style={{
                  overflowWrap: "break-word",
                  wordWrap: "break-word",
                  hyphens: "auto",
                }}
              >
                <b>
                  {" "}
                  {moment
                    .utc(eventData.startTimeUNIX)
                    .tz("America/New_York")
                    .format("hh:mm A")}
                </b>
              </p>
              <span style={{ whiteSpace: "break-spaces" }}>
                {eventInfo.event.title}{
                !isEvent && !isAnHourLong ? 
                  <>
                    : {eventData.home}{" "}
                    {eventData.away ? `vs ${eventData.away}` : ""}
                  </>
                  : null
              }
              </span>
            </div>
          </>
        );
        break;
      case "timeGridDay":
        setViewType("timeGridDay");
        eventDetails = (
          <div
            className={classes.eventCardTime}
            aria-describedby={id}
            onClick={handleClick}
          >
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell
                    colSpan={2}
                    align="left"
                    className={classes.eventCardTime}
                    style={{
                      paddingTop: "8px",
                      paddingBottom: "8px",
                      paddingLeft: 0,
                      border: "none",
                    }}
                  >
                    {eventInfo.event.title}
                    <br />
                  </TableCell>
                  <TableCell
                    align="left"
                    className={classes.eventCardTime}
                    style={{
                      paddingTop: "8px",
                      paddingBottom: "8px",
                      paddingLeft: 0,
                      border: "none",
                    }}
                  >
                    {moment
                      .utc(eventData.startTimeUNIX)
                      .tz("America/New_York")
                      .format("hh:mm A")}
                  </TableCell>
                  <TableCell
                    className={classes.eventCardTime}
                    align="left"
                    style={{
                      paddingTop: "8px",
                      paddingBottom: "8px",
                      paddingLeft: 0,
                      border: "none",
                    }}
                  >
                    {eventData.home}
                  </TableCell>
                  {eventData.away ? (
                    <>
                      <TableCell
                        className={classes.eventCardTime}
                        align="left"
                        style={{
                          paddingTop: "8px",
                          paddingBottom: "8px",
                          paddingLeft: 0,
                          border: "none",
                        }}
                      >
                        vs
                      </TableCell>
                      <TableCell
                        className={classes.eventCardTime}
                        align="left"
                        style={{
                          paddingTop: "8px",
                          paddingBottom: "8px",
                          paddingLeft: 0,
                          border: "none",
                        }}
                      >
                        {eventData.away}
                      </TableCell>
                    </>
                  ) : null}
                </TableRow>
              </TableBody>
            </Table>
          </div>
        );
        break;
      default:
        eventDetails = null;
    }
    return eventDetails;
  };
 
  return (
    <div className={classes.fullCalendarContainer}>
      <FullCalendar
        ref={anchorRef}
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        customButtons={{
          dayGridMonth: {
            text: "Month",
            click: function () {
              const calendarApi = anchorRef.current.getApi();
              setViewType("dayGridMonth");
              calendarApi.changeView("dayGridMonth");
            },
          },
          timeGridWeek: {
            text: "Week",
            click: function () {
              const calendarApi = anchorRef.current.getApi();
              setViewType("timeGridWeek");
              calendarApi.changeView("timeGridWeek");
            },
          },
          timeGridDay: {
            text: "Day",
            click: function () {
              const calendarApi = anchorRef.current.getApi();
              setViewType("timeGridDay");
              calendarApi.changeView("timeGridDay");
            },
          },
          prevWeek: {
            text: (
              <>
                <span className="fc-icon fc-icon-chevron-left" />
                {viewType === "timeGridWeek"
                  ? `${moment(prevDate)
                      .startOf("week")
                      .subtract(1, "weeks")
                      ?.format("MMM DD")}-${moment(prevDate)
                      .endOf("week")
                      .subtract(1, "weeks")
                      .format("MMM DD")}`
                  : viewType === "dayGridMonth"
                  ? `${moment(prevDate)
                      .startOf("month")
                      .subtract(1, "month")
                      .format("MMM YYYY")}`
                  : `${moment(prevDate).subtract(1, "day")?.format("dddd")}`}
              </>
            ),
            click: function () {
              const calendarApi = anchorRef.current.getApi();
              setPrevDate(
                moment(calendarApi.getDate()).subtract(
                  1,
                  viewType === "timeGridWeek"
                    ? "week"
                    : viewType === "dayGridMonth"
                    ? "months"
                    : "day"
                )
              );
              setViewType(calendarApi.view.type);
              calendarApi.prev();
            },
          },
          nextWeek: {
            text: (
              <>
                {viewType === "timeGridWeek"
                  ? `${moment(prevDate)
                      .startOf("week")
                      .add(1, "weeks")
                      .format("MMM DD")}-${moment(prevDate)
                      .endOf("week")
                      .add(1, "weeks")
                      .format("MMM DD")}`
                  : viewType === "dayGridMonth"
                  ? `${moment(prevDate)
                      .startOf("month")
                      .add(1, "month")
                      .format("MMM YYYY")}`
                  : `${moment(prevDate).add(1, "day")?.format("dddd")}`}
                <span className="fc-icon fc-icon-chevron-right" />
              </>
            ),
            click: function () {
              const calendarApi = anchorRef.current.getApi();
              setPrevDate(
                viewType === "timeGridWeek"
                  ? moment(calendarApi.getDate())
                      .startOf("week")
                      .add(1, "weeks")
                  : viewType === "dayGridMonth"
                  ? moment(calendarApi.getDate())
                      .startOf("month")
                      .add(1, "month")
                  : moment(calendarApi.getDate()).add(1, "day")
              );
              calendarApi.next();
            },
          },
        }}
        headerToolbar={{
          right: "nextWeek",
          left: "dayGridMonth,timeGridWeek,timeGridDay prevWeek",
          center: "title",
        }}
        allDaySlot={false}
        initialView="timeGridWeek"
        selectable={true}
        dayMaxEvents={4}
        expandRows={true}
        eventMaxStack={4}
        initialDate={date}
        handleWindowResize={true}
        eventTimeFormat={{
          hour: "numeric",
          minute: "2-digit",
          meridiem: "short",
        }}
        initialEvents={events}
        eventClick={handleEventClick}
        eventContent={renderEventContent}
        eventOverlap={false}
        stickyFooterScrollbar={true}
      />
      <span>
        {" "}
        All times are displayed in local time. This site’s events are in{" "}
        {moment().tz(timezone).format("z")}{" "}
      </span>
      <Popover
        placement="bottom"
        id={id}
        style={{ marginTop: "10px" }}
        anchorEl={anchorEl}
        open={open1}
        onClose={onCloseTooltip}
        keepMounted
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <PopoverDetails popoverData={popoverData} />
      </Popover>
    </div>
  );
};

export default CalendarEvents;

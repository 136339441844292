import React, { useContext, useEffect, useState } from "react";
import { cloneDeep, isEmpty, lowerCase, find } from "lodash";
import moment from "moment";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core";

import Axios from "axios";
import Loader from "../../containers/Loader";
import { ConfigContext } from "./../../containers/SiteConfig";
import { rosterColumns, formatRosterData, useStyles } from "../../utils";
import { RosterFilters } from "./RosterFilters";
import { sendMessage } from "../../iframeConnect";

const Roster = ({ label, handler, className = "", team }) => {
  const classes = useStyles();
  const [rosterData, setRosterData] = useState([]);
  const [filteredRosterData, setFilteredRosterData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState({});
  const [searchValue, setSearchValue] = useState();

  const { listingId: id, widgetSiteId, sitesInfo } = useContext(ConfigContext);

  useEffect(() => {
    const loadData = async () => {
      setIsLoading(true);
      let { data = [] } = await Axios(
        `${process.env.REACT_APP_CLOUD_FUNCTION_BASE_URL}/getRegistration?id=${id}&siteId=${widgetSiteId}`,
        { timeout: 1000000 }
      );
      let formattedData = formatRosterData(data);
      setRosterData(formattedData);
      setIsLoading(false);
      sendMessage("contentUpdate");
    };

    loadData();
  }, []);

  useEffect(() => {
    if (isEmpty(selectedFilters) && !isEmpty(rosterData)) {
      setFilteredRosterData(cloneDeep(rosterData));
    } else {
      const searchText = searchValue ? lowerCase(searchValue) : null;
      const data = cloneDeep(rosterData).filter(
        (data) =>
          (selectedFilters.role && data.role === selectedFilters.role) ||
          (selectedFilters.gender && data.gender === selectedFilters.gender) ||
          (
            searchText && (
              lowerCase(data.name).includes(searchText) ||
              lowerCase(data.role).includes(searchText) ||
              lowerCase(data.email).includes(searchText) ||
              lowerCase(data.team).includes(searchText)
            )
            )
      );

      setFilteredRosterData(data);
    }
  }, [selectedFilters.role, selectedFilters.gender, rosterData]);

  const handleSearch = () => {
    const searchText = lowerCase(searchValue);
    if (isEmpty(searchValue) && !isEmpty(rosterData)) {
      setFilteredRosterData(cloneDeep(rosterData));
    } else {
      const roster = cloneDeep(rosterData).filter(
        (data) =>
          lowerCase(data.name).includes(searchText) ||
          lowerCase(data.role).includes(searchText) ||
          lowerCase(data.email).includes(searchText) ||
          lowerCase(data.team).includes(searchText)
      );

      setFilteredRosterData(roster);
    }
  };

  const handleClearSearch = async () => {
    setFilteredRosterData(cloneDeep(rosterData));
  };

  if (isEmpty(rosterData) && !isLoading) {
    return <h3>No data for this team.</h3>;
  }

  return (
    <Loader showLoading={isLoading} key="schedule-loader" isFixed={false}>
      <>
        <RosterFilters
          rosterData={filteredRosterData}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          handleSearch={handleSearch}
          selectedFilters={selectedFilters}
          setSelectedFilters={setSelectedFilters}
          onHandleClearSearch={handleClearSearch}
        />
        <Table aria-label="simple table">
          <TableHead className={classes.scheduleTable}>
            <TableRow key="header-row">
              {rosterColumns.map(({ key, title }) => (
                <TableCell
                  style={{ width: !isEmpty(title) ? "18%" : "8%" }}
                  key={`${key}-header`}
                >
                  {title}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredRosterData.map((data) => {
              return (
                <TableRow key={data.id}>
                  {rosterColumns.map(({ key, title }) => {
                    let cellContent = null;
                    switch (title) {
                      case "Email":
                      case "Name":
                        cellContent = (
                          <span className={classes.primaryText}>
                            {data[key]}
                          </span>
                        );
                        break;
                      case "Gender":
                        cellContent = <span>{data[key] && data[key][0]}</span>;
                        break;
                      case "Birth Date":
                        cellContent = (
                          <span>{moment.utc(data[key]).format("ll")}</span>
                        );
                        break;
                      default:
                        cellContent = data[key];
                    }

                    return (
                      <TableCell key={`${key}-${data.id}`} align="left">
                        {cellContent}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </>
    </Loader>
  );
};

export default Roster;

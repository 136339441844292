// custom style classes for components
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(
  (theme) => {
    return {
      // daysToggle: {
      //   display: 'inline',
      //   textAlign: 'center',
      //   color: '#000000 !important',
      //   fontSize: '10px',
      //   height: '20px',
      //   width: '20px',
      //   margin: '0',
      //   marginRight: '3px',
      //   padding: '1px',
      //   fontWeight: 'normal',
      //   backgroundColor: 'lightgray',
      //   justifyContent: 'space-between',
      // },
      // daysToggleSelected: {
      //   backgroundColor: '#228B22 !important',
      // },
      // dayOfTheWeekCell: {
      //   display: 'flex',
      //   justifyContent: 'space-between',
      // },
      displayFlex: {
        display: "flex",
        justifyContent: "center",
      },
      row: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "baseline",
      },

      paginationBar: {
        display: "flex",
        justifyContent: "end",
      },

      paginationContainer: {
        display: "sm-flex",
        listStyleType: "none",
      },
      paginationItem: {
        padding: "0 12px",
        height: "32px",
        textAlign: "center",
        margin: "auto 0px",
        color: "rgba(0, 0, 0, 0.87)",
        display: "flex",
        boxSizing: "border-box",
        alignItems: "center",
        letterSpacing: "0.01071em",
        lineHeight: "1.43",
        fontSize: "0.8rem",
        minWidth: "32px",
        border: "1px solid rgb(128,128,128)",

        "&.dots:hover": {
          backgroundColor: "transparent",
          cursor: "default",
        },
        "&:hover": {
          backgroundColor: "rgba(0, 0, 0, 0.04)",
          cursor: "pointer",
        },

        "&.selected": {
          backgroundColor: "rgba(0, 0, 0, 0.08)",
        },
      },
      paginationText: {
        fontSize: "0.8rem",
      },

      pulse: {
        background: theme.overrides.MuiButton.textPrimary.background,
        borderRadius: "50%",
        height: "14px",
        width: "14px",
        position: "absolute",
        left: "50%",
        top: "50%",
        margin: "11px 0px 0px -12px",
        transform: "rotateX(55deg)",
        zIndex: "-2",
        "&::after": {
          content: "no-open-quote",
          borderRadius: "50%",
          height: "40px",
          width: "40px",
          position: "absolute",
          margin: "-13px 0 0 -13px",
          animation: "pulsate 1s ease-out",
          animationIterationCount: "infinite",
          opacity: "0",
          boxShadow: `0 0 1px 2px ${theme.overrides.MapMarker.primary}`,
          animationDelay: "1.1s",
        },
      },
      zipCodeFinderSearchButton: {
        backgroundColor: "#ffffff",
        fontSize: "12px",
        color: theme.overrides.MuiButton.textPrimary.background,
        boxShadow: "none",
        textTransform: "none",
        width: "auto",
        minWidth: "120px",
        minHeight: "25px",
        height: "auto",
        justifyContent: "space-around",
        borderRadius: "20px",
        border: `1px solid ${theme.overrides.MuiButton.textPrimary.background[0]}`,
        padding: "5px",
        margin: "5px",
        "&:hover": {
          cursor: "pointer",
        },
      },
      marker: {
        backgroundColor: theme.overrides.MuiButton.textPrimary.background,
      },
      zipCodeFinder: {
        width: "100%",
        height: "50vh",
        maxHeight: "50vh",
        minHeight: "50vh",
        marginBottom: "5px",
        flexDirection: "column",
        display: "flex",
      },
      mapFilterContainer: {
        marginBottom: "10px",
      },
      mapFilter: {
        top: "3px",
      },
      pagination: {
        flexShrink: 0,
        marginLeft: theme.spacing(2.5),
      },
      copyLink: {
        color: theme.overrides.MuiLink.root.color,
        "&:hover": {
          ...theme.overrides.MuiLink.underlineHover,
        },
      },
      programIcon: {
        width: "60px",
        height: "60px",
      },
      specialMessage: {
        color: theme.typography.linkColors.color,
      },
      headings: {
        fontFamily: theme.overrides.fontOverrides.widgetBoldFont,
        color: theme.typography.h1.color,
      },
      mainApp: {
        backgroundColor: theme.palette.background.default,
        color: theme.overrides.MuiTypography.root.color,
      },
      listResultInfo: {
        paddingLeft: "2px",
        paddingRight: "2px",
      },
      collapsedFilterContent: {
        marginBottom: "15px",
      },
      filterCheckBox: {
        padding: "4px",
      },
      drawerContent: {
        fontSize: "12px",
      },
      options: {
        paddingTop: 0,
        paddingBottom: 0,
      },
      programGridContainer: {
        flexDirection: "column-reverse",
      },
      flexHeader: {
        display: "flex",
        justifyContent: "space-between",
      },
      drawer: {
        width: "100%",
        flexShrink: 0,
      },
      drawerPaper: {
        width: "100%",
        backgroundColor: "#ffffff",
      },
      drawerHeader: {
        fontSize: "18px",
        fontFamily: theme.overrides.fontOverrides.widgetSemiBoldFont,
        display: "flex",
        alignItems: "center",
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
        justifyContent: "space-between",
      },
      drawerActionButton: {
        fontSize: "10px",
      },
      programHeading: {
        marginTop: "5px",
        marginBottom: "5px",
      },
      filterDrawerIcon: {
        "&:hover": {
          cursor: "pointer",
        },
        color: theme.overrides.MuiButton.textPrimary.background,
        display: "flex",
        height: "30px",
        flexDirection: "row-reverse",
        margin: 0,
      },
      headerDivider: {
        background: theme.overrides.MuiButton.textPrimary.background,
        marginLeft: "5px",
      },
      navText: {
        fontSize: "12px",
        display: "flex",
        verticalAlign: "middle",
      },
      listItemData: {
        marginTop: 0,
        marginBottom: "5px",
      },
      programsList: {
        marginTop: "10px",
      },
      programListItem: {
        marginBottom: "5px",
        marginTop: "10px",
        padding: "1%",
        fontSize: "13px",
        backgroundColor: "#ffffff",
        "&:hover": {
          backgroundColor: "rgba(0, 0, 0, 0.07)",
          cursor: "pointer",
        },
      },
      tableContainer: {
        width: "100%",
        overflowX: "auto",
      },
      programTable: {
        width: "100%",
        marginTop: theme.spacing(3),
        overflowX: "auto",
        boxShadow: "none",
      },
      programNameCell: {
        color: theme.overrides.MuiButton.textPrimary.background,
      },
      listResultsInfo: {
        fontWeight: "bold",
        fontSize: "12px",
      },
      filterOptionsPaper: {
        border: "2px solid",
        borderColor: theme.overrides.MuiButton.textPrimary.background,
        borderRadius: "20px",
      },
      programsListTable: {
        overflowX: "auto",
      },
      messageRowTableCell: {
        borderBottom: "none",
      },
      filterButton: {
        backgroundColor: "#ffffff",
        fontSize: "12px",
        color: theme.overrides.MuiButton.textPrimary.background,
        boxShadow: "none",
        textTransform: "none",
        width: "auto",
        minWidth: "120px",
        minHeight: "25px",
        height: "auto",
        justifyContent: "space-around",
        borderRadius: "20px",
        border: `1px solid ${theme.overrides.MuiButton.textPrimary.background[0]}`,
        padding: "5px",
        margin: "5px",
      },
      inFilterButton: {
        backgroundColor: "#ffffff",
        color: theme.overrides.MuiButton.textPrimary.background,
        justifyContent: "space-between",
      },
      filterOptionsItem: {
        color: theme.overrides.MuiButton.textPrimary.background[0],
        justifyContent: "space-between",
      },
      filterChips: {
        margin: "5px",
        fontSize: "10px",
      },
      programDetails: {
        marginBottom: "10px",
      },
      backIcon: {
        "&:hover": {
          cursor: "pointer",
        },
      },      
    "notifier-error" :{
      background: [theme.overrides.colors.alerts || '#ff0000', '!important'],
      color: [theme.overrides.colors.alternateText || '#ffffff', '!important'],
    },
      // schedule widget listing styles
      separator: {
        height: "1px",
        border: "0",
        borderTop: "1px solid #ccc",
        marginLeft: "10px",
      },
      listDisplaySetting: {
        display: "flex",
        justifyContent: "flex-start",
        gap: "10px",
        borderRight: "1px solid #ccc",
      },
      displayIcon: {
        "&:hover": {
          cursor: "pointer",
          color: theme.overrides.MuiButton.textPrimary["&:hover"].color,
          backgroundColor:
            theme.overrides.MuiButton.textPrimary["&:hover"].background,
        },
        backgroundColor: theme.overrides.MuiButton.textPrimary.background,
        color: theme.overrides.MuiButton.textPrimary.color,
        borderRadius: "5px",
        padding: "2%",
      },
      selectedDisplay: {
        color: theme.overrides.MuiButton.textPrimary["&:hover"].color,
        backgroundColor:
          theme.overrides.MuiButton.textPrimary["&:hover"].background,
      },
      filterItemsContainer: {
        paddingLeft: "15px",
        paddingRight: "3%",
        width: "fit-content",
      },
      searchContainer: {
        display: "flex",
        justifyContent: "flex-end",
        paddingBottom: "10px",
      },
      filterItem: {
        padding: "8px 0 !important",
      },
      filterSelect: {
        minWidth: "20px",
        height: "35px",
        padding: "5px",
        marginRight: "10px",
        backgroundColor: theme.overrides.MuiButton.textPrimary.background,
        color: theme.overrides.MuiButton.textPrimary.color,
        "&:hover": {
          color: theme.overrides.MuiButton.textPrimary["&:hover"].color,
          backgroundColor:
            theme.overrides.MuiButton.textPrimary["&:hover"].background,
        },
        "&:focus": {
          outline: "none",
        },
      },
      filterOptions: {
        "&.MuiMenu-paper": {
          borderRadius: 0,
        },
      },
      scheduleHeading: {
        padding: "20px",
      },
      scheduleTable: {
        fontFamily: theme.overrides.fontOverrides.widgetSemiBoldFont,
        fontSize: 16,
      },
      scheduleDate: {
        padding: "20px",
        fontSize: "1rem",
        paddingLeft: "10px"
      },
      secondaryText: {
        color: theme.overrides.MuiTypography.colorTextSecondary.color,
      },
      fullCalendarContainer: {
        padding: "20px",
        fontFamily: theme.overrides.fontOverrides.widgetRegularFont,
        color: theme.overrides.MuiTypography.root.color,
        "@media (max-width: 760px)": {
          width: "max-content",
        },
        "& .fc-popover": {
          left: "unset !important",
          width: "-webkit-fill-available",
        },

        "& .fc-event": {
          cursor: "pointer",
          "& .fc-event-main": {
            display: 'flex',
            alignItems: 'flex-start'
          }
        },

        "& .fc-v-event": {
          backgroundColor: "transparent !important",
          border: "none !important",
        },
        "& .fc-timegrid-event-harness, .fc-timegrid-event-harness-inset": {
          backgroundColor: theme.overrides.MuiButton.textPrimary.background,
          color: theme.overrides.MuiButton.textPrimary.color,
        },

        "& .fc-header-toolbar, .fc-event-main": {
          alignItems: "end",
        },

        "& .fc-header-left": {
          display: "flex",
          flexDirection: "column",
        },

        "& .fc-header-toolbar": {
          "& .fc-toolbar-chunk": {
            display: "flex",
            flexDirection: "column",
            marginRight: "10px",

            "& .fc-button-group": {
              "@media (max-width: 760px)": {
                width: "10%",
              },
            },

            "& .fc-button-primary": {
              backgroundColor: "transparent !important",
              color: theme.overrides.MuiButton.textPrimary.background,
              borderRadius: "4px !important",
              borderColor: "transparent !important",
              "@media (max-width: 760px)": {
                padding: 0,
              },
            },

            "& .fc-toolbar-title": {
              color: theme.overrides.MuiButton.textPrimary.background,
            },
            "& .fc-timeGridDay-button , .fc-nextWeek-button, .fc-prevWeek-button, .fc-dayGridMonth-button, .fc-timeGridWeek-button":
              {
                padding: 0,
                width: "fit-content",
                marginLeft: "0 !important",
                backgroundColor: "transparent",
                color: theme.overrides.MuiButton.textPrimary["&:hover"].color,
                "&:hover": {
                  color: `${theme.palette.linksHover} !important`,
                  backgroundColor: "transparent",
                },
                "&:focus": {
                  outline: "none",
                  boxShadow: "none",
                },
              },

            "& .fc-button": {
              color:
                theme.overrides.MuiButton.textPrimary["&:hover"].background,
            },

            "& .fc-button-active": {
              backgroundColor: "transparent",
              textDecoration: "underline",
              boxShadow: "none !important",
            },

            "&:first-child": {
              "& .fc-button-primary": {
                marginRight: "10px !important",
              },
            },

            "&:nth-child(2)": {
              ...theme.typography.h6,
            },

            "&:last-child": {
              "& .fc-button-primary": {
                marginLeft: "10px !important",
              },
            },
          },
        },
      },
      eventCardWeek: {
        flex: 1,
        padding: "8px",
        backgroundColor: theme.overrides.MuiButton.textPrimary.background,
        color: theme.overrides.MuiButton.textPrimary.color,
        "@media (max-width: 760px)": {
          fontSize: "10px",
        },
        "&.hourLongEvent": {
          padding: "4px !important",
        },
      },
      eventTooltip: {
        borderColor: theme.overrides.MuiButton.textPrimary.background,
      },
      eventCardTime: {
        backgroundColor: theme.overrides.MuiButton.textPrimary.background,
        color: theme.overrides.MuiButton.textPrimary.color,
        border: `1px solid`,
        borderColor: theme.overrides.MuiButton.textPrimary.background,
        height: "100%",
        display: 'flex',
        justifyContent: 'center',
      },
      eventCardDay: {
        backgroundColor: theme.overrides.MuiButton.textPrimary.background,
        borderColor: theme.overrides.MuiButton.textPrimary.background,
        color: theme.overrides.MuiButton.textPrimary.color,
        width: "100%",
        height: "100%",
        padding: "10px",
        paddingLeft: "5px",
        "@media (max-width: 760px)": {
          fontSize: "10px",
        },
      },
      widgetButton: {
        color: theme.palette.widgetButtonLinkText || "#000000",
        "&:hover": {
          color: theme.palette.widgetButtonLinkTextHover || "#707070",
        },
      },
      primaryText: {
        color: theme.palette.headings,
      },
    };
  },
  { index: 1 }
);

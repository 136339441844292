import React from 'react';
import {CircularProgress} from '@material-ui/core';

const loaderStyles = {
  position: 'fixed',
  zIndex: '999',
  height: '2em',
  width: '2em',
  overflow: 'visible',
  margin: 'auto',
  top: '0',
  left: '0',
  bottom: '0',
  right: '0',
};

const Loader = ({showLoading, isFixed = true,  ...props}) => {
  if(showLoading) {
    return (
      <div style={{...loaderStyles, position: isFixed ? 'fixed' : 'block'}}>
        <CircularProgress />
      </div>
    )
  } else {
    return props.children
  }
}

export default Loader;
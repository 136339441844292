/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from "react";
import { useParams, useHistory } from "react-router";
import { find } from "lodash";
import { ArrowBackIos } from "@material-ui/icons";
import { Tabs, Tab } from "@material-ui/core";

import { sendMessage } from "../iframeConnect";
import { api, useStyles, checkIsMobileView } from "../utils";
import { useQuery, getUrl } from "../constants";
import { ConfigContext } from "../containers/SiteConfig";
import TabPanel from "../containers/TabPanel";
import Loader from "../containers/Loader";
import { openSnackbar } from "../containers/Notifier";
import LeagueProfile from "./LeagueProfile";

const ProgramDetails = (props) => {
  const history = useHistory();
  const { sitesInfo, screenSize } = React.useContext(ConfigContext);
  const classes = useStyles();
  const query = useQuery();
  const { programId, siteId } = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [program, setProgram] = useState({});
  const [activeIndex, setActiveIndex] = useState(0);

  const id = query.get("id");
  const isMobileView = checkIsMobileView(screenSize);

  let apiKey = "";

  if (sitesInfo) {
    // Multi site Case
    const selectedSiteInfo = find(sitesInfo, { siteId: siteId });
    apiKey = selectedSiteInfo.apiKey;
  }
  const handleBackButton = useCallback(() => {
    history.push(`/?id=${id}&isBack=true`);
  });

  useEffect(() => {
    const getProgramDetails = async () => {
      const { data } = await api.getProgramDetail(apiKey, programId, siteId);
      if (data) {
        setProgram(data);
        setIsLoading(false);
        sendMessage("contentUpdate");
      } else {
        setProgram({});
        setIsLoading(false);
        sendMessage("contentUpdate");
      }
    };
    getProgramDetails();
  }, []);

  const handleTabChange = (event, newValue) => {
    switch (newValue) {
      case 1:
        program.scheduleUrlHtml
          ? window.open(getUrl(program.scheduleUrlHtml), "_blank")
          : openSnackbar("Link not found.");
        break;
      case 2:
        program.standingsUrlHtml
          ? window.open(getUrl(program.standingsUrlHtml), "_blank")
          : openSnackbar("Link not found.");
        break;
      case 3:
        program.programUrlHtml
          ? window.open(
              `${getUrl(program.programUrlHtml)}?ai=ANNOUNCEMENTS`,
              "_blank"
            )
          : openSnackbar("Link not found.");
        break;
      default:
        setActiveIndex(newValue);
    }
  };

  return (
    <div style={{ width: "100%" }}>
      <h1 className={classes.headings}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div>
            <ArrowBackIos
              className={classes.backIcon}
              onClick={handleBackButton}
            />
            {program.name}
          </div>
          {program.programLogo150 && (
            <img
              src={program.programLogo150}
              alt="logo icon"
              style={
                isMobileView
                  ? {
                      marginRight: "2vw",
                      width: 75,
                      height: 75,
                    }
                  : {
                      width: 75,
                      height: 75,
                      marginRight: "0.5vw",
                    }
              }
            />
          )}
        </div>
      </h1>
      <Tabs
        value={activeIndex}
        onChange={handleTabChange}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        scrollButtons="auto"
        aria-label="scrollable auto tabs example"
        TabIndicatorProps={{ style: { background: "gray", color: "red" } }}
      >
        <Tab label="League profile" className="tabLabel" />
        <Tab label="Schedule" />
        <Tab label="Standings" />
        <Tab label="Announcements and messages" />
      </Tabs>

      <hr
        style={{
          padding: 0,
          margin: 0,
        }}
      />

      <Loader showLoading={isLoading}>
        <TabPanel value={activeIndex} index={0}>
          <LeagueProfile program={program} siteId={siteId} />
        </TabPanel>
      </Loader>
    </div>
  );
};

export default ProgramDetails;

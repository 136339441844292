import React from 'react';
import { useHistory } from 'react-router';
import { compose } from 'recompose';
import { isEmpty } from 'lodash';
import * as moment from 'moment';
import withObservables from '@nozbe/with-observables'
import { TableCell, TableRow, Avatar, Link } from '@material-ui/core';
import { ConfigContext } from '../containers/SiteConfig';
import ActionButton from './ActionButton';
import { formatData, useStyles, getFeeDetails, stopEventPropagation, getHiddenMessage } from '../utils';
import { useQuery, getSignUpAction, getUrl, COLUMN_WIDTHS, CURRENCY_COLUMNS } from '../constants';
import { MapContext } from './ProgramsListing';



const RawProgramListItem = ({ program, className, totalColumns }) => {
  const classes = useStyles();
  const history = useHistory();
  const query = useQuery();
  const id = query.get("id");

  const { showProcessingFee, displayPriceLabels, signUpAction, showScheduleStandingLinks, columnSettings = [], showAdditionalTeamFee, showHasPaymentPlans } = React.useContext(ConfigContext);
  const { toggleProgramFocus } = React.useContext(MapContext);
  const columns = columnSettings.filter(col => col.value);
  const hiddenMessage = getHiddenMessage(program)

  const getProgramItem = (id) => {
    const value = program[id];
    let tableCell = null;
    const cellStyle = { minWidth: COLUMN_WIDTHS[id] || 'auto' }
    if (id === 'name') {
      let progValue =  program.masterProgramId > 0 ? program.masterProgramName: value;
      tableCell = (
        <TableCell key={id} id={progValue} className={classes.programNameCell} style={cellStyle}>
          {progValue}
        </TableCell>
      )
    } else if (id === 'programLogo150' && value) {
      tableCell = (<TableCell key={id} id={value} style={cellStyle}>
        <Avatar src={value} className={classes.programIcon} />
      </TableCell>
      )
    }else if (id === 'age') {
      const ageValue = getAgeColumValue(program)
      tableCell = (
        <TableCell key={id} id={ageValue} className={classes.programNameCell} style={cellStyle}>
          {ageValue}
        </TableCell>
      )
    }  else  if(id=== 'subProgram'){
      let progValue =  program.masterProgramId > 0 ? program.name: '';
      tableCell = (
        <TableCell key={id} id={progValue} className={classes.programNameCell} style={cellStyle}>
          {progValue}
        </TableCell>
      )
    }
    else if (CURRENCY_COLUMNS.includes(id)) {
      tableCell = getFeeDetails(id, program, undefined, undefined, undefined, showProcessingFee, displayPriceLabels, showAdditionalTeamFee, showHasPaymentPlans);
    } else {
      tableCell = (<TableCell key={id} id={value} style={cellStyle} >{formatData(id, value, program)}</TableCell>);
    }

    return tableCell;
  }

  const getAction = () => {
    let action = [];
    const {id, endRegistrationTime, state, scheduleUrlHtml, standingsUrlHtml} = program
    const regEndDateStatus = endRegistrationTime && moment.utc().isBefore(moment.utc(endRegistrationTime))

    if (!endRegistrationTime || regEndDateStatus) {
      action.push(<ActionButton key={`prog-action-btn-${action.length+1}`} program={program} />);
    } 

    if (state === 'LIVE' && showScheduleStandingLinks === "yes") {
      action.push(
        <div style={{ marginTop: '10px' }} key={`action-${id}`}>
          {scheduleUrlHtml && <><Link target="_blank" rel="noopener" href={getUrl(scheduleUrlHtml)} onClick={stopEventPropagation}> View schedule </Link> <br /></>}
          {standingsUrlHtml && <Link target="_blank" rel="noopener" href={getUrl(standingsUrlHtml)} onClick={stopEventPropagation}> View standings </Link>}
        </div>
      )
    }
    return action;
  }

  const getAgeColumValue = ({minAgeLimit, maxAgeLimit}) => {
    let value = '';
    if(minAgeLimit &&  maxAgeLimit){
      value = `Btw ${minAgeLimit}-${maxAgeLimit}`;
    } else if(minAgeLimit) {
      value = `${minAgeLimit} and above`;
    } else if(maxAgeLimit) {
      value = `Below ${maxAgeLimit}`;
    }
    return value;
  }

  return (
    <>
      <TableRow
        hover
        id={program.programId}
        key={program.programId}
        onClick={getSignUpAction(program, signUpAction, history, id)}
        className={hiddenMessage ? 'message-row' : ''}
        onMouseEnter={()=> toggleProgramFocus(program)}
      >
        {
          columns.map(({ id }) => getProgramItem(id))
        }

        <TableCell id='action' key="action" align="center" style={{ width: 100 }}>
          {getAction()}
        </TableCell>
      </TableRow>
      {
        !isEmpty(hiddenMessage) &&
        <TableRow>
          <TableCell colSpan={totalColumns} key="message" style={{ paddingTop: 0 }}>
            <span className={classes.specialMessage}>{hiddenMessage}</span>
          </TableCell>
        </TableRow>
      }
    </>
  )
}

export const ProgramRowItem = compose(
  withObservables(['program'], ({ program }) => ({
    program: program.observe()
  }))
)(RawProgramListItem);
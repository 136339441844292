import React from "react";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { withDatabase } from "@nozbe/watermelondb/DatabaseProvider";
import withObservables from "@nozbe/with-observables";
import { Q } from "@nozbe/watermelondb";

import { ConfigContext } from "../containers/SiteConfig";

// Custom theme wrapper for Material UI Components
const ThemeWrapperComponent = ({ config, ...props }) => {
  const { theme: colors, widgetType } = React.useContext(ConfigContext);
  
  const widgetBoldFont = widgetType === "registration" ? "Montserrat-Bold" : "apotek-extended";
  const widgetSemiBoldFont = widgetType === "registration" ? "Montserrat-SemiBold" : "apotek-extended";
  const widgetRegularFont = widgetType === "registration" ? "Montserrat-Regular" : "apotek-extended";
  const widgetMediumFont = widgetType === "registration" ? "Montserrat-Medium" : "apotek-extended";

  const muiTheme = {
    typography: {
      useNextVariants: true,
    },
    stepper: {
      iconColor: "rgb(115, 173, 72)",
    },
  };

  if (colors) {
    muiTheme.palette = {
      background: {
        default: colors.widgetBackground || "#FFFFFF",
        paper: colors.contentBackground,
      },
      ...colors,
    };

    muiTheme.typography = {
      h1: {
        fontFamily: widgetBoldFont,
        color: [colors.headings, "!important"],
      },
      h2: {
        fontFamily: widgetBoldFont,
        color: [colors.headings, "!important"],
      },
      h3: {
        fontFamily: widgetBoldFont,
        color: [colors.headings, "!important"],
      },
      h4: {
        fontFamily: widgetBoldFont,
        color: [colors.headings, "!important"],
      },
      h5: {
        fontFamily: widgetBoldFont,
        color: [colors.headings, "!important"],
      },
      h6: {
        fontFamily: widgetBoldFont,
        color: [colors.headings, "!important"],
      },
      linkColors: {
        color: [colors.links, "!important"],
      },
    };

    muiTheme.overrides = {
      fontOverrides: {
        widgetBoldFont,
        widgetSemiBoldFont,
        widgetRegularFont,
        widgetMediumFont,
      },
      colors,
      MuiSnackbarContent: {
        root: {
          backgroundColor: colors.alerts,
          color: colors.alternateText,
        },
      },
      MuiTypography: {
        root: {
          color: colors.bodyText,
        },
        colorTextSecondary: {
          color: colors.alternateText,
        },
      },
      MuiToolbar: {
        root: {
          // Name of the rule
          //
        },
      },
      MuiLink: {
        root: {
          color: [colors.links, "!important"],
        },
        underlineHover: {
          "&:hover": {
            cursor: "pointer",
            color: [colors.linksHover, "!important"],
          },
        },
      },
      MuiMenu: {
        paper: {
          border: "2px solid",
          borderColor: colors.buttonBackground,
          borderRadius: "20px",
          minWidth: 200,
          maxHeight: 300,
          maxWidth: 300,
          overflowX: "auto",
        },
        list: {
          paddingRight: ["0px", "!important"],
          width: ["auto", "!important"],
        },
      },
      MuiMenuItem: {
        root: {
          overflow: "unset",
        },
      },
      MuiRadio: {
        root: {
          marginLeft: "11px",
        },
        colorPrimary: {
          "&$checked": {
            color: [colors.buttonBackground, "!important"],
          },
        },
      },
      MuiCheckbox: {
        colorPrimary: {
          "&$checked": {
            color: [colors.buttonBackground, "!important"],
          },
        },
      },
      MuiSnackbarContent: {
        root: {
          background: [colors.buttonBackground || "#c3c3c3", "!important"],
          color: [colors.buttonText || "#ffffff", "!important"],
        },
      },
      MuiPopover: {
        paper: {
          backgroundColor: "#ffffff",
        },
      },
      MuiChip: {
        root: {
          color: [colors.buttonText || "#ffffff", "!important"],
          background: [colors.buttonBackground, "!important"],
        },
        deleteIcon: {
          color: [colors.buttonText || "#ffffff", "!important"],
        },
      },
      MuiTabs: {
        indicator: {
          background: [colors.buttonBackground, "!important"],
        },
      },
      MuiTab: {
        textColorPrimary: {
          color: [colors.links, "!important"],
          fontFamily: widgetSemiBoldFont,
          fontSize: "14px",
          textTransform: "none",
          "&:hover": {
            color: [colors.linksHover, "!important"],
            textDecoration: "underline",
          },
          "&$selected": {
            fontWeight: "bold",
          },
        },
      },
      MuiTableRow: {
        root: {
          "&$hover": {
            cursor: "pointer",
          },
        },
      },
      MuiTableCell: {
        root: {
          padding: "10px",
        },
        head: {
          fontFamily: widgetSemiBoldFont,
          color: [colors.headings, "!important"],
        },
        body: {
          fontFamily: widgetRegularFont,
          fontSize: "12px",
          color: colors.bodyText,
        },
      },
      MuiInputLabel: {
        "&$focused": {
          color: [colors.buttonBackground, "!important"],
        },
      },
      MuiFab: {
        root: {
          fontFamily: widgetMediumFont,
          color: [colors.buttonText || "#ffffff", "!important"],
          boxShadow: "none",
          width: "auto",
          height: "40px",
          padding: "0 16px",
          minWidth: "102px",
          borderRadius: "20px",
          fontWeight: "normal",
          fontSize: "12px",
          "&:hover": {
            background: [colors.buttonBackgroundHover, "!important"],
            color: [colors.buttonTextHover, "!important"],
          },
          "&:disabled": {
            background: ["#9f9f9f", "!important"],
            color: [colors.buttonTextHover, "!important"],
          },
        },
        primary: {
          background: [colors.buttonBackground, "!important"],
          "&:hover": {
            background: [colors.buttonBackgroundHover, "!important"],
            color: [colors.buttonTextHover, "!important"],
          },
        },
      },
      MapMarker: {
        primary: colors.buttonBackground,
      },
      MuiOutlinedInput: {
        input: {
          padding: "5.5px 14px",
        },
      },
      MuiButton: {
        // Name of the styleSheet
        root: {
          fontFamily: widgetMediumFont,

          "&:hover": {
            background: [colors.buttonBackgroundHover, "!important"],
            color: [colors.buttonTextHover, "!important"],
          },
          "&:disabled": {
            background: ["#9f9f9f", "!important"],
            color: [colors.buttonTextHover, "!important"],
          },
        },
        containedPrimary: {
          background: [colors.buttonBackground, "!important"],
          color: [colors.buttonText || "#ffffff", "!important"],
        },
        textPrimary: {
          background: [colors.buttonBackground, "!important"],
          color: [colors.buttonText || "#ffffff", "!important"],

          "&:hover": {
            background: [colors.buttonBackgroundHover, "!important"],
            color: [colors.buttonTextHover, "!important"],
          },
        },
        "&$disabled": {
          background: ["#ccc", "!important"],
          color: [colors.buttonText || "#ffffff", "!important"],

          "&:hover": {
            background: ["#ccc", "!important"],
            color: [colors.buttonTextHover, "!important"],
          },
        },
      },
    };
  }

  return (
    <MuiThemeProvider theme={createMuiTheme(muiTheme)}>
      {props.children}
    </MuiThemeProvider>
  );
};

const enhance = withObservables(
  ["selectedSiteId"],
  ({ database, selectedSiteId }) => {
    return {
      config: database.collections
        .get("configs")
        .query(Q.where("id", selectedSiteId))
        .observe(),
    };
  }
);

export default withDatabase(enhance(ThemeWrapperComponent));

import { startsWith } from "lodash";
import ReactHtmlParser from "react-html-parser";

const regex = new RegExp("(w*##message##w*)");

export const stopEventPropagation = (e) => {
  e.stopPropagation();
};

export const preventDefaultEvent = (e) => {
  e.preventDefault();
};

/** 
 * Method to check if window width is less than 768px. 
 * if true, then it is considered a mobile screen and relevant UI is displayed
 */
export const checkIsMobileView = (screenSize = null) => {
  const windowWidth = screenSize || window.outerWidth;
  return windowWidth < 768;
};

/** 
 * Method to find the hidden message, 
 * Hidden message is a text in description the begins with ##message##
 */
export const getHiddenMessage = ({ description = null }) => {
  let hiddenMessage = null;
  if (description && regex.test(description)) {
    hiddenMessage = description.split("##message##")[1].split("</div>")[0];
    return ReactHtmlParser(hiddenMessage);
  }
  return null;
};

/** 
 * Method to update each filter's options, 
 * if 'show filtered options' check box is checked from the admin panel, only relevant options will be displayed
 */
export const updateFilters = (filtersList, updatedFilters) => {
  for (const filter in updatedFilters) {
    if (updatedFilters[filter].hasRelevancy === false) { // have to add boolean comparison to consider key not present as true
      continue;
    }

    let optionsList = updatedFilters[filter].options.reduce((map, obj) => {
      map[obj.value] = obj;
      return map;
    }, {});

    filtersList[filter].options = filtersList[filter].options.map(
      ({ label, value, key }) => {
        return {
          key,
          label,
          value,
          isDisabled: !optionsList[value],
        };
      }
    );
  }
};

export const removePreviousParams = (params) => {
  params.forEach((value, key) => {
    if (startsWith(key, "la-")) {
      params.delete(key);
    }
  });
  return params
};

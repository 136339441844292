import { forEach } from 'lodash';
import { synchronize } from '@nozbe/watermelondb/sync';
import { api } from '../../utils';

const getProgWithKeys = (programs) => {
  if (!Array.isArray(programs)) return {}
  const progWithKeys = {}
  for (const program of programs) {
    const { programId } = program;
    if (programId > 0) {
      progWithKeys[programId] = program;
    }
  }
  return progWithKeys;
}

const getPrograms = async (sitesInfo, database, updated, created, deleted) => {
  const promisesApi = [];
  forEach(sitesInfo, async (siteInfo) => {
    promisesApi.push(api.getPrograms(siteInfo.apiKey, siteInfo.siteId));
  });

  const apiPrograms = await Promise.all(promisesApi);
  const existingPrograms = await database.collections.get('programs').query().fetch();
  const dbPrograms = getProgWithKeys(existingPrograms)

  forEach(dbPrograms, async (dbProgram) => {
      await database.action(async () => {
        await dbProgram.markAsDeleted() // syncable
        await dbProgram.destroyPermanently() // permanent
    });
  });

  forEach(apiPrograms, ({ data }, index) => {
    const serverPrograms = getProgWithKeys(data);
    const masterPrograms = {};
    for (const id in serverPrograms) {
      const program = serverPrograms[id];
      if (serverPrograms[id].isMaster) {
        masterPrograms[program.programId] = program;
      }
    }    

    for (const id in serverPrograms) {
      let timeArray = null;
      if (serverPrograms[id].scheduleTimes) {
        timeArray = serverPrograms[id].scheduleTimes.replace('between', "").split(" to ");
      }
      const selectedSite = sitesInfo[index];
      const masterId = serverPrograms[id].masterProgramId;
      const selectedMasterProgram = (masterPrograms[masterId] && masterPrograms[masterId]) || {};
      const masterProgramName = (selectedMasterProgram && selectedMasterProgram.name) || '';
      const masterRegisterUrlHtml = (selectedMasterProgram && selectedMasterProgram.registerUrlHtml) || '';
      const masterProgramUrlHtml = (selectedMasterProgram && selectedMasterProgram.programUrlHtml) || '';
      
      const newProg = {
        ...serverPrograms[id],
        isChild: masterId > 0,
        masterRegisterUrlHtml: masterRegisterUrlHtml, masterProgramUrlHtml: masterProgramUrlHtml, masterProgramName: masterProgramName, siteId: parseInt(selectedSite.siteId), programId: parseInt(id), id, startingTime: timeArray ? timeArray[0] : null, endingTime: timeArray ? timeArray[1] : null, siteName: selectedSite.siteName
      }
      
      created.push(newProg)
    }
  });

  return {
    updated, created, deleted
  }
}

export const startup = async (database, sitesInfo) => {
  try {
    await synchronize({
      database,
      pullChanges: async ({ lastPulledAt }) => {
        const created = [], updated = [], deleted = [];
        const returnedPrograms = await getPrograms(sitesInfo, database, updated, created, deleted)
        const programs = returnedPrograms;
        return { changes: { programs }, timestamp: new Date() }
      },
      pushChanges: async ({ changes }) => { }
    })
  } catch (err) {
    console.error('Err', err)
    throw new Error("Invalid Site Id or Api Key")
  }
}

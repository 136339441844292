/** 
 * This file handles the stable sort logic for programs
 */

import * as moment from 'moment';
import { isEmpty } from 'lodash';
import { WEEKDAYS, daysSorterFromMonday, daysSorterFromSunday, CURRENCY_COLUMNS } from '../constants';
import { getFees } from "./feeDetails";

const convTime = (time) => {
  if (time) {
    if (time.length === 6) {
      return moment.utc(`0${time}`, "HH:mma");
    } else {
      return moment.utc(time, "HH:mma");
    }
  }
  return moment.utc().endOf("Day");
};

const convDate = (date) => {
  if (date) {
    return moment(date);
  }
  return null;
};

const getFeeValue = (prog, orderBy) => {
  const {
    earlyRegistrationTime, regularRegistrationTime, lateRegistrationTime, endRegistrationTime,
    earlyTeamFee, teamFee, lateTeamFee,
    earlyFreeAgentFee, freeAgentFee, lateFreeAgentFee,
    earlyIndividualFee, teamIndividualFee, lateTeamIndividualFee,
    usingVariableTeamFee
  } = prog

  if (orderBy === "teamFee" || (orderBy === "teamPrice" && !usingVariableTeamFee)) {
    return getFees(earlyRegistrationTime, regularRegistrationTime, lateRegistrationTime, earlyTeamFee, teamFee, lateTeamFee, endRegistrationTime);
  } else if (orderBy === "freeAgentFee") {
    return getFees(earlyRegistrationTime, regularRegistrationTime, lateRegistrationTime, earlyFreeAgentFee, freeAgentFee, lateFreeAgentFee, endRegistrationTime);
  } else if (orderBy === "teamIndividualFee" || (orderBy === "teamPrice" && usingVariableTeamFee)) {
    return getFees(earlyRegistrationTime, regularRegistrationTime, lateRegistrationTime, earlyIndividualFee, teamIndividualFee, lateTeamIndividualFee, endRegistrationTime);
  }
  return null;
};

const desc = (a, b, orderBy, firstDayOfTheWeek) => {
  if (orderBy === "scheduleTimes") {
    const first = convTime(a["startingTime"]);
    const second = convTime(b["startingTime"]);

    if (first.isAfter(second)) {
      return -1;
    } else {
      return 1;
    }
  } else if (orderBy === "scheduleDays") {
    const daysSorter =
      firstDayOfTheWeek === WEEKDAYS.MONDAY
        ? daysSorterFromMonday
        : daysSorterFromSunday;
    const first = a[orderBy] ? a[orderBy].split(",")[0] : "";
    const second = b[orderBy] ? b[orderBy].split(",")[0] : "";

    if ((daysSorter[second] || 0) < (daysSorter[first] || 0)) {
      return -1;
    } else if ((daysSorter[second] || 0) > (daysSorter[first] || 0)) {
      return 1;
    }
  } else if (orderBy === "startTime" || orderBy === "endRegistrationTime") {
    const first = convDate(a[orderBy]);
    const second = convDate(b[orderBy]);
    if (isEmpty(first)) {
      return 1;
    } else if (isEmpty(second)) {
      return -1;
    } else if (first.isAfter(second)) {
      return -1;
    }
    if (second.isAfter(first)) {
      return 1;
    }
  } else if (CURRENCY_COLUMNS.includes(orderBy)) {
    let first = getFeeValue(a, orderBy);
    let second = getFeeValue(b, orderBy);

    if (second < first) {
      return -1;
    }
    if (second > first) {
      return 1;
    }
    return 0;
  } else {
    const aValue = a[orderBy];
    const bValue = b[orderBy];

    if (typeof aValue === 'number' && typeof bValue === 'number') {
      return bValue - aValue;
    }
    
    if (isEmpty(aValue)) {
      return -1;
    }
    if (isEmpty(bValue)) {
      return 1;
    }
    if (!isEmpty(aValue) && !isEmpty(bValue)) {
      return bValue.localeCompare(aValue, "en", { numeric: true });
    }
    return 0;
  }
};

export const getSorting = (order, orderBy, firstDayOfTheWeek) => {
  return order === "desc"
    ? (a, b) => desc(a, b, orderBy, firstDayOfTheWeek)
    : (a, b) => -desc(a, b, orderBy, firstDayOfTheWeek);
};

export const stableSort = (array, cmp, secondaryCmp) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    let order = cmp(a[0], b[0]);
    if (order !== 0) return order;

    order = secondaryCmp(a[0], b[0]);
    if (order !== 0) return order;

    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};

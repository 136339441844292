import React from "react";
import { useHistory } from "react-router";
import * as moment from "moment";
import clsx from "clsx";
import { isEmpty } from "lodash";
import { ConfigContext } from "../containers/SiteConfig";
import { Avatar, Divider, Link } from "@material-ui/core";
import ActionButton from "./ActionButton";
import {
  getUrl,
  getSignUpAction,
  useQuery,
  CURRENCY_COLUMNS,
} from "../constants";
import {
  useStyles,
  formatData,
  getFeeDetails,
  stopEventPropagation,
  getHiddenMessage,
} from "../utils";

const ProgramListItem = ({ program }) => {
  const classes = useStyles();
  const history = useHistory();
  const query = useQuery();
  const id = query.get("id");
  const {
    columnSettings = [],
    signUpAction,
    showProcessingFee,
    displayPriceLabels,
    showAdditionalTeamFee,
    showHasPaymentPlans,
    showScheduleStandingLinks,
  } = React.useContext(ConfigContext);

  const columns = columnSettings.filter((col) => col.value);
  const hiddenMessage = getHiddenMessage(program);

  const getData = ({ id, customLabel, label }) => {
    let value = program[id];
    if (id === "sport") {
      return <b key={id}>{value}</b>;
    } else if (id === "name") {
      if (program["masterProgramName"]) {
        return (
          <>
            <h2
              key={id}
              className={clsx(classes.programNameCell, classes.programHeading)}
            >
              {program["masterProgramName"]}
            </h2>
            <h3
              key={id}
              className={clsx(classes.programNameCell, classes.programHeading)}
            >
              {value}
            </h3>
          </>
        );
      } else {
        return (
          <h2
            key={id}
            className={clsx(classes.programNameCell, classes.programHeading)}
          >
            {value}
          </h2>
        );
      }
    } else if (CURRENCY_COLUMNS.includes(id)) {
      return getFeeDetails(
        id,
        program,
        customLabel || label,
        classes,
        true,
        showProcessingFee,
        displayPriceLabels,
        showAdditionalTeamFee,
        showHasPaymentPlans
      );
    } else if (id === "programLogo150") {
      return (
        value && (
          <Avatar
            src={value}
            style={{ marginBottom: 5 }}
            className={classes.programIcon}
            key={`avatar-${id}`}
          />
        )
      );
    } else {
      const _value = formatData(id, value, program);

      return isEmpty(_value) ? null : (
        <p key={id} className={classes.listItemData}>
          <span>
            <b>{customLabel || label}:</b>
          </span>{" "}
          &nbsp;
          <span>{formatData(id, value, program)}</span>
        </p>
      );
    }
  };

  const getAction = () => {
    let action = [];
    const regEndDateStatus =
      program.endRegistrationTime &&
      moment.utc().isBefore(moment.utc(program.endRegistrationTime));

    if (!program.endRegistrationTime || regEndDateStatus) {
      action.push(
        <div style={{ marginTop: "10px" }} key={`action-btn-${program.id}`}>
          <ActionButton program={program} key={program.programId} />
        </div>
      );
    }

    if (program.state === "LIVE") {
      showScheduleStandingLinks === "yes" &&
        action.push(
          <div style={{ marginTop: "10px" }} key={`action-link-${program.id}`}>
            <Link
              target="_blank"
              rel="noopener"
              href={getUrl(program.scheduleUrlHtml)}
              onClick={stopEventPropagation}
            >
              {" "}
              View schedule{" "}
            </Link>{" "}
            <br />
            <Link
              target="_blank"
              rel="noopener"
              href={getUrl(program.standingsUrlHtml)}
              onClick={stopEventPropagation}
            >
              {" "}
              View standings{" "}
            </Link>
          </div>
        );
    }
    return action;
  };

  return (
    <>
      <div
        className={classes.programListItem}
        onClick={getSignUpAction(program, signUpAction, history, id)}
        key={`program-${id}`}
      >
        {columns.map((column) => getData(column))}
        {getAction()}
        {!isEmpty(hiddenMessage) && (
          <p key="message" style={{ paddingTop: 0 }}>
            <span className={classes.specialMessage}>{hiddenMessage}</span>
          </p>
        )}
      </div>
      <Divider />
    </>
  );
};

export default ProgramListItem;

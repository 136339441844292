import React from 'react';
import { Fab } from '@material-ui/core';
import { stopEventPropagation } from '../utils'

const AppButton = ({label, handler, className = '', ...props}) => {
  const handleButtonClick = (e) => {
    stopEventPropagation(e);
    handler()
  }

  const buttonProps = {
    ...props,
    onClick: handleButtonClick
  }
  return ( 
    <Fab {...buttonProps} className={className}>
      {label}
    </Fab>
   );
}
 
export default AppButton;
import React, { useRef, useState } from "react";
import { Grid, MenuItem, Menu, Button, TextField } from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { useStyles } from "../../utils/styles";
import { isEmpty } from "lodash";

const MapFilterItem = ({
  classes,
  label = null,
  type,
  searchKey,
  placeholder,
  children,
  setSearchValues,
  searchValues = {},
}) => {
  return (
    <Grid item sm={2} xs={12} style={{ textAlign: "left", padding: "5px" }}>
      <div style={{ marginBottom: "5px", minHeight: "15px" }}>
        {" "}
        {label ? <b>{label}</b> : ""}{" "}
      </div>
      {type === "text" ? (
        <div className={classes.mapFilter}>
          <TextField
            id="outlined-size-small"
            placeholder={placeholder}
            variant="outlined"
            size="small"
            value={searchValues[searchKey]}
            onChange={({ target: { value } }) =>
              setSearchValues(searchKey, value)
            }
          />
        </div>
      ) : (
        { ...children }
      )}
    </Grid>
  );
};

const MapFilters = ({
  searchValues,
  radiusOptions,
  setSearchValues,
  handleBtnClick,
}) => {
  const classes = useStyles();
  let anchorRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const handleSearchValues = (key, value) => {
    setSearchValues({ ...searchValues, [key]: value });
  };

  return (
    <div key="map-filters" className={classes.mapFilterContainer}>
      <Grid container>
        <Grid item xs={12} style={{ textAlign: "left", padding: "5px 0 0 5px" }}>
          <div className="mapFiltersHeading">
            <b>Add a zip code to see available programs</b>
          </div>
        </Grid>
        <MapFilterItem
          classes={classes}
          placeholder="Search By Zip Code"
          searchKey="zipCode"
          setSearchValues={handleSearchValues}
          searchValues={searchValues}
          type="text"
        />

        <MapFilterItem
          classes={classes}
          showInputField={false}
          type="dropdown"
          searchKey="radius"
          setSearchValues={handleSearchValues}
          searchValues={searchValues}
        >
          <>
            <div className={classes.mapFilter}>
              <Button
                ref={anchorRef}
                aria-haspopup="true"
                aria-controls={isOpen ? "menu-list-grow" : undefined}
                className={classes.filterButton}
                onClick={({ currentTarget }) =>
                  setIsOpen(!isOpen, currentTarget)
                }
              >
                {searchValues.radius
                  ? searchValues.radius.value
                  : "Filter by Radius"}
                <ExpandMore fontSize="small" />
              </Button>

              <Menu
                anchorEl={anchorRef.current}
                getContentAnchorEl={null}
                id="map-radius-filter"
                open={isOpen}
                onClose={() => null}
                keepMounted
              >
                <MenuItem
                  key="default"
                  onClick={() => {
                    setSearchValues({ ...searchValues, radius: null });
                    setIsOpen(!isOpen);
                  }}
                >
                  Filter by Radius
                  <ExpandLess fontSize="small" />
                </MenuItem>
                {radiusOptions.map((option) => (
                  <MenuItem
                    key={option.id}
                    onClick={(e) => {
                      setSearchValues({ ...searchValues, radius: option });
                      setIsOpen(false);
                    }}
                  >
                    {option.value}
                  </MenuItem>
                ))}
              </Menu>
            </div>
          </>
        </MapFilterItem>

        <MapFilterItem classes={classes} key="searchBtn">
          <Button
            disabled={isEmpty(searchValues.zipCode)}
            onClick={() => handleBtnClick()}
            className={classes.filterButton}
          >
            Search
          </Button>
        </MapFilterItem>
      </Grid>
    </div>
  );
};

export default MapFilters;

import React from "react";
import SearchBar from "material-ui-search-bar";

import { useStyles } from "../../utils";
import { Grid } from "@material-ui/core";

export const TeamsFilters = ({
  handleSearch,
  searchValue,
  setSearchValue,
  handleClearSearch,
}) => {
  const classes = useStyles();

  return (
    <>
      <hr className={classes.separator} />

      <Grid container spacing={2} className={classes.searchContainer}>
        <Grid item xs={2} className={classes.filterItem}>
          <SearchBar
            value={searchValue}
            onChange={(value) => setSearchValue(value)}
            onRequestSearch={handleSearch}
            onCancelSearch={handleClearSearch}
            style={{
              boxShadow: "none",
              border: "1px solid #ccc",
              height: 35,
            }}
          />
        </Grid>
      </Grid>
    </>
  );
};

import React, { useRef, useEffect, useMemo } from "react";
import { isEmpty } from "lodash";
import {
  Checkbox,
  MenuItem,
  Menu,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { useStyles, formatData } from "../utils";

const FilterItem = ({
  label,
  id,
  handleChange,
  setFilterValue,
  filter,
  selectedValues,
  visibleOptions,
}) => {
  const classes = useStyles();
  const [open] = React.useState(filter.isOpen);
  let anchorRef = useRef(null);
  const prevOpen = useRef(open);
  const disabled = useRef([]);

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const handleMenuItemClick =
    (value, isMultiSelect = false) =>
    () => {
      return handleChange(id, value, isMultiSelect);
    };

  const onFilterMenuButtonCLick = ({ currentTarget }) =>
    setFilterValue(
      { [id]: { ...filter, isOpen: !filter.isOpen } },
      currentTarget
    );

  const onFilterMenuClick = () =>
    setFilterValue({ [id]: { ...filter, isOpen: !filter.isOpen } });

  const getMultiSelectOptions = (options, label) => {
    const showSelectedValues = (id, value) => {
      return selectedValues.includes(value);
    };

    return options.map(({ label: optionLabel, value }) => {
      return (
        <MenuItem
          key={value}
          onClick={handleMenuItemClick(value, true)}
          className={"filters-menu__options"}
        >
          <>
            <Checkbox
              color="primary"
              checked={showSelectedValues(id, value)}
              value={value}
            />
            <span>{formatData(id, optionLabel)}</span>
          </>
        </MenuItem>
      );
    });
  };

  const getSingleSelectOptions = (options, label) => {
    return (
      <RadioGroup aria-label={label} name={label} value={selectedValues[0]}>
        {options.map(({ label: optionLabel, value }) => (
          <MenuItem key={value} onClick={handleMenuItemClick(value)}>
            <FormControlLabel
              style={{ pointerEvents: "none" }}
              value={value}
              control={
                <Radio
                  onClick={handleMenuItemClick(value)}
                  color="primary"
                  className={classes.filterCheckBox}
                  checked={selectedValues.includes(value)}
                />
              }
              label={formatData(id, optionLabel)}
            />
          </MenuItem>
        ))}
      </RadioGroup>
    );
  };
  const optionsList = useMemo(() => {
    const newOptions = [...filter.options].filter(
      ({ isDisabled }) => !isDisabled
    ); // We need to hide the disabled filter
    if (visibleOptions && !isEmpty(visibleOptions)) {
      return newOptions.filter(
        ({ key, value }) =>
          visibleOptions.includes(key) || visibleOptions.includes(value)
      );
    }
    return newOptions;
  }, [visibleOptions, filter.options]);

  return (
    <>
      <Button
        ref={anchorRef}
        aria-haspopup="true"
        aria-controls={open ? "menu-list-grow" : undefined}
        className={classes.filterButton}
        disabled={!optionsList.length}
        onClick={onFilterMenuButtonCLick}
      >
        {label}
        <ExpandMore fontSize="small" />
      </Button>
      <Menu
        anchorEl={anchorRef.current}
        getContentAnchorEl={null}
        className="filters-menu"
        id={id}
        open={filter.isOpen}
        onClose={onFilterMenuClick}
        keepMounted
      >
        <MenuItem
          key="default"
          className={classes.inFilterButton}
          onClick={onFilterMenuClick}
        >
          {label}
          <ExpandLess fontSize="small" />
        </MenuItem>
        {filter.isMultiSelect
          ? getMultiSelectOptions(optionsList, label)
          : getSingleSelectOptions(optionsList, label)}
      </Menu>
    </>
  );
};

export default FilterItem;

import React from "react";
import { pickBy, isEmpty, find, capitalize } from "lodash";
import { Chip, Link } from "@material-ui/core";
import { CancelOutlined } from "@material-ui/icons";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useStyles, stopEventPropagation } from "../utils";
import { days, stateDefaultValues } from "../constants";
import { ConfigContext } from "../containers/SiteConfig";
import { openSnackbar } from "../containers/Notifier";

const ProgramListMeta = ({
  programs,
  searchText,
  filters,
  handleDelete,
  setSearchText,
  handleClearFilters,
  programLink,
}) => {
  const classes = useStyles();
  const { programStateLabels, filtersConfig } = React.useContext(ConfigContext);
  let selectedFilters = pickBy(filters, (value) => !isEmpty(value));
  let filterItems = [];

  for (const key in selectedFilters) {
    // eslint-disable-next-line no-loop-func
    filterItems = filterItems.concat(
      selectedFilters[key].map((value) => {
        let label = value;
        if (key === "scheduleDays") {
          const dayObj = find(days, (d) => d.key === value.trim());
          label = (dayObj && dayObj.filterLabel) || value;
        }
        if (key === "state") {
          label = programStateLabels[value]
            ? programStateLabels[value]
            : stateDefaultValues[value];
        }
        if (key === "age") {
          label = `${value} y/o`;
        }
        const currentFilter = find(filtersConfig, { id: key });
        if (currentFilter && currentFilter.visibility) {
          return (
            <Chip
              key={value}
              className={classes.filterChips}
              size="small"
              label={key === "age" ? label : label.replace(/\w+/g, capitalize)}
              deleteIcon={<CancelOutlined fontSize="small" />}
              onDelete={() => handleDelete(key, value)}
            />
          );
        }
        return "";
      })
    );
  }
  if (!isEmpty(searchText)) {
    searchText.forEach((term) => {
      filterItems.push(
        <Chip
          key={`serachText-${term}`}
          className={classes.filterChips}
          size="small"
          label={term}
          deleteIcon={<CancelOutlined fontSize="small" />}
          onDelete={() =>
            setSearchText(searchText.filter((text) => text !== term))
          }
        />
      );
    });
  }

  const onClearFilterClick = (e) => {
    stopEventPropagation(e);
    handleClearFilters();
  };

  return (
    <div className={classes.listResultInfo}>
      <span className={classes.programCount}>{programs.length} programs</span>
      <span>{filterItems}</span>
      {filterItems.length > 0 && (
        <span>
          <small>
            <Link
              target="_blank"
              rel="noopener"
              href={null}
              onClick={onClearFilterClick}
            >
              Clear Filters
            </Link>
          </small>
        </span>
      )}
      <span
        className={`copy-link ${filterItems.length > 0 && "v-separator"}`}
      >
        <small>
          <CopyToClipboard
            text={programLink}              
            onCopy={(text, result) => {
              if (text) { 
                openSnackbar("Link copied.")
              } else {
                openSnackbar("Link copied does not work in preview mode.", "error")
              };
            }}
          >
            <span className={classes.copyLink}> Copy Link </span>
          </CopyToClipboard>
        </small>
      </span>
    </div>
  );
};

export default ProgramListMeta;

import React from 'react';
import {useHistory } from 'react-router';
import { ConfigContext } from '../containers/SiteConfig';
import { disabledButtonValues, registrationStatuses, useQuery, getSignUpAction } from '../constants';
import AppButton from './AppButton';

const ActionButton = ({ program }) => {
  const history = useHistory();
  const query = useQuery();
  const { signUpAction, soldOutButtonText, signUpButtonText } = React.useContext(ConfigContext);

  const { registrationStatus: regStatus = '' } = program;

  if (disabledButtonValues.includes(regStatus)) {
    return (
      <AppButton 
        key={`sold-out-${program.id}`}
        disabled
        label={regStatus === 'SOLD_OUT' ? (soldOutButtonText || registrationStatuses[regStatus]) : registrationStatuses[regStatus]}
        handler={getSignUpAction(program, signUpAction, history, query.get("id"), true)}
      />
    )
  } else {
    return (
      <>
        <AppButton 
          key={`sign-up-${program.id}`}
          label={signUpButtonText || 'Sign Up'}
          handler={getSignUpAction(program, signUpAction, history, query.get("id"))}
          color='primary'
        />
      </>
    )
  }
}

export default ActionButton;
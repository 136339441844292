import Axios from "axios";
import jsonpAdapter from "axios-jsonp";
export let api;

// Axios instance and api listing
export const createApi = () => {
  api = Axios.create({
    baseURL: `https://public.${process.env.REACT_APP_LA_DOMAIN}/v1/sites/`,
    adapter: jsonpAdapter,
  });

  api = {
    ...api,
    getSiteTheme: (apiKey, siteId) =>
      api.get(
        `https://public.${process.env.REACT_APP_LA_DOMAIN}/v1/sites/${siteId}/?x-api-key=${apiKey}`
      ),
    getPrograms: (apiKey, siteId) =>
      api.get(
        `https://public.${process.env.REACT_APP_LA_DOMAIN}/v1/sites/${siteId}/programs/current?x-api-key=${apiKey}`
      ),
    getProgramDetail: (apiKey, programId, siteId) =>
      api.get(
        `https://public.${process.env.REACT_APP_LA_DOMAIN}/v1/sites/${siteId}/programs/${programId}?x-api-key=${apiKey}`
      ),
    getProgramTeamsDetail: (programId, siteId, apiKey) =>
      api.get(
        `https://public.${process.env.REACT_APP_LA_DOMAIN}/v1/sites/${siteId}/programs/${programId}/teams?x-api-key=${apiKey}`
      ),
    getScheduleDetail: (programId, siteId, apiKey) =>
      api.get(
        `https://public.${process.env.REACT_APP_LA_DOMAIN}/v1/sites/${siteId}/programs/${programId}/schedule?x-api-key=${apiKey}`
      ),
    getTeamData: (teamProfileURL) => api.get(`${teamProfileURL}`),
  };
};

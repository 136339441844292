import React, { useEffect, useState } from "react";
import SearchBar from "material-ui-search-bar";

import { useStyles } from "../../utils";
import { Grid, Select, MenuItem } from "@material-ui/core";
import { cloneDeep, uniq, startCase } from "lodash";

export const RosterFilters = ({
  rosterData,
  searchValue,
  setSearchValue,
  handleSearch,
  selectedFilters,
  setSelectedFilters,
  onHandleClearSearch
}) => {
  const classes = useStyles();
  const [filterOptions, setFilterOptions] = useState({
    roles: [],
    genders: [],
  });

  useEffect(() => {
    const loadFilterOptions = async () => {
      const getRoleFilterOptions = () => {
        const options = [];
        rosterData.forEach((data) => {
          options.push(data.role);
        });
        return uniq(options);
      };
      
      const getGenderFilterOptions = () => {
        const options = [];
        rosterData.forEach((data) => {
          options.push(data.gender);
        });
        return uniq(options);
      };
      
      const filters = {
        roles: await getRoleFilterOptions(),
        genders: await getGenderFilterOptions(),
      }
      
      setFilterOptions(filters);
    };
    
    loadFilterOptions();
  }, []);

  const handleFilterSelect =
    (key) =>
    ({ target: { value } }) => {
      const selections = cloneDeep(selectedFilters);
      if (value === "All Genders" || value === "All Roles") {
        delete selections[key];
        setSelectedFilters(selections);
      } else {
        setSelectedFilters({ ...selections, [key]: value });
      }
    };

  return (
    <Grid container spacing={2} className={classes.filterItemsContainer}>
      <Grid item xs={10} className={classes.filterItem}>
        <Select
          variant="outlined"
          className={classes.filterSelect}
          defaultValue={"All Roles"}
          value={selectedFilters.role || "All Roles"}
          onChange={handleFilterSelect("role")}
          MenuProps={{
            className: "filterOptions",
          }}
          key="roles-filter"
        >
          <MenuItem value="All Roles" key="all roles">
            All Roles
          </MenuItem>
          {filterOptions.roles.map((role) => (
            <MenuItem value={role} key={role}>
              {startCase(role)}
            </MenuItem>
          ))}
        </Select>

        <Select
          variant="outlined"
          className={classes.filterSelect}
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          defaultValue={"All Genders"}
          value={selectedFilters.gender || "All Genders"}
          label="All gender"
          onChange={handleFilterSelect("gender")}
          key="genders-filter"
        >
          <MenuItem value="All Genders" key="all gender">
            All Genders
          </MenuItem>
          {filterOptions.genders.map((gender) => (
            <MenuItem value={gender} key={gender}>
              {gender}
            </MenuItem>
          ))}
        </Select>
      </Grid>

      <Grid item xs={2} className={classes.filterItem}>
        <SearchBar
          value={searchValue}
          onChange={(value) => setSearchValue(value)}
          onRequestSearch={handleSearch}
          key='roster-search'
          onCancelSearch={onHandleClearSearch}
          style={{
            boxShadow: "none",
            border: "1px solid #ccc",
            height: 35,
          }}
        />
      </Grid>
    </Grid>
  );
};

import { Database } from "@nozbe/watermelondb";
import LokiJSAdapter from '@nozbe/watermelondb/adapters/lokijs';

import schema from './models/schema'
import Program from "./models/Programs";
import Config from "./models/Config";


// var is_safari = navigator.userAgent.indexOf("Safari") > -1;
// var is_chrome = navigator.userAgent.indexOf('Chrome') > -1;
// if ((is_chrome) && (is_safari)) {is_safari = false;}
// if (is_safari) {
//   window.indexedDB = require('fake-indexeddb')
// }

// First, create the adapter to the underlying database:
const adapter = new LokiJSAdapter({
  useWebWorker: false,
  dbName: 'la-widget-model',
  schema,
})

// Then, make a Watermelon database from it!
export const database = new Database({
  adapter,
  modelClasses: [ Program, Config ],
  actionsEnabled: true,
})

import * as moment from 'moment';
import { isEmpty, startCase, lowerCase,split } from 'lodash';
import { genderValues, registrationStatuses } from '../constants';

/** 
 * Method to price amounts upto 2 decimal places
 */
export const formatCurrency = amt => {
  if (amt === 'N/A') {
    return amt;
  } else if (!amt) {
    return 'N/A';
  }
  return `$${Number.parseFloat(amt).toFixed(2)}`;
}
export const formatTime = time => {
  return time ? moment.utc(time).format("MM/DD/YYYY") : null;
}

/** 
 * Method to format date for age display
 */
export const customDateFormat = (time) => {
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
  ];
  let formattedDate = null;

  const date = moment.utc(time).format("M/DD/YY");
  const dates = split(date, '/');
  if(dates && dates.length > 0){
    const month = +dates[0];
    const day = +dates[1];
    const year = +dates[2];
    formattedDate = `${months[month-1]} ${day} '${year}`
  }
  
  return formattedDate;
}

export const formatScheduleTime = scheduleTime => {
  if (isEmpty(scheduleTime)) {
    return '';
  }
  return scheduleTime.replace('Between ', '').replace('and', '-');
}

const formatString = value => {
  return startCase(lowerCase(value))
}

/** 
 * Method to format data filter options and program list
 */
export const formatData = (id, value, program) => {
  switch (id) {
    case 'leagueFormat':
      return value
    case 'startTime':
      return value ? moment.utc(value).format("MM/DD/YYYY") : '';
    case 'scheduleTimes':
      return formatScheduleTime(value);
    case 'scheduleDays':
      return (value || '').split(',').join(', ');
    case 'endRegistrationTime':
      return value ?  moment.utc(value).subtract(1, 'days').format("MM/DD/YYYY") : null;
    case 'teamFee':
    case 'freeAgentFee':
      return formatCurrency(value || 'N/A');
    case 'gender':
      return genderValues[value] || formatString(value);
    case 'registrationStatus':
      if(program.publicRegistrationTime && moment().unix() > moment(program.publicRegistrationTime) && !value){
        return 'Open';
      }
      return registrationStatuses[value]  || formatString(value);
    case 'season': 
      return formatString(value);
    default:
      return value;
  }
}
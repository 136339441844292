import React, { useEffect } from "react";
import ReactHtmlParser from "react-html-parser";
import * as moment from "moment";
import { isEmpty, startsWith } from "lodash";
import windowSize from "react-window-size";
import { CopyToClipboard } from "react-copy-to-clipboard";
import clsx from "clsx";
import { Grid, Link } from "@material-ui/core";
import {
  useStyles,
  checkIsMobileView,
  formatScheduleTime,
  formatCurrency,
  customDateFormat,
} from "../utils";
import AppButton from "./AppButton";
import { ConfigContext } from "../containers/SiteConfig";
import { openSnackbar } from "../containers/Notifier";
import {
  registrationStatuses,
  disabledButtonValues,
  alterUrl,
  getUrl,
  genderValues,
} from "../constants";
import { sendMessage } from "../iframeConnect";

const getDetail = (title, value, programDetails) => {
  if (isEmpty(value)) {
    return null;
  }
  return (
    <div className={programDetails}>
      <span>
        <b> {title} </b>
      </span>
      <br />
      <span> {value} </span> <br />
    </div>
  );
};

const getFeeDetail = (title, value, perPlayer, showWholeFee) => {
  const _value = formatCurrency(value);
  if ((!showWholeFee && !perPlayer) || _value === "N/A") {
    return;
  }
  return (
    <div>
      <span>
        <b> {title}: </b>
      </span>
      <span>
        {showWholeFee && formatCurrency(value)}
        {showWholeFee && perPlayer && perPlayer !== "N/A" && <br />}
        {perPlayer && <> {formatCurrency(perPlayer)} per player</>}
      </span>
      <br />
    </div>
  );
};

const getAgeDetail = (title, value, isDate) => {
  if (!value) {
    return;
  }
  let ageValue = `${value} years old`;

  if (isDate) {
    ageValue = customDateFormat(value);
  }
  return (
    <div>
      <span>
        <b> {title}: </b>
      </span>
      <span>{ageValue}</span>
    </div>
  );
};

const Details = React.memo(({program, classes, parentUrl, siteId}) => {
  const {
    minAgeLimit,
    maxAgeLimit,
    experienceLevel,
    leagueFormat,
    ageLimitEffectiveDate,
    scheduleDays = "",
    startTime = "",
    scheduleTimes = "",
    gender = "",
    division = "",
    location = "",
    programId,
  } = program || {};
  let programDirectLink = "";
  console.log("PARENT URL DETAILS", parentUrl);

  if (parentUrl) {
    let url = new URL(parentUrl);
    let query = new URLSearchParams(url.search);

    const widgetParams = [];

    query.forEach((value, key) => {
      if (startsWith(key, "la-")) {
        widgetParams.push(key);
      }
    });

    widgetParams.forEach((key) => {
      query.delete(key);
    });

    query.append(`la-program`, programId);
    query.append(`la-siteId`, siteId);

    programDirectLink = `${url.origin}${url.pathname}?${query}`;
    if (parentUrl !== programDirectLink) {
      console.log("HERE");
      sendMessage("updateURL", programDirectLink);
    }
  }

  return (
    <div>
      {programDirectLink && (
        <CopyToClipboard
          text={programDirectLink}
          onCopy={(text, result) => {
            if (result) openSnackbar("Link copied.");
          }}
        >
          <span className={classes.copyLink}> Copy direct Link </span>
        </CopyToClipboard>
      )}
      {getDetail(
        "Days of the week",
        scheduleDays.split(",").join(", "),
        classes.programDetails
      )}
      {getDetail(
        "Start date",
        startTime ? moment.utc(startTime).format("MMM DD, YYYY") : "",
        classes.programDetails
      )}
      {getDetail(
        "Game times",
        formatScheduleTime(scheduleTimes),
        classes.programDetails
      )}
      {getDetail("Level", experienceLevel, classes.programDetails)}
      {getDetail("Gender", genderValues[gender], classes.programDetails)}
      {getDetail("Division", division, classes.programDetails)}
      {getDetail(
        "Location",
        program.locationUrlHtml ? (
          <Link
            target="_blank"
            rel="noopener"
            href={getUrl(program.locationUrlHtml)}
          >
            {location}
          </Link>
        ) : (
          location
        ),
        classes.programDetails
      )}
      {getDetail("Format", leagueFormat, classes.programDetails)}
      {getAgeDetail("Minimum age", minAgeLimit)}
      {getAgeDetail("Maximum age", maxAgeLimit)}
      {getAgeDetail("Age as of", ageLimitEffectiveDate, true)}
    </div>
  );
});

const getRegisterFee = (
  feeDetails,
  registerUrlHtml,
  buttonText,
  earlyRegistrationTime,
  regularRegistrationTime,
  endRegistrationTime,
  processingFeeLabel
) => {
  const {
    earlyFee = null,
    perPlayerEarlyFee = null,
    perPlayerRegularFee = null,
    perPlayerLateFee = null,
    lateFee = null,
    processingFee = null,
    percentageProcessingFee = null,
    regularFee = null,
    showWholeFee,
  } = feeDetails;
  let processFee = null;
  const customFeeLabel =
    processingFeeLabel && processingFeeLabel.length > 0
      ? processingFeeLabel
      : "processing fee";
  if (processingFee || percentageProcessingFee) {
    if (percentageProcessingFee) {
      processFee = (
        <small>
          +{percentageProcessingFee}% {customFeeLabel}{" "}
        </small>
      );
    } else {
      processFee = (
        <small>
          +{formatCurrency(processingFee)} {customFeeLabel}{" "}
        </small>
      );
    }
  }
  const showEarlyBirdDiscount =
    earlyRegistrationTime &&
    moment(earlyRegistrationTime).unix() < moment().unix() &&
    regularRegistrationTime &&
    moment().unix() < moment(regularRegistrationTime).unix();
  // Verify Show Early Bird with real data

  const regEndDateStatus =
    endRegistrationTime &&
    moment.utc().isBefore(moment.utc(endRegistrationTime));
  return (
    <div className="registration-action">
      {showEarlyBirdDiscount &&
        getFeeDetail("Early bird", earlyFee, perPlayerEarlyFee, showWholeFee)}
      {getFeeDetail("Regular", regularFee, perPlayerRegularFee, showWholeFee)}
      {getFeeDetail("Late", lateFee, perPlayerLateFee, showWholeFee)}

      {processFee}

      <p>
        {!endRegistrationTime || regEndDateStatus ? (
          <AppButton
            disabled={disabledButtonValues.includes(buttonText)}
            label={
              registrationStatuses[buttonText]
                ? registrationStatuses[buttonText]
                : buttonText || "Sign Up"
            }
            handler={() =>
              registerUrlHtml
                ? window.open(getUrl(registerUrlHtml), "_blank")
                : openSnackbar("Link not found.")
            }
            color="primary"
          />
        ) : (
          <AppButton
            disabled={true}
            label={registrationStatuses["REGISTRATION_CLOSED"]}
            handler={() =>
              registerUrlHtml
                ? window.open(getUrl(registerUrlHtml), "_blank")
                : openSnackbar("Link not found.")
            }
            color="primary"
          />
        )}
      </p>
    </div>
  );
};

const LeagueProfile = ({ program, windowWidth, siteId }) => {
  const classes = useStyles();
  const isMobileView = checkIsMobileView();
  const { parentUrl = null } = React.useContext(ConfigContext);

  console.log("parentUrl", parentUrl);
  useEffect(() => {
    return () => {
      if (parentUrl) {
        let url = new URL(parentUrl);
        let query = new URLSearchParams(url.search);

        const widgetParams = [];
        query.forEach((value, key) => {
          if (startsWith(key, "la-")) {
            widgetParams.push(key);
          }
        });

        widgetParams.forEach((key) => {
          query.delete(key);
        });
        sendMessage("updateURL", `${url.origin}${url.pathname}`);
      }
    };
  }, []);

  const individualFees = {
    earlyFee: program.earlyFreeAgentFee,
    regularFee: program.freeAgentFee,
    lateFee: program.lateFreeAgentFee,
    processingFee: program.individualProcessingFee,
    showWholeFee: true,
    percentageProcessingFee: program.percentageProcessingFee,
  };

  const teamFees = {
    earlyFee: program.earlyTeamFee,
    regularFee: program.teamFee,
    lateFee: program.lateTeamFee,
    perPlayerEarlyFee: program.earlyIndividualFee,
    perPlayerRegularFee: program.teamIndividualFee,
    perPlayerLateFee: program.lateTeamIndividualFee,
    processingFee: program.individualProcessingFee,
    showWholeFee: true,
    percentageProcessingFee: program.percentageProcessingFee,
  };

  const joiningFees = {
    perPlayerEarlyFee: program.earlyIndividualFee,
    perPlayerRegularFee: program.teamIndividualFee,
    perPlayerLateFee: program.lateTeamIndividualFee,
    processingFee: program.individualProcessingFee,
    showWholeFee: false,
  };

  return (
    <Grid
      container
      className={clsx(isMobileView && classes.programGridContainer)}
      spacing={4}
    >
      <Grid item xs={12} sm={8} style={{ overflowX: "auto" }}>
        {!isEmpty(program.description) ? (
          ReactHtmlParser(program.description)
        ) : (
          <p>No description provided.</p>
        )}
      </Grid>

      <Grid item xs={12} sm={2}>
        <p>
          <b>DETAILS</b>
        </p>
        <Details
          program={program}
          classes={classes}
          parentUrl={parentUrl}
          siteId={siteId}
        />
      </Grid>

      <Grid item xs={12} sm={2}>
        {program.freeAgentFee && (
          <>
            <p>
              <b>REGISTER AN INDIVIDUAL</b>
            </p>
            {getRegisterFee(
              individualFees,
              alterUrl(program.registerUrlHtml, "individual", program.mode),
              program.registrationStatus,
              program.earlyRegistrationTime,
              program.regularRegistrationTime,
              program.endRegistrationTime,
              program.processingFeeLabel
            )}
          </>
        )}
        {(program.teamFee || program.teamIndividualFee) && (
          <>
            <hr className="registration-details-separator" />
            <p>
              <b>REGISTER A TEAM</b>
            </p>
            {getRegisterFee(
              teamFees,
              alterUrl(program.registerUrlHtml, "team", program.mode),
              program.registrationStatus,
              program.earlyRegistrationTime,
              program.regularRegistrationTime,
              program.endRegistrationTime,
              program.processingFeeLabel
            )}
            {program.allowTeamPlayerRegistration && (
              <>
                <hr className="registration-details-separator" />
                <p>
                  <b>JOIN AN EXISTING TEAM</b>
                </p>
                {getRegisterFee(
                  joiningFees,
                  alterUrl(program.registerUrlHtml, null, program.mode),
                  "JOIN",
                  program.earlyRegistrationTime,
                  program.regularRegistrationTime,
                  program.endRegistrationTime,
                  program.processingFeeLabel
                )}
              </>
            )}
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default windowSize(LeagueProfile);

import moment from "moment-timezone";
import { isEmpty } from "lodash";

export const getNewDate = (dateTime) => {
  const date = moment.utc(dateTime).tz("America/New_York");
  return new Date(
    date.year(),
    date.month(),
    date.date(),
    date.hour(),
    date.minute(),
    date.second()
  ).toISOString();
};
export const renderMap = (loc) => () => {
  window.open(
    `https://www.google.com/maps/?q=${loc.location}&sll=${loc.latitude},${loc.longitude}`
  );
};

export const formatProgramSchedule = ({ data: { games } }, teamId) => {
  let gamesList = games;

  if (teamId) {
    gamesList = games.filter(
      (game) =>
        game.teamId === parseInt(teamId) ||
        game.team1Id === parseInt(teamId) ||
        game.team2Id === parseInt(teamId)
    );
  }
  
  return gamesList.map((game) => {
    const eventDate = game.startTime || game.startDate || game.start;
    const eventEndDate = game.endDate || game.endTime || undefined;
    const {startTime, endTime, ...gameDetails} = game
    return {
      ...gameDetails,
      id: game.gameId || undefined,
      title: game.typeLabel || game.title || undefined,
      subTitle: game.typeLabel || undefined,
      away: game.team2 || undefined,
      home: game.team1 || game.teamName || undefined,
      start: getNewDate(eventDate) || undefined,
      end: eventEndDate ? getNewDate(eventEndDate) : undefined,
      event: game.type || undefined,
      eventSubText: game.typeLabel || undefined,
      location: game.locationName || game.location || undefined,
      subLocation: game.subLocationName || undefined,
      startTimeUNIX: eventDate || undefined,
      endTimeUnix: eventEndDate,
    };
  });
};

export const scheduleColumns = [
  {
    title: "Event",
    key: "title",
    hasSubtext: false,
    subTextKey: "eventSubText",
  },
  {
    title: "Start Time",
    hasSubtext: false,
    key: "startTimeUNIX",
  },
  {
    title: "End Time",
    hasSubtext: false,
    key: "endTimeUnix",
  },
  {
    title: "Home Team",
    key: "home",
    hasSubtext: false,
  },
  {
    title: "",
    hasSubtext: false,
  },
  {
    title: "Away Team",
    key: "away",
    hasSubtext: false,
  },
  {
    title: "Location",
    key: "location",
    hasSubtext: true,
    subTextKey: "subLocation",
  },
];

export const rosterColumns = [
  {
    title: "Name",
    key: "name",
  },
  {
    title: "Team",
    key: "team",
  },
  {
    title: "#",
    key: "number",
  },
  {
    title: "Role",
    key: "role",
  },
  {
    title: "Gender",
    key: "gender",
  },
  {
    title: "Birth Date",
    key: "birthDate",
  },
  {
    title: "Email",
    key: "email",
  },
];

export const formatRosterData = (data) => {
  if (!data || isEmpty(data)) return [];

  return data.map((rosterData) => {
    const { parentEmail, userId, firstName, lastName } = rosterData;

    return {
      name: `${firstName} ${lastName}`,
      number: userId,
      email: parentEmail,
      ...rosterData,
    };
  });
};

import React from 'react';
import { Button, TextField } from '@material-ui/core';
import { Search, Close } from '@material-ui/icons';
import { useStyles } from '../utils';

export const SearchButtons = ({ showSearchField, handleClick}) => {
  const classes = useStyles();
  return (
    <span style={{ display: 'flex' }}>
      {
        !showSearchField &&
          <Button className={classes.programNameCell} onClick={handleClick}> <Search /> </Button>
      }
      {
        showSearchField &&
          <Button className={classes.programNameCell} onClick={handleClick}> <Close /> </Button>
      }
    </span>
  )
}

export const SearchField = ({ searchValue, setSearchValue, handleSearch }) => {
  const handleEnter = ({keyCode}) => {
    if(keyCode === 13){
      handleSearch()
    }
  }
  return (
    <TextField
      fullWidth
      type="search"
      onKeyDown={handleEnter}
      onChange={({ target: { value } }) => setSearchValue(value)}
      value={searchValue}
    />
  )
}

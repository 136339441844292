import React from "react";
import {
  Table,
  TableCell,
  TableRow,
  TableBody,
  TableHead,
  Link,
  TableFooter,
} from "@material-ui/core";

import { useStyles, renderMap } from "../../../utils";

import "react-big-calendar/lib/css/react-big-calendar.css";
import moment from "moment-timezone";

const PopoverDetails = ({ popoverData }) => {
  const classes = useStyles();
  if (!popoverData) {
    return null;
  }
  const { extendedProps: gameDetails } = popoverData.event._def;


  return (
    <Table className={classes.eventTooltip}>
      <TableHead>
        <TableRow>
          <TableCell colSpan={gameDetails.away ? 1 : 3}>
            {gameDetails.home}
          </TableCell>
          {gameDetails.away ? (
            <>
              <TableCell>vs</TableCell>
              <TableCell>
                {gameDetails.away}
              </TableCell>
            </>
          ) : null}
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell>{popoverData.event._def.title}</TableCell>

          <TableCell>
            {moment
              .utc(gameDetails.startTimeUNIX)
              .tz("America/New_York")
              .format("hh:mm A")}
            <br />
          </TableCell>
          <TableCell>
            {gameDetails.endTimeUnix ? moment
              .utc(gameDetails.endTimeUnix)
              .tz("America/New_York")
              .format("hh:mm A")
              : ""
            }
            <br />
          </TableCell>
        </TableRow>
      </TableBody>
      <TableFooter>
        <TableRow>
          <TableCell>
            <Link
              onClick={renderMap({
                longitude: gameDetails.locationId,
                latitude: gameDetails.latitude,
                location: gameDetails.location,
              })}
            >
              {gameDetails.location}
            </Link>
            <br />
            {gameDetails?.subLocation && (
              <sub className={classes.secondaryText}>
                {gameDetails?.subLocation}
              </sub>
            )}
          </TableCell>
        </TableRow>
      </TableFooter>
    </Table>
  );
};

export default PopoverDetails;

import React from 'react';
import ReactDOM from 'react-dom';
import DatabaseProvider from '@nozbe/watermelondb/DatabaseProvider';
import { database } from './db';
import * as serviceWorker from './serviceWorker';
import './index.css';
import './fonts/Montserrat-Regular.ttf';
import './fonts/Montserrat-Medium.ttf';
import './fonts/Montserrat-SemiBold.ttf';
import './fonts/Montserrat-Bold.ttf';
import './fonts/Montserrat-Italic.ttf';
import App from './App';


ReactDOM.render(
  <DatabaseProvider database={database}>
    <App />
  </DatabaseProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

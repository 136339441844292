import React from 'react';
import { TableHead, TableRow, TableCell, TableSortLabel } from '@material-ui/core';
import { ConfigContext } from '../containers/SiteConfig';
import { COLUMN_WIDTHS } from '../constants';

const styles = {
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  }
};

const ProgramTableHeader = (props) => {
  const { columnSettings = [] } = React.useContext(ConfigContext);
  const columns = columnSettings.filter(col => col.value);
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {columns.map(({ id, customLabel, label }) => {
          return (
            <TableCell
              id={id}
              key={id}
              align='left'
              sortDirection={orderBy === id ? order : false}
              style={{ minWidth: COLUMN_WIDTHS[id] || 'auto' }}
            >
              {id === 'programLogo150' ?
                customLabel || label
                :
                <TableSortLabel
                  active={orderBy === id}
                  direction={order}
                  onClick={createSortHandler(id)}
                >
                  {customLabel || label}
                  {
                    orderBy === id ? (
                      <span style={styles.visuallyHidden}>
                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                      </span>
                    ) : null
                  }
                </TableSortLabel>
              }
            </TableCell>
          )
        })}
        <TableCell
          id={'actions'}
          key={'actions'}
          align='left'
        >
        </TableCell>
      </TableRow>
    </TableHead>
  )
};
export default ProgramTableHeader;
import React, { useEffect, useState } from "react";
import { includes, isEmpty, isNil } from "lodash";
import clsx from "clsx";
import moment from "moment";

import { ConfigContext } from "../../containers/SiteConfig";
import { checkIsMobileView } from "../../utils";

import BorderAllOutlinedIcon from "@material-ui/icons/BorderAllOutlined";
import DateRangeOutlinedIcon from "@material-ui/icons/DateRangeOutlined";

import { useStyles } from "../../utils";
import { Grid, Box } from "@material-ui/core";

export const SchedulesFilters = ({
  scheduleList,
  showCalendar,
  setShowCalendar,
  disable: dates,
}) => {
  const classes = useStyles();
  const [filterOptions, setFilterOptions] = useState({
    teams: [],
    locations: [],
  });
  const { screenSize } = React.useContext(ConfigContext);
  const isMobileView = checkIsMobileView(screenSize);

  useEffect(() => {
    const loadFilterOptions = async () => {
      const getTeamFilterOptions = () => {
        const options = [];
        scheduleList.forEach((game) => {
          if (!includes(options, game.away)) {
            options.push(game.away);
          }

          if (!includes(options, game.home)) {
            options.push(game.home);
          }
        });
        return options;
      };

      const getLocationFilterOptions = () => {
        const options = [];
        scheduleList.forEach((game) => {
          if (!includes(options, game.location)) options.push(game.location);
        });
        return options;
      };

      const filters = {
        teams: await getTeamFilterOptions(),
        locations: await getLocationFilterOptions(),
      };
      setFilterOptions(filters);
    };

    loadFilterOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scheduleList]);

  const hideCalendar = () => {
    setShowCalendar(false);
  };

  const displayCalendar = () => {
    setShowCalendar(true);
  };

  return (
    <>
      <Grid container spacing={2} className={classes.filterItemsContainer}>
        <Box
          clone
          order={{
            md: isMobileView ? 3 : 1,
            lg: isMobileView ? 3 : 1,
            sm: isMobileView ? 3 : 1,
            xs: isMobileView ? 3 : 1,
          }}
        >
          <Grid
            item
            className={classes.filterItem}
            lg={isMobileView ? 12 : 1}
            md={isMobileView ? 12 : 2}
            sm={isMobileView ? 12 : 2}
            xs={isMobileView ? 12 : 1}
          >
            <div className={classes.listDisplaySetting}>
              <BorderAllOutlinedIcon
                className={clsx(
                  classes.displayIcon,
                  !showCalendar && classes.selectedDisplay
                )}
                onClick={hideCalendar}
              />
              <DateRangeOutlinedIcon
                className={clsx(
                  classes.displayIcon,
                  showCalendar && classes.selectedDisplay
                )}
                onClick={isEmpty(dates) ? undefined : displayCalendar}
              />
            </div>
          </Grid>
        </Box>
      </Grid>
    </>
  );
};

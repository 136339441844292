import React, {useState} from 'react';
import ThemeWrapperComponent from './containers/ThemeWrapper';
import SiteConfigWrapper from './containers/SiteConfig';
import Routes from './Routes';
import Notifier from './containers/Notifier';
import { withDatabase } from '@nozbe/watermelondb/DatabaseProvider';

function App(props) {
  const [selectedSiteId, setSelectedSiteId] = useState(''); // This site is used to fetch theme. 
  const [sitesInfo, setSitesInfo] = useState();
  
  return (
    <SiteConfigWrapper
      setSelectedSiteId={setSelectedSiteId}
      setSitesInfo={setSitesInfo}
    >
      <ThemeWrapperComponent
        selectedSiteId={selectedSiteId}
        sitesInfo={sitesInfo}
      >
        <Routes />
        <Notifier />
      </ThemeWrapperComponent>
    </SiteConfigWrapper>
  );
}

export default withDatabase(App);

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
import React, { useState, useEffect, createContext } from "react";
import {
  remove,
  isEmpty,
  forEach,
  filter,
  cloneDeep,
  find,
  uniq,
  startsWith,
  isNil,
} from "lodash";
import { useHistory } from "react-router";
import windowSize from "react-window-size";
import { Divider, Button, Paper } from "@material-ui/core";
import { FilterList } from "@material-ui/icons";

import { ConfigContext } from "../containers/SiteConfig";
import { SearchButtons, SearchField } from "./Search";
import LeagueFilters from "./Filters";
import ProgramTable from "./ProgramTable";
import ProgramList from "./ProgramList";
import FilterDrawer from "./FiltersDrawer";
import ZipCodeFinder from "./zipCodeFinder";

import {
  defaultMapSearchState,
  defaultSelectedFilters,
  filtersMessage,
  useQuery,
  radiusOptions,
} from "../constants";

import {
  getFilters,
  getPreselectedFilters,
  useStyles,
  checkIsMobileView,
  getSelectedFilters,
} from "../utils";
import { sendMessage } from "../iframeConnect";

export const MapContext = createContext(null);

function ProgramsList(props) {
  const history = useHistory();
  const query = useQuery();
  const {
    filtersConfig: _filtersConfig,
    columnSettings,
    sitesInfo,
    parentUrl = null,
    hasCustomFilters = "false",
    listingId,
    screenSize = null,
    showZipCodeFinder = false,
    defaultZipCode = null,
    defaultSort = "startTime",
    defaultSortDirection = "asc",
  } = React.useContext(ConfigContext);
  const filtersConfig = cloneDeep(_filtersConfig);
  const [order, setOrder] = useState(defaultSortDirection);
  const [orderBy, setOrderBy] = useState(defaultSort);
  // if there's a custom name for a column, reflect that in filters too

  forEach(filtersConfig, (config) => {
    const customFilter = filter(columnSettings, (col) => col.id === config.id);
    if (customFilter && customFilter.length && customFilter[0].customLabel) {
      config.label = customFilter[0].customLabel;
    }
  });

  if (parentUrl && query.get("isBack") !== "true") {
    let url = new URL(parentUrl);
    let programQuery = url.searchParams.get("la-program") || null;
    let siteQuery = url.searchParams.get("la-siteId") || null;
    if (programQuery) {
      history.push(`/details/${programQuery}/${siteQuery}?id=${listingId}`);
    }
  }

  const isMobileView = checkIsMobileView(screenSize);
  const classes = useStyles();

  const [filtersList, setFiltersList] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState(
    defaultSelectedFilters
  );

  const [isFiltersModified, setIsFiltersModified] = useState({
    isModified: false,
    id: "",
  });
  const [searchText, setSearchText] = useState([]);
  const [searchZipCode, setSearchZipCode] = useState(null);
  const [searchRadius, setSearchRadius] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [showSearchField, setShowSearchField] = useState(false);
  const [markers, setMarkers] = useState([]);
  const [focusedProgram, setFocusedProgram] = useState(null);

  // default state for zip code finder
  const [mapSearchValues, setMapSearchValues] = useState(
    cloneDeep(defaultMapSearchState)
  );
  const [isOpen, setIsOpen] = useState(false);
  const [programLink, setProgramLink] = useState(null);

  useEffect(() => {
    let mapSearch = mapSearchValues;
    if (showZipCodeFinder !== "yes" && defaultZipCode) {
      mapSearch.zipCode = defaultZipCode;
    }
    if (defaultZipCode && isNil(searchZipCode)) {
      setSearchZipCode(defaultZipCode);
      setSearchRadius(radiusOptions[0]);
      mapSearch.zipCode = defaultZipCode;
    }

    if (parentUrl) {
      const url = new URL(parentUrl);
      const zipCode = url.searchParams.get("la-searchZipCode") || null;
      const radius = url.searchParams.get("la-searchRadius") || null;
      if (zipCode) {
        setSearchZipCode(zipCode);
        mapSearch.zipCode = zipCode;
      }

      if (radius) {
        let radiusOption = find(
          radiusOptions,
          (option) => option.id === parseInt(radius)
        );
        setSearchRadius(radiusOption);
        mapSearch.radius = radiusOption;
      }
    }
    setMapSearchValues(mapSearch);
  }, [defaultZipCode, mapSearchValues, parentUrl, showZipCodeFinder]);

  useEffect(() => {
    let link = parentUrl;
    let hasCustomFilters = "false";
    if (parentUrl) {
      let url = new URL(parentUrl);
      let query = new URLSearchParams(url.search);

      const widgetParams = [];
      query.forEach((value, key) => {
        if (startsWith(key, "la-")) {
          widgetParams.push(key);
        }
      });

      widgetParams.forEach((key) => {
        query.delete(key);
      });

      Object.keys(selectedFilters).forEach((key) => {
        if (!isEmpty(selectedFilters[key])) {
          hasCustomFilters = "true";
          uniq(selectedFilters[key]).forEach((item) =>
            query.append(`la-${key}`, item)
          );
        }
      });

      if (hasCustomFilters === "true") {
        query.append(`la-hasCustomFilters`, hasCustomFilters);
      }

      if (searchZipCode) {
        query.append(`la-searchZipCode`, searchZipCode);
      }

      if (searchRadius) {
        query.append(`la-searchRadius`, searchRadius.id);
      }

      if (hasCustomFilters === "true") {
        link = `${url.origin}${url.pathname}?${query}`;
      }

      if (parentUrl !== link) {
        sendMessage("updateURL", link);
      }

      setProgramLink(link);
    }
  }, [
    selectedFilters,
    searchRadius,
    searchZipCode,
    parentUrl,
    history.location,
  ]);

  useEffect(() => {
    loadFilters();
  }, [searchZipCode, searchRadius]);

  const loadFilters = async () => {
    const filters = getFilters(filtersConfig);
    setFiltersList(filters);
    const preSelectedFilter = getPreselectedFilters(filtersConfig);
    if (hasCustomFilters === "true") {
      const customSelectedFilters = getSelectedFilters(parentUrl);
      setSelectedFilters(customSelectedFilters);
    } else {
      setSelectedFilters({ ...preSelectedFilter });
    }
  };

  const handleDelete = async (key, value) => {
    remove(selectedFilters[key], (val) => val === value);
    setIsFiltersModified({ isModified: true, id: key });
    setSelectedFilters({ ...selectedFilters, [key]: selectedFilters[key] });
  };

  const handleClearFilters = async () => {
    setSelectedFilters(cloneDeep(defaultSelectedFilters));
    setSearchText([]);
  };

  const handleClick = () => {
    setShowSearchField(!showSearchField);
  };

  const handleSearch = () => {
    const searchStr = searchValue.trim();
    if (searchStr.length) {
      setSearchText(searchStr.split(" "));
    }
    setSearchValue("");
    setShowSearchField(false);
  };

  // Map search button click
  const handleMapSearch = () => {
    setSearchZipCode(mapSearchValues.zipCode);
    setSearchRadius(mapSearchValues.radius);
  };

  const toggleProgramFocus = (program) => setFocusedProgram(program);

  return (
    <MapContext.Provider value={{ focusedProgram, toggleProgramFocus }}>
      {/* Zip code finder */}
      {showZipCodeFinder === "yes" && (
        <ZipCodeFinder
          searchValues={mapSearchValues}
          setSearchValues={setMapSearchValues}
          handleSearch={handleMapSearch}
          searchZipCode={searchZipCode}
          markers={markers}
          defaultZipCode={defaultZipCode}
        />
      )}

      <Paper
        style={{ position: "relative", border: "none", boxShadow: "none" }}
      >
        {/* UI for desktop view */}

        {!isMobileView && (
          <>
          {/* filters panel */}
            <LeagueFilters
              sitesInfo={sitesInfo}
              listingId={listingId}
              selectedFilters={selectedFilters}
              setSelectedFilters={setSelectedFilters}
              searchText={searchText}
              filterConfig={filtersList}
              isFiltersModified={isFiltersModified}
              setIsFiltersModified={setIsFiltersModified}
              searchValue={searchValue}
              setSearchValue={setSearchValue}
              showSearchField={showSearchField}
              handleClick={handleClick}
              handleSearch={handleSearch}
            />
            {/* programs list table */}
            <ProgramTable
              sitesInfo={sitesInfo}
              filters={selectedFilters}
              handleDelete={handleDelete}
              handleClearFilters={handleClearFilters}
              searchText={searchText}
              setSearchText={setSearchText}
              searchZipCode={searchZipCode}
              searchRadius={searchRadius}
              showZipCodeFinder={showZipCodeFinder}
              setMarkers={setMarkers}
              defaultSort={defaultSort}
              defaultSortDirection={defaultSortDirection}
              programLink={programLink}
            />
          </>
        )}
        {/* UI for mobile view */}
        {isMobileView && (
          <>
          {/* search field */}
            <p className={classes.filterDrawerIcon}>
              <SearchButtons
                showSearchField={showSearchField}
                handleClick={handleClick}
              />

              {!showSearchField && !isEmpty(filtersList) && (
                <Divider
                  className={classes.headerDivider}
                  orientation="vertical"
                />
              )}

              {!showSearchField && (
                <Button
                  className={classes.programNameCell}
                  onClick={() => setIsOpen(true)}
                >
                  <span>Filters</span>
                  <FilterList fontSize="small" />
                </Button>
              )}

              {showSearchField && (
                <SearchField
                  searchValue={searchValue}
                  setSearchValue={setSearchValue}
                  handleSearch={handleSearch}
                />
              )}
            </p>
            {/* filters Drawer  */}
            <FilterDrawer
              open={isOpen}
              handleDrawerClose={() => setIsOpen(false)}
              sitesInfo={sitesInfo}
              listingId={listingId}
              selectedFilters={selectedFilters}
              setSelectedFilters={setSelectedFilters}
              searchText={searchText}
              filterConfig={filtersList}
              isFiltersModified={isFiltersModified}
              setIsFiltersModified={setIsFiltersModified}
              defaultSort={defaultSort}
              defaultSortDirection={defaultSortDirection}
              order={order}
              setOrder={setOrder}
              orderBy={orderBy}
              setOrderBy={setOrderBy}
            />
            {/* Programs list */}
            <ProgramList
              sitesInfo={sitesInfo}
              filters={selectedFilters}
              handleDelete={handleDelete}
              handleClearFilters={handleClearFilters}
              searchText={searchText}
              setSearchText={setSearchText}
              setSearchValue={setSearchValue}
              showSearchField={showSearchField}
              searchZipCode={searchZipCode}
              searchRadius={searchRadius}
              showZipCodeFinder={showZipCodeFinder}
              handleClick={handleClick}
              handleSearch={handleSearch}
              setMarkers={setMarkers}
              defaultSort={defaultSort}
              defaultSortDirection={defaultSortDirection}
              programLink={programLink}
              order={order}
              setOrder={setOrder}
              orderBy={orderBy}
              setOrderBy={setOrderBy}
            />
          </>
        )}
        {showZipCodeFinder === "yes" && isEmpty(searchZipCode) && (
          <center>
            {" "}
            <h3>{filtersMessage}</h3>{" "}
          </center>
        )}
      </Paper>
    </MapContext.Provider>
  );
}

export default windowSize(ProgramsList);

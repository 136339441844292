import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import GoogleMapReact from 'google-map-react';
import { isEmpty } from 'lodash';
import { MapContext } from '../ProgramsListing';
import { useStyles } from '../../utils/styles';
import { ConfigContext } from '../../containers/SiteConfig';
import { radiusOptions, getSignUpAction, useQuery } from '../../constants';
import Marker from './marker';
import MapFilters from './mapFilters';
import { getCoordinates } from "../../db/queries/programs";


const ZipCodeFinder = ({ searchValues, setSearchValues, handleSearch, markers = [], searchZipCode, defaultZipCode }) => {
	const history = useHistory();
	const query = useQuery();
	const id = query.get("id");
	const { focusedProgram } = React.useContext(MapContext);
	const [isOpen, setIsOpen] = useState(false);
	const [zoom, setZoomValue] = useState(8);
	const classes = useStyles();
	const { latitude = 41.850033, longitude = -87.6500523 } = searchZipCode ? getCoordinates(searchValues.zipCode) : getCoordinates(defaultZipCode)
	const { signUpAction } = React.useContext(ConfigContext);
	const defaultProps = {
		center: {
			lat: latitude,
			lng: longitude
		},
		zoom
	};

	const handleMarkerClick = (program) => {
		getSignUpAction(program, signUpAction, history, id)
	}


	const handleTooltipClose = () => {
		setIsOpen(false);
	};

	const handleTooltipOpen = () => {
		setIsOpen(true);
	};

	const getHighlightedMarker = () => {
		if (!focusedProgram) return null

		let lat = focusedProgram['locationLatitude'] || null
		let lng = focusedProgram['locationLongitude'] || null
		if ((!lat || !lng) && focusedProgram['locationZip']) {
			let zipCode = getCoordinates(focusedProgram['locationZip'])
			lat = zipCode['latitude'] || null
			lng = zipCode['longitude'] || null
		}
		return `${lat},${lng}`
	}


	const renderMarker = (markers) => {
		const markersListByCoordinates = {}
		const focusedMarker = getHighlightedMarker()
		markers.forEach(marker => {
			let lat = marker['locationLatitude'] || null
			let lng = marker['locationLongitude'] || null
			if ((!lat || !lng) && marker['locationZip']) {
				let zipCode = getCoordinates(marker['locationZip'])
				lat = zipCode['latitude'] || null
				lng = zipCode['longitude'] || null
			}

			markersListByCoordinates[`${lat},${lng}`] = isEmpty(markersListByCoordinates[`${lat},${lng}`]) ? [] : markersListByCoordinates[`${lat},${lng}`]
			markersListByCoordinates[`${lat},${lng}`].push(marker)

		})

		return (Object.keys(markersListByCoordinates).map(latlng => {
			const lat = latlng.split(',')[0];
			const lng = latlng.split(',')[1];
			const programNames = markersListByCoordinates[`${latlng}`].map(prog => prog['name']).join("\n")

			return (
				<Marker
					key={latlng}
					latlng={latlng}
					lat={lat}
					lng={lng}
					name={programNames}
					isMultiplePrograms={markersListByCoordinates[`${latlng}`].length > 1}
					isOpen={isOpen}
					handleTooltipClose={handleTooltipClose}
					handleTooltipOpen={handleTooltipOpen}
					programs={markersListByCoordinates[`${latlng}`]}
					handleClick={handleMarkerClick}
					highlightedMarker={focusedMarker}
					center={defaultProps.center}
				/>
			)
		}))
	}

	const handleMapChange = ({ zoom: updatedZoomVal }) => {
		if (updatedZoomVal !== zoom) {
			setZoomValue(updatedZoomVal)
		}
	}

	return (
		<div className={classes.zipCodeFinder}>
			<MapFilters
				searchValues={searchValues}
				setSearchValues={setSearchValues}
				radiusOptions={radiusOptions}
				handleBtnClick={handleSearch}
			/>

			<GoogleMapReact
				key="zip-code-finder"
				bootstrapURLKeys={{ key: 'AIzaSyC5gBeToFMrGQ0-Qn2X9r9G1cC6ND2RKG8' }}
				center={defaultProps.center}
				defaultZoom={defaultProps.zoom}
				onChange={handleMapChange}
				options={(maps) => ({
					scrollwheel: false
				})}
			>
				{renderMarker(markers)}
			</GoogleMapReact>
		</div>
	)
}

export default (ZipCodeFinder);
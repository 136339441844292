/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Tabs, Tab } from "@material-ui/core";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import { ArrowBackIos } from "@material-ui/icons";
import { isEmpty } from "lodash";

import { TabPanel } from "./TabPanel";
import { SchedulesWidget } from "./ScheduleWidget";
import Roster from "./Roster";
import { useStyles } from "../../utils";
import { getUrl, useQuery } from "../../constants";
import { ConfigContext } from "../../containers/SiteConfig";
import { Typography } from "@material-ui/core";

const TeamSchedule = () => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const query = useQuery();

  const { teamId } = React.useContext(ConfigContext);

  const [team, setTeam] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    if (!isEmpty(location?.state?.team)) {
      setTeam(location?.state?.team);
      setActiveIndex(parseInt(query.get("tabIndex") || 0));
    } else {
      history.push("/");
    }
  }, []);

  const handleTabChange = (event, newValue) => {
    setActiveIndex(newValue);
  };

  const handleBackButton = () => {
    history.push("/");
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <ArrowBackIos className={classes.backIcon} onClick={handleBackButton} />
        <div>
          <Typography
            component="p"
            variant="h5"
            className={classes.scheduleHeading}
          >
            {team?.teamName}
          </Typography>

          <Typography variant="body1">
            <b>Status: </b>
            {team?.teamStatus} <br />
            <b>Division: </b>
            {team?.division} <br />
            <a href={getUrl(team?.teamProfileURL)} target="blank">
              {" "}
              View Profile{" "}
            </a>
          </Typography>
        </div>
      </div>

      <Tabs
        value={activeIndex}
        onChange={handleTabChange}
        variant="scrollable"
        scrollButtons="auto"
        aria-label="scrollable auto tabs example"
        TabIndicatorProps={{ style: { background: "gray", color: "red" } }}
      >
        <Tab label="Schedule" />
        <Tab label="Roster" />
      </Tabs>
      <hr
        style={{
          padding: 0,
          margin: 0,
          color: "#C8C8C8",
        }}
      />
      <TabPanel value={activeIndex} index={0}>
        <SchedulesWidget team={team || location?.state?.team || teamId} />
      </TabPanel>
      <TabPanel value={activeIndex} index={1}>
        <Roster team={team || location?.state?.team || teamId} />
      </TabPanel>
    </>
  );
};
export default TeamSchedule;

import { Model } from '@nozbe/watermelondb';
import { field, date } from '@nozbe/watermelondb/decorators';

export default class Program extends Model {
  static table = 'programs';

  @field('siteId') siteId
  @field('siteName') siteName
  @field('programId') programId
  @field('sport') sport
  @field('code') code
  @field('type') type
  @field('name') name
  @field('division') division
  @field('location') location
  @field('locationAddress1') locationAddress1
  @field('locationAddress2') locationAddress2
  @field('locationCity') locationCity
  @field('locationDescription') locationDescription
  @field('locationId') locationId
  @field('locationState') locationState
  @field('locationZip') locationZip
  @field('locationUrlHtml') locationUrlHtml
  @field('scheduleDays') scheduleDays
  @field('scheduleTimes') scheduleTimes
  @field('startingTime') startingTime
  @field('endingTime') endingTime
  @date('startTime') startTime
  @field('gender') gender
  @field('experienceLevel') experienceLevel
  @field('leagueFormat') leagueFormat
  @field('teamIndividualFee') teamIndividualFee
  @field('earlyIndividualFee') earlyIndividualFee
  @field('lateTeamIndividualFee') lateTeamIndividualFee
  @field('teamFee') teamFee
  @field('lateTeamFee') lateTeamFee
  @field('usingVariableTeamFee') usingVariableTeamFee
  @field('lateFreeAgentFee') lateFreeAgentFee
  @field('freeAgentFee') freeAgentFee
  @field('chargeProcessingFee') chargeProcessingFee
  @field('processingFeeLabel') processingFeeLabel
  @field('individualProcessingFee') individualProcessingFee
  @field('teamProcessingFee') teamProcessingFee
  @field('percentageProcessingFee') percentageProcessingFee
  @date('earlyRegistrationTime') earlyRegistrationTime
  @date('regularRegistrationTime') regularRegistrationTime
  @field('earlyFreeAgentFee') earlyFreeAgentFee
  @field('earlyTeamFee') earlyTeamFee
  @date('lateRegistrationTime') lateRegistrationTime
  @field('sponsor') sponsor
  @date('publicRegistrationTime') publicRegistrationTime
  @date('endRegistrationTime') endRegistrationTime
  @field('season') season
  @field('programUrlHtml') programUrlHtml
  @field('registerUrlHtml') registerUrlHtml
  @field('registrationStatus') registrationStatus
  @field('state') state
  @field('mode') mode
  @field('programLogo150') programLogo150
  @field('description') description
  @field('scheduleUrlHtml') scheduleUrlHtml
  @field('standingsUrlHtml') standingsUrlHtml
  @field('additionalTeamFee') additionalTeamFee
  @field('additionalTeamFeeLabel') additionalTeamFeeLabel
  @field('hasPaymentPlans') hasPaymentPlans
  @field('minAgeLimit') minAgeLimit
  @field('maxAgeLimit') maxAgeLimit
  @field('ageLimitEffectiveDate') ageLimitEffectiveDate
  @field('masterProgramId') masterProgramId
  @field('masterProgramName') masterProgramName
  @field('isSessionBased') isSessionBased
  @field('isMaster') isMaster
  @field('type') type
  @field('masterProgramUrlHtml') masterProgramUrlHtml
  @field('masterRegisterUrlHtml') masterRegisterUrlHtml
  @field('isChild') isChild
  @field('maxFreeAgentsCount') maxFreeAgentsCount
  @field('maxPlayersCount') maxPlayersCount
  @field('maxTeamCount') maxTeamCount  
  @field('remainingFreeAgents') remainingFreeAgents
  @field('remainingPlayers') remainingPlayers
  @field('remainingTeamCount') remainingTeamCount  
}
import React, { useEffect, useState } from "react";
import { pickBy, isEmpty, map } from "lodash";
import { Q } from "@nozbe/watermelondb";
import { withDatabase } from "@nozbe/watermelondb/DatabaseProvider";
import withObservables from "@nozbe/with-observables";
import { sendMessage } from "../iframeConnect";
import { List } from "@material-ui/core";
import { ConfigContext } from "../containers/SiteConfig";

import ProgramListMeta from "./ProgramsListMeta";
import ProgramListItem from "./ProgramListItem";
import { useStyles, stableSort, getSorting  } from "../utils";
import { getProgramsQuery, filterPrograms } from "../db/queries/programs";
import { noDataMessage } from "../constants";
import Loader from "../containers/Loader";

const ProgramList = (props) => {
  const {
    programs: programsList,
    filters,
    handleDelete,
    searchText,
    setSearchText,
    handleClearFilters,
    programLink,
    order,
    orderBy,
  } = props;
  const { firstDayOfTheWeek } = React.useContext(ConfigContext);
  const { showZipCodeFinder, setMarkers, searchZipCode, searchRadius } = props;
  const classes = useStyles();
  const [programs, setPrograms] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (showZipCodeFinder === "yes") {
      const filteredPrograms = filterPrograms(
        programsList,
        searchZipCode,
        searchRadius
      );
      setPrograms(filteredPrograms);
      setIsLoading(false);
      setMarkers(filteredPrograms);
    } else {
      setPrograms(programsList);
      setIsLoading(false);
    }
    sendMessage("contentUpdate", undefined, true);
    sendMessage("scrollIframe", undefined, undefined, undefined);
  }, [
    programsList.length,
    searchZipCode,
    searchRadius,
    showZipCodeFinder,
    programsList,
    setMarkers,
  ]);

  return (
    <div style={{ top: "20px", position: "relative" }}>
      <ProgramListMeta
        programs={programs}
        searchText={searchText}
        filters={filters}
        handleDelete={handleDelete}
        setSearchText={setSearchText}
        handleClearFilters={handleClearFilters}
        programLink={programLink}
      />
      <div className={classes.programsList}>
        <Loader showLoading={isLoading}>
          {programs.length > 0 ? (
            <List>
              {stableSort(
                programs,
                getSorting(order, orderBy, firstDayOfTheWeek),
                getSorting("asc", "startTime")
              ).map((program) => {
                return (
                  <ProgramListItem program={program} key={program.programId} />
                );
              })}
            </List>
          ) : (
            <center>
              {" "}
              <h3>{noDataMessage}</h3>{" "}
            </center>
          )}
        </Loader>
      </div>
    </div>
  );
};

const enhance = withObservables(
  ["filters", "searchText", "searchZipCode", "searchRadius", "sitesInfo"],
  ({
    database,
    filters,
    searchText,
    searchZipCode,
    searchRadius,
    showZipCodeFinder,
    sitesInfo,
  }) => {
    if (
      showZipCodeFinder === "yes" &&
      isEmpty(searchText) &&
      isEmpty(searchZipCode) &&
      isEmpty(searchRadius)
    ) {
      return {
        programs: [],
      };
    }

    const selectedFilters = pickBy(filters, (value, key) => !isEmpty(value));
    const siteIds = map(sitesInfo, ({ siteId }) => parseInt(siteId));
    const query = getProgramsQuery(
      selectedFilters,
      siteIds,
      searchText,
      searchZipCode,
      searchRadius
    );
    

    const data = database.collections
      .get("programs")
      .query(...query)
      .observe();

    return {
      programs: data,
    };
  }
);

export default withDatabase(enhance(ProgramList));

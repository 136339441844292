import  {appSchema, tableSchema} from  '@nozbe/watermelondb';

const schema = appSchema({
  version: Date.now(),
  tables: [
    tableSchema({
      name: 'programs',
      columns: [
        {name: 'programId', type: 'number'},
        {name: 'siteId', type: 'number'},
        {name: 'siteName', type: 'string', isOptional: true},
        {name: 'programLogo150', type: 'string', isOptional: true},
        {name: 'description', type: 'string', isOptional: true},
        {name: 'sport', type: 'string'},
        {name: 'type', type: 'string', isOptional: true},
        {name: 'code', type: 'string', isOptional: true},
        {name: 'name', type: 'string', isOptional: true},
        {name: 'location', type: 'string', isOptional: true},
        {name: 'locationAddress1', type: 'string', isOptional: true},
        {name: 'locationAddress2', type: 'string', isOptional: true},
        {name: 'locationCity', type: 'string', isOptional: true},
        {name: 'locationDescription', type: 'string', isOptional: true},
        {name: 'locationId', type: 'number', isOptional: true},
        {name: 'locationState', type: 'string', isOptional: true},
        {name: 'locationZip', type: 'string', isOptional: true},
        {name: 'locationUrlHtml', type: 'string', isOptional: true},
        {name: 'division', type: 'string', isOptional: true},
        {name: 'scheduleDays', type: 'string', isOptional: true},
        {name: 'scheduleTimes', type: 'string', isOptional: true},
        {name: 'startingTime', type: 'string', isOptional: true},
        {name: 'endingTime', type: 'string', isOptional: true},
        {name: 'startTime', type: 'number', isOptional: true},
        {name: 'gender', type: 'string', isOptional: true},
        {name: 'experienceLevel', type: 'string', isOptional: true},
        {name: 'leagueFormat', type: 'string', isOptional: true},
        {name: 'earlyIndividualFee', type: 'number', isOptional: true},
        {name: 'lateTeamIndividualFee', type: 'number', isOptional: true},
        {name: 'teamIndividualFee', type: 'number', isOptional: true},
        {name: 'teamFee', type: 'number', isOptional: true},
        {name: 'lateTeamFee', type: 'number', isOptional: true},
        {name: 'lateFreeAgentFee', type: 'number', isOptional: true},
        {name: 'freeAgentFee', type: 'number', isOptional: true},
        {name: 'chargeProcessingFee', type: 'number', isOptional: true},
        {name: 'processingFeeLabel', type: 'string', isOptional: true},
        {name: 'individualProcessingFee', type: 'number', isOptional: true},
        {name: 'teamProcessingFee', type: 'number', isOptional: true},
        {name: 'percentageProcessingFee', type: 'number', isOptional: true},
        {name: 'earlyRegistrationTime', type: 'number', isOptional: true},
        {name: 'regularRegistrationTime', type: 'number', isOptional: true},
        {name: 'earlyFreeAgentFee', type: 'number', isOptional: true},
        {name: 'earlyTeamFee', type: 'number', isOptional: true},
        {name: 'lateRegistrationTime', type: 'number', isOptional: true},
        {name: 'usingVariableTeamFee', type: 'boolean', isOptional: true},
        {name: 'sponsor', type: 'string', isOptional: true},
        {name: 'publicRegistrationTime', type: 'number', isOptional: true},
        {name: 'endRegistrationTime', type: 'number', isOptional: true},
        {name: 'season', type: 'string', isOptional: true},
        {name: 'programUrlHtml', type: 'string', isOptional: true},
        {name: 'registerUrlHtml', type: 'string', isOptional: true},
        {name: 'standingsUrlHtml', type: 'string', isOptional: true},
        {name: 'scheduleUrlHtml', type: 'string', isOptional: true},
        {name: 'registrationStatus', type: 'string', isOptional: true},
        {name: 'state', type: 'string', isOptional: true},
        {name: 'mode', type: 'string', isOptional: true},
        {name: 'additionalTeamFee', type: 'number', isOptional: true},
        {name: 'additionalTeamFeeLabel', type: 'string', isOptional: true},
        {name: 'hasPaymentPlans', type: 'boolean', isOptional: true},
        {name: 'minAgeLimit', type: 'number', isOptional: true},
        {name: 'maxAgeLimit', type: 'number', isOptional: true},
        {name: 'ageLimitEffectiveDate', type: 'string', isOptional: true},
        {name: 'masterProgramId', type: 'number', isOptional: true},
        {name: 'masterProgramName', type: 'string', isOptional: true},
        {name: 'isSessionBased', type: 'boolean', isOptional: true},
        {name: 'masterProgramUrlHtml', type: 'string', isOptional: true},
        {name: 'masterRegisterUrlHtml', type: 'string', isOptional: true},
        {name: 'isMaster', type: 'boolean', isOptional: true},
        {name: 'isChild', type: 'boolean', isOptional: true},
        {name: 'maxFreeAgentsCount', type: 'number', isOptional: true},
        {name: 'maxPlayersCount', type: 'number', isOptional: true},
        {name: 'maxTeamCount', type: 'number', isOptional: true},        
        {name: 'remainingFreeAgents', type: 'number', isOptional: true},
        {name: 'remainingPlayers', type: 'number', isOptional: true},
        {name: 'remainingTeamCount', type: 'number', isOptional: true},        
      ]
    }),
    tableSchema({
      name: 'configs',
      columns: [
        {name: 'siteId', type: 'number'},
        {name: 'alerts', type: 'string'},
        {name: 'alternateText', type: 'string'},
        {name: 'bodyText', type: 'string'},
        {name: 'buttonBackground', type: 'string'},
        {name: 'buttonBackgroundHover', type: 'string'},
        {name: 'buttonText', type: 'string'},
        {name: 'buttonTextHover', type: 'string'},
        {name: 'contentBackground', type: 'string'},
        {name: 'headerBackground', type: 'string'},
        {name: 'headings', type: 'string'},
        {name: 'links', type: 'string'},
        {name: 'linksHover', type: 'string'},
        {name: 'logo', type: 'string'},
        {name: 'mainNavBackground', type: 'string'},
        {name: 'mainNavLinks', type: 'string'},
        {name: 'mainNavLinksHover', type: 'string'},
        {name: 'pageBackground', type: 'string'},
        {name: 'sidebarBackground', type: 'string'},
        {name: 'widgetBackground', type: 'string'},
      ]
    })
  ]
});

export default schema;
import { Model } from '@nozbe/watermelondb';
import { field } from '@nozbe/watermelondb/decorators';

export default class Config extends Model {
  static table = 'configs';
  @field('siteId') siteId
  @field('alerts') alerts
  @field('alternateText') alternateText
  @field('bodyText') bodyText
  @field('buttonBackground') buttonBackground
  @field('buttonBackgroundHover') buttonBackgroundHover
  @field('buttonText') buttonText
  @field('buttonTextHover') buttonTextHover
  @field('contentBackground') contentBackground
  @field('headerBackground') headerBackground
  @field('headings') headings
  @field('links') links
  @field('linksHover') linksHover
  @field('logo') logo
  @field('mainNavBackground') mainNavBackground
  @field('mainNavLinks') mainNavLinks
  @field('mainNavLinksHover') mainNavLinksHover
  @field('pageBackground') pageBackground
  @field('sidebarBackground') sidebarBackground
  @field('widgetBackground') widgetBackground
}
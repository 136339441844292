import React from 'react';
import * as moment from 'moment';
import { isEmpty } from 'lodash';
import { TableCell } from '@material-ui/core';
import { formatCurrency } from './formatPrograms';


const showPricingDetails = (early, regular, late, end) => {  
  const currentDateTime = moment().unix()
  const showEarlyBirdDiscount = (early && moment(early).unix() < currentDateTime) && (regular && currentDateTime < moment(regular).unix());
  const showRegularFee = late ? !showEarlyBirdDiscount && currentDateTime < moment(late).unix() : true;
  const showLateFee = !showEarlyBirdDiscount && !showRegularFee && (late && moment(late).unix() < currentDateTime && (end ? currentDateTime < moment(end).unix() : true));

  return {showEarlyBirdDiscount, showRegularFee, showLateFee}
}

/** 
 * Returns fee value based on early, regular and late fee booleans
 */

export const getFees = (early, regular, late, earlyFee, regularFee, lateFee, end) => {  
  const {showEarlyBirdDiscount, showRegularFee, showLateFee} = showPricingDetails(early, regular, late, end)
  let fees = null

  if (showEarlyBirdDiscount) {
    fees = earlyFee;
  } else if (showRegularFee || (isEmpty(early) && isEmpty(late) && !isEmpty(regularFee))) {
    fees = regularFee;
  } else if (showLateFee) {
    fees = lateFee;
  } 

  return fees;
}

const getTeamPlayerFeeDetails = (program, showEarlyBirdDiscount, showRegularFee, showLateFee, showProcessingFee, displayPriceLabels, showHasPaymentPlans) => {
  const { earlyRegistrationTime, regularRegistrationTime, lateRegistrationTime, endRegistrationTime } = program;
  const { earlyIndividualFee, teamIndividualFee, lateTeamIndividualFee, individualProcessingFee, hasPaymentPlans, processingFeeLabel } = program;
  const customFeeLabel = processingFeeLabel && processingFeeLabel.length > 0 ? processingFeeLabel :  'processing fee';

  let processingFee = null;
  let paymentPlanAvailableLabel = null;
  let feeAmount = formatCurrency(getFees(earlyRegistrationTime, regularRegistrationTime, lateRegistrationTime, earlyIndividualFee, teamIndividualFee, lateTeamIndividualFee, endRegistrationTime));
  
  if (showProcessingFee === 'yes' && individualProcessingFee) {
    processingFee = (<small>+ {formatCurrency(individualProcessingFee)} {customFeeLabel} per player</small>);
  }

  if (showHasPaymentPlans === 'yes' && hasPaymentPlans) {
    paymentPlanAvailableLabel = (<React.Fragment><br/><small>Payment Plans Available</small></React.Fragment>)
  }

  if (showEarlyBirdDiscount) {
    const timeLeft = regularRegistrationTime ? moment.duration(moment(regularRegistrationTime).unix() - moment().unix(), 'seconds').humanize(true) : null;
    return (
      <>
        {feeAmount}
        {earlyIndividualFee && processingFee} {paymentPlanAvailableLabel}
        {displayPriceLabels === 'yes' &&<small>
          <br />
          <span>Early bird pricing</span> <br />
          <i> {timeLeft.replace('in ', '')} left </i>
        </small>
        }
      </>
    );
  } else if (showRegularFee || (isEmpty(earlyRegistrationTime) && isEmpty(lateRegistrationTime) && !isEmpty(teamIndividualFee))) {
    const timeLeft = lateRegistrationTime ? moment.duration(moment(lateRegistrationTime).unix() - moment().unix(), 'seconds').humanize(true) : null;

    return (
      <>
        {feeAmount} {teamIndividualFee && processingFee} {paymentPlanAvailableLabel}
        {
          lateRegistrationTime && displayPriceLabels === 'yes' &&
          <small>
            <br />
            <span>Regular Pricing</span> <br />
            <i> {timeLeft.replace('in ', '')} left </i>
          </small>
        }
      </>
    );
  } else if (showLateFee) {
    return (
      <>
        {feeAmount} {lateTeamIndividualFee && processingFee} {paymentPlanAvailableLabel}
      </>
    );
  }
}

const getTeamFeeDetails = (program, showEarlyBirdDiscount, showRegularFee, showLateFee, showProcessingFee,displayPriceLabels, showAdditionalTeamFee, showHasPaymentPlans) => {
  const { 
    earlyRegistrationTime, regularRegistrationTime, lateRegistrationTime, endRegistrationTime,
    teamFee, lateTeamFee, earlyTeamFee, 
    teamProcessingFee = null, percentageProcessingFee = null, additionalTeamFee, 
    processingFeeLabel ,
    additionalTeamFeeLabel, 
    hasPaymentPlans
  } = program;

  const customFeeLabel = processingFeeLabel && processingFeeLabel.length > 0 ? processingFeeLabel :  'processing fee';

  let feeAmount = formatCurrency(getFees(earlyRegistrationTime, regularRegistrationTime, lateRegistrationTime, earlyTeamFee, teamFee, lateTeamFee, endRegistrationTime));
  let processingFee = null;
  let teamAdditionalFee = null;
  let paymentPlanAvailableLabel = null;

  if (showProcessingFee === 'yes' && (teamProcessingFee || percentageProcessingFee)) {
    if (percentageProcessingFee) {
      processingFee = (<small> +{percentageProcessingFee}%  {customFeeLabel} per team</small>)
    } else {
      processingFee = (<small> +{formatCurrency(teamProcessingFee)}  {customFeeLabel} per team</small>)
    }
  }

  if (showAdditionalTeamFee === 'yes' && additionalTeamFee) {
    teamAdditionalFee = (<React.Fragment><br/><small> +${additionalTeamFee} {additionalTeamFeeLabel}</small></React.Fragment>)
  }

  if (showHasPaymentPlans === 'yes' && hasPaymentPlans) {
    paymentPlanAvailableLabel = (<React.Fragment><br/><small>Payment Plans Available</small></React.Fragment>)
  }

  if (showEarlyBirdDiscount) {
    const timeLeft = regularRegistrationTime ? moment.duration(moment(regularRegistrationTime).unix() - moment().unix(), 'seconds').humanize(true) : null;
    return (earlyTeamFee && (
      <>
        {feeAmount} {earlyTeamFee && processingFee} {teamAdditionalFee} {paymentPlanAvailableLabel}
        {displayPriceLabels === 'yes' &&<small>
          <br />
          <span>Early bird pricing</span> <br />
          <i> {timeLeft.replace('in ', '')} left </i>
        </small>}
      </>
    ));
  } else if (showRegularFee || (isEmpty(earlyRegistrationTime) && isEmpty(lateRegistrationTime) && !isEmpty(teamFee))) {
    const timeLeft = lateRegistrationTime ? moment.duration(moment(lateRegistrationTime).unix() - moment().unix(), 'seconds').humanize(true) : null;

    return (teamFee && (
      <>
        {feeAmount} {teamFee && processingFee} {teamAdditionalFee}  {paymentPlanAvailableLabel}
        {
          lateRegistrationTime && displayPriceLabels === 'yes' &&
          <small>
            <br />
            <span>Regular Pricing</span> <br />
            <i> {timeLeft.replace('in ', '')} left </i>
          </small>
        }
      </>
    ));
  } else if (showLateFee) {
    return (lateTeamFee && (
      <>
        {feeAmount} {lateTeamFee && processingFee} {teamAdditionalFee}  {paymentPlanAvailableLabel}
      </>
    ));
  }
}

const getIndividualFeeDetails = (program, showEarlyBirdDiscount, showRegularFee, showLateFee, showProcessingFee, displayPriceLabels, showHasPaymentPlans) => {
  const { earlyRegistrationTime, regularRegistrationTime, lateRegistrationTime, endRegistrationTime } = program;
  const { earlyFreeAgentFee, lateFreeAgentFee, freeAgentFee, individualProcessingFee = null, percentageProcessingFee = null, hasPaymentPlans, processingFeeLabel } = program;

  let feeAmount = formatCurrency(getFees(earlyRegistrationTime, regularRegistrationTime, lateRegistrationTime, earlyFreeAgentFee, freeAgentFee, lateFreeAgentFee, endRegistrationTime));
  let processingFee = null;
  let paymentPlanAvailableLabel = null;
  const customFeeLabel = processingFeeLabel && processingFeeLabel.length > 0 ? processingFeeLabel :  'processing fee';

  if (showProcessingFee === 'yes' && (individualProcessingFee || percentageProcessingFee)) {
    if (percentageProcessingFee) {
      processingFee = (<small> +{percentageProcessingFee}%  {customFeeLabel} </small>)
    } else {
      processingFee = (<small> +{formatCurrency(individualProcessingFee)}  {customFeeLabel} </small>)
    }
  }

  if (showHasPaymentPlans === 'yes' && hasPaymentPlans) {
    paymentPlanAvailableLabel = (<React.Fragment><br/><small>Payment Plans Available</small></React.Fragment>)
  }

  if (showEarlyBirdDiscount) {
    const timeLeft = regularRegistrationTime ? moment.duration(moment(regularRegistrationTime).unix() - moment().unix(), 'seconds').humanize(true) : null;
    return (earlyFreeAgentFee && (
      <>
        {feeAmount} {earlyFreeAgentFee && processingFee} {paymentPlanAvailableLabel}
        {displayPriceLabels === 'yes' &&<small>
          <br />
          <span>Early bird pricing</span> <br />
          <i> {timeLeft.replace('in ', '')} left </i>
        </small>}
      </>
    ));
  } else if (showRegularFee || (isEmpty(earlyRegistrationTime) && isEmpty(lateRegistrationTime) && !isEmpty(freeAgentFee))) {
    const timeLeft = lateRegistrationTime ? moment.duration(moment(lateRegistrationTime).unix() - moment().unix(), 'seconds').humanize(true) : null;

    return (freeAgentFee && (
      <>
        {feeAmount} {freeAgentFee && processingFee} {paymentPlanAvailableLabel}
        {
          lateRegistrationTime &&  displayPriceLabels === 'yes' &&
          <small>
            <br />
            <span>Regular Pricing</span> <br />
            <i> {timeLeft.replace('in ', '')} left </i>
          </small>
        }
      </>
    ));
  } else if (showLateFee) {
    return (lateFreeAgentFee && (
      <>
        {feeAmount} {lateFreeAgentFee && processingFee} {paymentPlanAvailableLabel}
      </>
    ));
  }
}

/** 
 * Return the applicable fees that is to be displayed on program table (desktop view) or program list (mobile view)
 */
 export const getFeeDetails = (id, program, label, classes, isMobileView, showProcessingFee, displayPriceLabels, showAdditionalTeamFee, showHasPaymentPlans) => {
  const value = program[id];
  const { earlyRegistrationTime, regularRegistrationTime, lateRegistrationTime, endRegistrationTime, usingVariableTeamFee } = program;
  const {showEarlyBirdDiscount, showRegularFee, showLateFee} = showPricingDetails(earlyRegistrationTime, regularRegistrationTime, lateRegistrationTime, endRegistrationTime)

  let feeItem = null;

  if (id === 'teamFee' || (id === 'teamPrice' && !usingVariableTeamFee)) {
    feeItem = getTeamFeeDetails(
                program, 
                showEarlyBirdDiscount, 
                showRegularFee, 
                showLateFee, 
                showProcessingFee, 
                displayPriceLabels, 
                showAdditionalTeamFee, 
                showHasPaymentPlans
              )
  } else if (id === 'freeAgentFee') {
    feeItem = getIndividualFeeDetails(
                program, 
                showEarlyBirdDiscount, 
                showRegularFee, 
                showLateFee, 
                showProcessingFee, 
                displayPriceLabels, 
                showHasPaymentPlans
              )
  } else if (id === 'teamIndividualFee' || (id === 'teamPrice' && usingVariableTeamFee)) {
    feeItem = getTeamPlayerFeeDetails(
                program, 
                showEarlyBirdDiscount,
                showRegularFee, 
                showLateFee, 
                showProcessingFee, 
                displayPriceLabels, 
                showHasPaymentPlans
              )
  }

  if (isMobileView) {
    return (<p key={id} className={classes.listItemData}>
      <span>
        <b>{label}:</b>
      </span>
      &nbsp;
      <span>
        {feeItem || 'N/A'}
      </span>
    </p >);
  } else {
    return (
      <TableCell key={id} id={value}>
        <span>
          {feeItem || 'N/A'}
        </span>
      </TableCell>
    );
  }
}
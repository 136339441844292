import { isEmpty, toUpper, find, compact } from "lodash";
import { Q } from "@nozbe/watermelondb";
import * as moment from "moment";
import { days, radiusOptions, stateValuesKeys } from "../../constants";
import * as zipcodes from "zipcodes";

const registrationTypes = {
  Individual: "freeAgentFee",
  "Full Team": "teamFee",
};

export const getProgramsQuery = (
  selectedFilters,
  siteIds,
  searchText = null,
  searchZipCode = null,
  searchRadius = null
) => {
  //filter out any instances where lateRegistrationTime is after endRegistrationTime
  const query = [
    Q.and(
      Q.where("siteId", Q.oneOf(siteIds)),
      Q.or(
        Q.or(
          Q.and(
            Q.where("endRegistrationTime", Q.notEq(null)),
            Q.and(
              (Q.where("lateRegistrationTime", Q.notEq(null)),
              Q.where(
                "endRegistrationTime",
                Q.gt(Q.column("lateRegistrationTime"))
              ))
            )
          ),

          Q.and(
            Q.where("endRegistrationTime", Q.notEq(null)),
            Q.or(
              Q.where("lateRegistrationTime", Q.eq(null)),
              Q.and(
                (Q.where("lateRegistrationTime", Q.notEq(null)),
                Q.where(
                  "endRegistrationTime",
                  Q.gt(Q.column("lateRegistrationTime"))
                ))
              )
            )
          )
        ),

        Q.or(
          Q.where("endRegistrationTime", Q.eq(null)),
          Q.and(
            (Q.where("lateRegistrationTime", Q.notEq(null)),
            Q.where(
              "endRegistrationTime",
              Q.gt(Q.column("lateRegistrationTime"))
            ))
          )
        )
      )
    ),
    Q.where("isMaster", Q.notEq(true)),
    Q.or(
      Q.and(
        Q.where("masterProgramName", Q.notEq("")),
        Q.where("isChild", Q.eq(true))
      ),
      Q.and(
        Q.where("masterProgramName", Q.eq("")),
        Q.where("isChild", Q.eq(false))
      )
    )
  ];

  for (const filterKey in selectedFilters) {
    let queryFilters = selectedFilters[filterKey];

    if (filterKey === "scheduleDays") {
      // eslint-disable-next-line no-loop-func
      const daysQuery = selectedFilters[filterKey].map((day) => {
        let _day = day;
        let dayObj = find(days, (d) => d.key === day.trim());
        if (!dayObj) {
          dayObj = find(days, (d) => d.filterLabel === day);
        }
        _day = dayObj && dayObj.key;
        return Q.where(
          "scheduleDays",
          Q.like(`%${Q.sanitizeLikeString(_day)}%`)
        );
      });

      query.push(Q.or(...daysQuery));
    } else if (filterKey === "registrationType") {
      query.push(
        Q.where(`${registrationTypes[queryFilters[0]]}`, Q.notEq(null))
      );
    } else if (filterKey === "state") {
      const stateQuery = [
        Q.where(filterKey, Q.oneOf(queryFilters.map((f) => toUpper(f)))),
      ];
      if (
        selectedFilters[filterKey].includes(stateValuesKeys.OPEN_REGISTRATIONS)
      ) {
        const currentDate = moment().format("x");
        stateQuery.push(
          Q.and(
            Q.where("publicRegistrationTime", Q.lte(currentDate)),
            Q.where("endRegistrationTime", Q.gte(currentDate))
          )
        );
        stateQuery.push(
          Q.and(
            Q.where("endRegistrationTime", null),
            Q.where("publicRegistrationTime", null)
          )
        );
      }
      if (
        selectedFilters[filterKey].includes(
          stateValuesKeys.OPEN_REGISTRATIONS_AND_UPCOMING
        )
      ) {
        const currentDate = moment().format("x");
        stateQuery.push(
          Q.and(
            Q.or(
              Q.where("endRegistrationTime", null),
              Q.where("endRegistrationTime", Q.gt(currentDate))
            )
          )
        );
      }

      query.push(Q.or(...stateQuery));
    } else if (filterKey === "subProgram") {
      query.push(Q.where(`name`, Q.oneOf(selectedFilters[filterKey])));
    } else if (filterKey === "age") {
      const selectedAge = +selectedFilters[filterKey][0];
      query.push(
        Q.or(
          Q.and(
            Q.where("minAgeLimit", Q.notEq(null)),
            Q.where("maxAgeLimit", null),
            Q.where("minAgeLimit", Q.lte(selectedAge))
          ),
          Q.and(
            Q.where("maxAgeLimit", Q.notEq(null)),
            Q.where("minAgeLimit", null),
            Q.where("maxAgeLimit", Q.gte(selectedAge))
          ),
          Q.and(
            Q.where("minAgeLimit", Q.notEq(null)),
            Q.where("maxAgeLimit", Q.notEq(null)),
            Q.where("minAgeLimit", Q.lte(selectedAge)),
            Q.where("maxAgeLimit", Q.gte(selectedAge))
          ),
          Q.and(
            Q.where("minAgeLimit", null),
            Q.where("maxAgeLimit", null)
          ),
        )
      );
    } else {
      query.push(Q.where(filterKey, Q.oneOf(queryFilters)));
    }
  }

  if (!isEmpty(searchText)) {
    let searchQuery = [];
    searchText.forEach((term) =>
      searchQuery.push(
        Q.or(
          Q.where("name", Q.like(`%${Q.sanitizeLikeString(term)}%`)),
          Q.where(
            "masterProgramName",
            Q.like(`%${Q.sanitizeLikeString(term)}%`)
          ),
          Q.where("sponsor", Q.like(`%${Q.sanitizeLikeString(term)}%`)),
          Q.where("code", Q.like(`%${Q.sanitizeLikeString(term)}%`)),
          Q.where("location", Q.like(`%${Q.sanitizeLikeString(term)}%`)),
          Q.where("gender", Q.like(`%${Q.sanitizeLikeString(term)}%`)),
          Q.where("experienceLevel", Q.like(`%${Q.sanitizeLikeString(term)}%`)),
          Q.where("season", Q.like(`%${Q.sanitizeLikeString(term)}%`))
        )
      )
    );
    query.push(Q.and(...searchQuery));
  }

  return query;
};

export const getCoordinates = (zipCode) => zipcodes.lookup(zipCode) || {};

export const filterPrograms = (programs, searchZipCode, searchRadius) => {
  if (isEmpty(searchZipCode)) {
    return programs;
  }
  const { latitude, longitude } = getCoordinates(searchZipCode);
  const programList = programs.map((program) => {
    const {
      locationLatitude = null,
      locationLongitude = null,
      locationZip = null,
    } = program;
    let programLat = locationLatitude,
      programLng = locationLongitude;

    if ((!locationLatitude || !locationLongitude) && locationZip) {
      const { latitude: lat, longitude: lng } = getCoordinates(locationZip);
      programLat = lat;
      programLng = lng;
    }

    if (
      getDistanceInMiles(latitude, longitude, programLat, programLng) <=
      (searchRadius ? searchRadius.id : radiusOptions[0])
    )
      return program;
  });

  return compact(programList);
};

const deg2rad = (degrees) => degrees * (Math.PI / 180);

export const getDistanceInMiles = (lat1, lon1, lat2, lon2) => {
  const R = 6371; // Radius of the earth in km
  const dLat = deg2rad(lat2 - lat1); // deg2rad below
  const dLon = deg2rad(lon2 - lon1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c; // Distance in km
  const dm = d / 1.6; //  Distance in miles

  return dm;
};

import React, { useState } from "react";
import { Snackbar } from "@material-ui/core";
import { useStyles } from "../utils";

let openSnackbarFn;


// Global the component to display snackbar messages throughout the widget
export const Notifier = () => {
  const classes = useStyles();
  const [state, setState] = useState({
    open: false,
    message: null,
    severity: "notifier-success",
  });

  openSnackbarFn = (message, severity) => {
    setState({ open: true, message, severity });
  };

  const closeSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setState({ open: false, message: null });
  };

  return (
    <Snackbar
      ContentProps={{
        className: classes[state.severity],
      }}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      key="top, center"
      autoHideDuration={43000}
      open={state.open}
      onClose={closeSnackbar}
      message={state.message}
    />
  );
};

export function openSnackbar(message, severity = "success") {
  openSnackbarFn(message, `notifier-${severity}`);
}

export default Notifier;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback } from "react";
import { isEmpty, remove, pickBy, cloneDeep, map } from "lodash";
import { withDatabase } from "@nozbe/watermelondb/DatabaseProvider";
import { ConfigContext } from "../containers/SiteConfig";
import withObservables from "@nozbe/with-observables";

import { SearchButtons, SearchField } from "./Search";
import FilterItem from "./FilterItem";

import { getProgramsQuery } from "../db/queries/programs";
import {
  getFilterOptions,
  updateFilters,
} from "../utils";
import { defaultFilters } from "../constants";
let selectedId = "";

/**
 * Filters panel component
 * Displays all drop downs for configured filters
 */

const LeagueFilters = (props) => {
  const {
    database,
    programs,
    filterConfig,
    selectedFilters,
    setSelectedFilters,
    isFiltersModified,
    setIsFiltersModified,
  } = props;
  const { sitesInfo } = props;
 
  const {
    searchText,
    handleSearch,
    handleClick,
    showSearchField,
    searchValue,
    setSearchValue,
  } = props;
  const { programStateLabels, firstDayOfTheWeek } =
    React.useContext(ConfigContext);
  const [filters, setFilters] = useState(cloneDeep(defaultFilters));

  useEffect(() => {
    //Create filter options for each filters
    const loadFilters = async () => {
      let filterObj = await getFilterOptions(
        filterConfig,
        programs,
        programStateLabels,
        sitesInfo,
        firstDayOfTheWeek,
        selectedFilters
      );

      const selected = pickBy(selectedFilters, (value, key) => !isEmpty(value));
      const siteIds = map(sitesInfo, ({ siteId }) => parseInt(siteId));
      const query = await getProgramsQuery(selected, siteIds, searchText);

      const filteredPrograms = await database.collections
        .get("programs")
        .query(...query)
        .fetch();

      let updatedFilterList = [];
      if (!isEmpty(filteredPrograms)) {
        const updatedFilters = getFilterOptions(
          filterConfig,
          filteredPrograms,
          programStateLabels,
          sitesInfo,
          firstDayOfTheWeek,
          selectedFilters
        );
        updateFilters(filterObj, updatedFilters);
      }

      if (isFiltersModified.isModified) {
        if (!isEmpty(isFiltersModified.id)) modifyFilters(isFiltersModified.id);
          setIsFiltersModified({ isModified: false, id: "" });
      }
      setFilters({ ...filterObj, ...updatedFilterList });
    };

    loadFilters();
  }, [programs]);

  const modifyFilters = useCallback(async (id) => {
    const selected = pickBy(selectedFilters, (value, key) => !isEmpty(value));
    const siteIds = map(sitesInfo, ({ siteId }) => parseInt(siteId));
    const query = await getProgramsQuery(selected, siteIds, searchText);
    
    const filteredPrograms = await database.collections
      .get("programs")
      .query(...query)
      .fetch();
    let updatedFilterList = [];

    if (!isEmpty(filteredPrograms)) {
      const updatedFilters = getFilterOptions(
        filterConfig,
        filteredPrograms,
        programStateLabels,
        sitesInfo,
        firstDayOfTheWeek
      );
      updatedFilterList = updateFilters(filters, updatedFilters);
      setFilters({ ...filters, ...updatedFilterList });
    }
  });

  const setFilterValue = (value) => {
    setFilters({ ...filters, ...value });
  };

  const showSelectedValues = (id, value, _value, filterArr) => {
    let _selectedValues = filterArr || [];
    return {
      included: _selectedValues && _selectedValues.includes(value || _value),
      updatedSelection: _selectedValues,
    };
  };

  useEffect(() => {
    modifyFilters(selectedId);
  }, [selectedFilters]);

  const handleChange = async (id, value, isMultiSelect) => {
    selectedId = id;
    const showSelectionStatus = showSelectedValues(
      id,
      value,
      value,
      selectedFilters && selectedFilters[id]
    );

    if (showSelectionStatus.included) {
      remove(showSelectionStatus.updatedSelection, (val) => val === value);
    } else {
      if (isMultiSelect) {
        showSelectionStatus.updatedSelection.push(value);
      } else {
        showSelectionStatus.updatedSelection = [value];
      }
    }
    await setSelectedFilters({
      ...selectedFilters,
      [id]: showSelectionStatus.updatedSelection,
    });
  };

  // eslint-disable-next-line array-callback-return
  return (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <div style={{ width: "100%" }}>
        {showSearchField && (
          <SearchField
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            handleSearch={handleSearch}
          />
        )}
        {!showSearchField && (
          <>
            {filterConfig.map(({ label, id, visibleOptions }) => {
              if (filters[id] && !isEmpty(filters[id].options)) {
                return (
                  <FilterItem
                    key={id}
                    selectedValues={selectedFilters[id]}
                    filter={filters[id]}
                    label={label}
                    id={id}
                    handleChange={handleChange}
                    setFilterValue={setFilterValue}
                    visibleOptions={visibleOptions}
                  />
                );
              }
              return null;
            })}
          </>
        )}
      </div>

      <div style={{ display: "flex" }}>
        <SearchButtons
          showSearchField={showSearchField}
          handleClick={handleClick}
        />
      </div>
    </div>
  );
};

/** Observable for monitoring changes in the following 
 * "program", "isFiltersModified, sitesInfo", "filters", "searchText"
 * If changes are observed then programs and re-fetched from DB based on changes.  
 * */
const enhance = withObservables(
  ["program", "isFiltersModified, sitesInfo", "filters", "searchText",],
  ({
    database,
    filters,
    searchText,
    searchZipCode,
    searchRadius,
    showZipCodeFinder,
    sitesInfo,
  }) => {
    if (
      showZipCodeFinder === "yes" &&
      isEmpty(searchText) &&
      isEmpty(searchZipCode) &&
      isEmpty(searchRadius)
    ) {
      return {
        programs: [],
      };
    }

    const selectedFilters = pickBy(filters, (value, key) => !isEmpty(value));
    const siteIds = map(sitesInfo, ({ siteId }) => parseInt(siteId));
    const query = getProgramsQuery(
      selectedFilters,
      siteIds,
      searchText,
      searchZipCode,
      searchRadius
    );
    
    return {
      programs: database.collections
        .get("programs")
        .query(...query)
        .observe(),
    };
  }
);

export default withDatabase(enhance(LeagueFilters));


import React from "react";
import { DOTS, usePagination } from "../hooks";
import { useStyles } from "../../../utils";
import { Typography } from "@material-ui/core";

import clsx from "clsx";

const Pagination = (props) => {
  const {
    onPageChange,
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize = 5,
    firstEntry,
    lastEntry,
    className,
  } = props;

  const classes = useStyles();

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  let lastPage = paginationRange[paginationRange.length - 1];

  return (
    <div className={clsx(classes.row)}>
      <Typography className={classes.paginationText}>
        showing {firstEntry} to {lastEntry} of {totalCount} entries
      </Typography>
      <ul
        className={clsx({
          [className]: className,
        })}
      >
        <li
          className={clsx(classes.paginationItem, {
            disabled: currentPage === 1,
          })}
          onClick={currentPage === 1 ? undefined : onPrevious}
        >
          Prev
        </li>
        {paginationRange.map((pageNumber) => {
          if (pageNumber === DOTS) {
            return (
              <li
                className={clsx(classes.paginationItem, classes.dots)}
                key="pagination-dots"
              >
                &#8230;
              </li>
            );
          }

          return (
            <li
              key={`pagination-${pageNumber}`}
              className={clsx(classes.paginationItem, {
                selected: pageNumber === currentPage,
              })}
              onClick={() => onPageChange(pageNumber)}
            >
              {pageNumber}
            </li>
          );
        })}
        <li
          className={clsx(classes.paginationItem, {
            disabled: currentPage === lastPage,
          })}
          onClick={currentPage === lastPage ? undefined : onNext}
        >
          Next
        </li>
      </ul>
    </div>
  );
};

export default Pagination;

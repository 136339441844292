import {
  compact,
  uniq,
  uniqBy,
  map,
  isEmpty,
  find,
  cloneDeep,
  isNil,
  concat,
} from "lodash";
import {
  days,
  defaultSelectedFilters,
  defaultFilters,
  daysSorterFromMonday,
  daysSorterFromSunday,
  AGE,
  WEEKDAYS,
  hasOpenReg,
  hasOpenAndUpcoming,
  stateValuesKeys,
  stateDefaultValues,
  stateValueToKey,
} from "../constants";
import * as moment from "moment";

export const getMasterSubProgramValue = (programs) => {
  const subPrograms = [];
  for (const id in programs) {
    const masterId = programs[id].masterProgramId;
    const name = programs[id].name;
    if (masterId > 0) {
      subPrograms.push({ key: name, label: name, value: name, isDisabled: false });
    }
  }
  const _subPrograms =
    subPrograms &&
    subPrograms.length > 0 &&
    subPrograms.sort((a, b) =>
      a.value.toLowerCase().localeCompare(b.value.toLowerCase())
    );
  return uniqBy(_subPrograms, "label");
};


/** 
 * Method to create the filter options based on programs fetched
 */
export const getFilterOptions = (
  filterConfig,
  programs,
  programStateLabels,
  sitesInfo,
  firstDayOfTheWeek
) => {
  let program = [];
  const getFilterValues = (filterKey) => {
    if (filterKey === "freeAgentFee" || filterKey === "teamFee") {
      if (compact(map(programs, filterKey))) {
        return true;
      }
    }
    if (filterKey === "state") {
      programs.forEach((p) => {
        if (
          (moment().isSameOrBefore(p?.endRegistrationTime) &&
            moment().isSameOrAfter(p?.publicRegistrationTime)) ||
          (isNil(p.publicRegistrationTime) && isNil(p.endRegistrationTime))
        ) {
          !hasOpenReg(program) &&
            program.push(stateValuesKeys.OPEN_REGISTRATIONS);
        }
        if (
          moment().isBefore(p?.endRegistrationTime) ||
          isNil(p.endRegistrationTime)
        ) {
          !hasOpenAndUpcoming(program) &&
            program.push(stateValuesKeys.OPEN_REGISTRATIONS_AND_UPCOMING);
        }

        if([stateValuesKeys.LIVE, stateValuesKeys.UPCOMING].includes(p.state)){
          program.push(p.state)
        }
        
      });
      return concat(compact(uniq(map(programs, filterKey))).sort(), program);
    }
    return compact(uniq(map(programs, filterKey))).sort();
  };

  const filterObj = cloneDeep(defaultFilters);
  for (const { id, hasRelevancy = true } of filterConfig) {
    if (id !== "neighborhood") filterObj[id].hasRelevancy = hasRelevancy;
    switch (id) {
      case "scheduleDays":
        const daysSorter =
          firstDayOfTheWeek === WEEKDAYS.MONDAY
            ? daysSorterFromMonday
            : daysSorterFromSunday;
        let availableDays = getFilterValues(id);
        availableDays = compact(uniq(availableDays.join(",").split(",")));
        availableDays.sort((a, b) => daysSorter[a] - daysSorter[b]);
        if (!isEmpty(availableDays)) {
          filterObj[id].options = availableDays.map((day) => {
            const dayObj = find(days, (d) => d.key === day.trim());
            return {
              key: dayObj.filterLabel,
              label: dayObj.filterLabel,
              value: dayObj.filterLabel,
              isDisabled: false,
            };
          });
        }
        break;
      case "registrationType":
        let type1 = getFilterValues("teamFee");
        let type2 = getFilterValues("freeAgentFee");

        filterObj[id].options = [
          type1 && {
            key: "Full Team",
            label: "Full Team",
            value: "Full Team",
            isDisabled: false,
          },
          type2 && {
            key: "Individual",
            label: "Individual",
            value: "Individual",
            isDisabled: false,
          },
        ];
        break;
      case "state":
        let state = uniq(getFilterValues(id));
        if (!isEmpty(state)) {
          filterObj[id].options = map(state, (value) => {
            return {
              key: stateDefaultValues[value],
              label: programStateLabels[value] || stateDefaultValues[value],
              value,
              isDisabled: false,
            };
          });
        }
        break;
      case "age":
        let age = getFilterValues(id);
        filterObj[id].options = map(AGE, (value, key) => ({
          key: value,
          label: value,
          value,
          isDisabled: age.includes(value),
        }));
        break;
      case "siteName":
        let sites = getFilterValues("siteId");
        if(!isEmpty(sites)) {
          const optionsArr = [];
          sites.forEach(site => {
            const siteInfo = sitesInfo.find(s => parseInt(s.siteId) === site)
            if(siteInfo) {
              optionsArr.push(siteInfo);
            }
          })

          filterObj[id].options = map(optionsArr, ({ siteName }) => ({
            key: siteName,
            label: siteName,
            value: siteName,
            isDisabled: false,
          }));
        }
        break;
      case "subProgram":
        filterObj[id].options = getMasterSubProgramValue(programs);
        break;
      default:
        if (filterObj[id]) {
          filterObj[id].options = getFilterValues(id).map((value) => ({
            key: value,
            label: value,
            value,
            isDisabled: false,
          }));
        }
    }
  }

  return filterObj;
};

/** 
 * Method to return the  filter options selected from the admin panel 
 */
export const getPreselectedFilters = (filtersConfig) => {
  const preSelection = cloneDeep(defaultSelectedFilters);
  for (const filter of filtersConfig) {
    if (!isEmpty(filter.selectedOptions)) {
      if (filter.id === 'state') {
        preSelection[filter.id] = filter.selectedOptions?.map(option => stateValueToKey[option])
      } else {
        preSelection[filter.id] = filter.selectedOptions;
      }
    }
  }
  return preSelection;
};

/** 
 * Method to return a list of all selected options in filters
 * These options are extracted from the query params of parent URL (if widget is embedded) 
 */
export const getSelectedFilters = (parentUrl) => {
  let filterList = cloneDeep(defaultSelectedFilters);
  let url = new URL(parentUrl);
  let query = new URLSearchParams(url.search);

  Object.keys(filterList).forEach((key) => {
    const paramValue = uniq(query.getAll(`la-${key}`));
    if (paramValue.length) {
      filterList[key] = paramValue;
    }
  });
  return filterList;
};


/** 
 * Method to return a list of filters that are 
 */
export const getFilters = (filtersConfig) => {
  let filtersList = filtersConfig.filter(
    (filter) => filter.value && filter.visibility
  );
  return filtersList;
};

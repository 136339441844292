import React, { useEffect, useState, useCallback } from "react";
import { find, isEmpty, includes, cloneDeep, lowerCase, isNil } from "lodash";
import moment from "moment-timezone";

import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Grid,
  Link,
} from "@material-ui/core";
import Pagination from "./pagination/Pagination";
import { sendMessage } from "../../iframeConnect";

import Loader from "../../containers/Loader";
import { ConfigContext } from "../../containers/SiteConfig";
import CalendarEvents from "./Calendar/Calendar";
import { SchedulesFilters } from "./ScheduleFilters";
import {
  api,
  formatProgramSchedule,
  scheduleColumns,
  useStyles,
  renderMap,
} from "../../utils";

export const SchedulesWidget = ({ team }) => {
  const classes = useStyles();
  const { sitesInfo, programId, widgetSiteId, teamId, timezone } =
    React.useContext(ConfigContext);
  const [scheduleList, setScheduleList] = useState([]);
  const [filteredSchedule, setFilteredSchedule] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState(
    team ? { team: team.teamName } : {}
  );
  const [searchValue, setSearchValue] = useState(null);

  const [showCalendar, setShowCalendar] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  let pageSize = 5;
  const [currentPage, setCurrentPage] = useState(1);

  const getDetails = useCallback(
    async (program, site, apiKey) => {
      try {
        const apiProgramSchedule = await api.getScheduleDetail(
          program,
          site,
          apiKey
        );
        const games = formatProgramSchedule(
          apiProgramSchedule,
          team || teamId
        ).filter(
          (game) =>
            !(
              game.scheduleItemType === "STRUCTURED_GAME" &&
              game.state === "CANCELED"
            )
        );
        await setScheduleList(games);
        setIsLoading(false);
        sendMessage("contentUpdate", undefined, false);
        sendMessage("scrollIframe", undefined, undefined, undefined);
      } catch (error) {
        console.log(error);
      }
    },
    [team, teamId]
  );
  const [firstEntry, setFirstEntry] = useState(1);
  const [lastEntry, setLastEntry] = useState(pageSize);
  const [dates, setDates] = useState([]);
  
  const handleChangePage = useCallback((newPage) => {
    setFirstEntry((newPage - 1) * pageSize + 1);
    setLastEntry(((newPage - 1) * pageSize) + pageSize);
    setCurrentPage(newPage);
  }, [pageSize]);

  // triggered on first load when filteredSchedule is set. 
  // Groups the games by Dates and sets the default current page based on the current date 
  useEffect(() => {
    const scheduleDates = [];
    let currentDateIndex = 0;
    filteredSchedule.forEach((game) => {
      const gameDate = moment.utc(game.startTimeUNIX).tz("America/New_York").format("dddd, MMMM DD YYYY");
      if (
        !includes(
          scheduleDates,
          gameDate
        )
      ) {
        scheduleDates.push(gameDate);
        // set the current page to the page containing the games for today's date or closed future date
        if(moment(gameDate).isSameOrAfter(moment.utc()) && currentDateIndex === 0) {
          currentDateIndex = scheduleDates.length; 
        }
      }
    });

    // if no future date are present then show the last page
    if(moment(scheduleDates[scheduleDates.length-1]).isBefore(moment.utc())) {
      currentDateIndex = scheduleDates.length; 
    };
    setDates(scheduleDates);
    const updatedCurrentPage = Math.ceil(currentDateIndex/pageSize) 
    handleChangePage(updatedCurrentPage)
  }, [filteredSchedule, pageSize, handleChangePage])

  useEffect(() => {
    const getScheduleDetail = async () => {
      try {
        setIsLoading(true);
        const { siteId, apiKey } = find(
          sitesInfo,
          (site) => site.siteId === widgetSiteId
        );

        const { data: promisesApi } = await api.getProgramDetail(
          apiKey,
          programId,
          siteId
        );
        if (
          promisesApi.visibility === "Public" &&
          promisesApi.type !== "CLASS" &&
          (promisesApi.state === "LIVE" || promisesApi.state === "UPCOMING")
        ) {
          getDetails(programId, siteId, apiKey);
        } else {
          setScheduleList(null);
          setIsLoading(false);
        }
      } catch (err) {
        console.log(err);
        setIsLoading(false);
      }
    };
    getScheduleDetail();
  }, [getDetails, programId, sitesInfo, team, widgetSiteId]);

  useEffect(() => {
    if (isNil(scheduleList)) return;
    if (
      isEmpty(selectedFilters) &&
      isNil(searchValue) &&
      !isEmpty(scheduleList)
    ) {
      setFilteredSchedule(cloneDeep(scheduleList));
    } else {
      const searchText = searchValue ? lowerCase(searchValue) : null;
      const schedules = cloneDeep(scheduleList).filter(
        (game) =>
          (selectedFilters.team &&
            (game.home === selectedFilters.team ||
              game.away === selectedFilters.team)) ||
          (selectedFilters.location &&
            game.location === selectedFilters.location) ||
          (searchText &&
            (lowerCase(game.events).includes(searchText) ||
              lowerCase(game.location).includes(searchText) ||
              lowerCase(game.home).includes(searchText) ||
              lowerCase(game.away).includes(searchText)))
      );
      setFilteredSchedule(schedules);
    }
  }, [selectedFilters, scheduleList, searchValue]);

  useEffect(() => {
    sendMessage("contentUpdate", undefined, false);
    sendMessage("scrollIframe", undefined, undefined, undefined);
  }, [showCalendar, filteredSchedule, currentPage]);

  const handleSearch = () => {
    const searchText = lowerCase(searchValue);
    if (isEmpty(searchValue) && !isEmpty(scheduleList)) {
      setFilteredSchedule(cloneDeep(scheduleList));
    } else {
      const schedules = cloneDeep(scheduleList).filter(
        (game) =>
          lowerCase(game.events).includes(searchText) ||
          lowerCase(game.location).includes(searchText) ||
          lowerCase(game.home).includes(searchText) ||
          lowerCase(game.away).includes(searchText)
      );

      setFilteredSchedule(schedules);
    }
  };

  const handleCancelSearch = () => {
    setFilteredSchedule(cloneDeep(scheduleList));
    setSearchValue(null);
  };

  const renderScheduleDetails = () => {
    let scheduleTable = [];    
    const firstPageIndex = (currentPage - 1) * pageSize;
    const lastPageIndex = firstPageIndex + pageSize;

    dates.slice(firstPageIndex, lastPageIndex).forEach((date) => {
      scheduleTable.push(
        <Grid container key={date}>
          <Grid item xs={12}>
            <Typography
              component="h5"
              variant="h5"
              className={classes.scheduleDate}
            >
              {date}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Table aria-label="simple table">
              <TableHead className={classes.scheduleTable}>
                <TableRow key="header-row">
                  {scheduleColumns.map(({ key, title }) => (
                    <TableCell
                      style={{ width: !isEmpty(title) ? "15%" : "8%" }}
                      key={`${key}-header`}
                    >
                      {title}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredSchedule
                  .filter(
                    (game) =>
                      moment.utc(game.startTimeUNIX).tz("America/New_York")
                        .format("dddd, MMMM DD YYYY") === date
                  )
                  .map((game) => renderScheduleList(game))}
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      );
    });
    return (
      <>
        {isEmpty(dates) ? (
          <h3 className={classes.displayFlex}>No schedules for this Team.</h3>
        ) : (
          <>
            {scheduleTable}
            <Typography
              component="h6"
              variant="h6"
              className={classes.scheduleDate}
            >
              All times are displayed in local time. This site’s events are in {moment().tz(timezone).format("z")}
            </Typography>
            <Pagination
              className={classes.paginationBar}
              currentPage={currentPage}
              totalCount={dates.length}
              pageSize={pageSize}
              onPageChange={(page) => handleChangePage(page)}
              firstEntry={firstEntry}
              lastEntry={lastEntry}
            />
          </>
        )}
      </>
    );
  };

  const renderScheduleList = (game) => {
    const getCellContent = (column) => {
      if (["Start Time", "End Time"].includes(column.title)) {
        const isStartTime = column.title === "Start Time";
        const value = isStartTime ? game.startTimeUNIX : game.endTimeUnix;
        return <>{value ? moment.utc(value).tz("America/New_York").format("hh:mm A") : null}</>;
      }

      if (column.key === "location") {
        if (game.longitude && game.longitude) {
          return (
            <>
              <Link
                onClick={renderMap({
                  longitude: game.longitude,
                  latitude: game.latitude,
                  location: game.location,
                })}
              >
                {game[column.key]}
              </Link>
              <br />
              <sub className={classes.secondaryText}>
                {game[column.subTextKey]}
              </sub>
            </>
          );
        }
        return (
          <>
            <span>{game[column.key]}</span>
            <br />
            <sub className={classes.secondaryText}>
              {game[column.subTextKey]}
            </sub>
          </>
        );
      }

      return (
        <>
          {isEmpty(column.title) ? (
            <span className={classes.secondaryText}>{game.away ? 'vs' : ''}</span>
          ) : (
            <span>{game[column.key]}</span>
          )}

          {column.hasSubtext && (
            <>
              <br />
              <sub className={classes.secondaryText}>
                {game[column.subTextKey]}
              </sub>
            </>
          )}
        </>
      );
    };

    return (
      <TableRow key={game.id}>
        {scheduleColumns.map((column) => (
          <TableCell key={`${column.key}-${game.id}`} align="left">
            {getCellContent(column)}
          </TableCell>
        ))}
      </TableRow>
    );
  };

  const renderCalendar = () => {
    return <CalendarEvents events={filteredSchedule} timezone={timezone} date={new Date()}/>;
  };

  if (isEmpty(scheduleList) && !isLoading) {
    return <h3>No schedules for this program.</h3>;
  }

  return (
    <Loader
      showLoading={isLoading}
      key="schedule-loader"
      isFixed={isEmpty(team)}
    >
      <>
        <SchedulesFilters
          scheduleList={filteredSchedule}
          disable={dates}
          setShowCalendar={setShowCalendar}
          showCalendar={showCalendar}
          selectedFilters={selectedFilters}
          setSelectedFilters={setSelectedFilters}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          handleSearch={handleSearch}
          handleCancelSearch={handleCancelSearch}
        />
        {showCalendar ? renderCalendar() : renderScheduleDetails()}
      </>
    </Loader>
  );
};

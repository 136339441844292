import React, { useState } from 'react';
import { Tooltip, ClickAwayListener } from '@material-ui/core';
import { useStyles } from '../../utils/styles';
import { getDistanceInMiles } from '../../db/queries/programs';


const Marker = ({ latlng, handleClick, programs, highlightedMarker, center }) => {
	const classes = useStyles();
	const [isOpen, setIsOpen] = useState(false);

	const handleTooltipClose = () => {
		setIsOpen(false);
	};

	const handleTooltipOpen = () => {
		setIsOpen(true);
	};

	const programLinks = programs.map(prog => {
		const	programCoordinates = latlng.split(',');
		const distanceInMiles = getDistanceInMiles(center.lat, center.lng, programCoordinates[0], programCoordinates[1])
		return (
			<p style={{cursor:'pointer'}} onClick={() => handleClick(prog)}>
				{prog['name']} {distanceInMiles > 0 ? `[${distanceInMiles.toFixed(2)} mile(s) away]` : ''}
			</p>
		)
	})

	return (
		<ClickAwayListener onClickAway={handleTooltipClose}>
			<Tooltip
				interactive
				onClose={handleTooltipClose}
				open={isOpen}
				onMouseEnter={handleTooltipOpen}
				onMouseLeave={handleTooltipClose}
				disableFocusListener
				disableTouchListener
				title={programLinks}
			>
				<div key={latlng}>
					<div
						className={`pin bounce ${classes.marker}`}
					/>
					{ (highlightedMarker == latlng) && <div className={classes.pulse} /> }
				</div>
			</Tooltip>
		</ClickAwayListener>
	);
};

export default (Marker);